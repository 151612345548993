import Vue from "vue";
import App from "./App.vue";
import router from "../src/components/Tools/rutas";
import Vuetify from "vuetify/lib";
import firebase from "firebase";
import * as VueGoogleMaps from "vue2-google-maps";
import VueExcelEditor from "vue-excel-editor";
import VueSimpleAlert from "vue-simple-alert";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";
import ImageUploader from 'vue-image-upload-resize'
import VueCurrencyFilter from "vue-currency-filter";

Vue.use(ImageUploader);


const moment = require("moment");
require("moment/locale/es");
Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ".",
  fractionCount: 0,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: false,
  avoidEmptyDecimals: undefined
});


Vue.use(VueAxios, axios);
Vue.use(VueSimpleAlert , { confirmButtonColor: '#2c3e50',});
Vue.use(require("vue-moment"), { moment });
Vue.use(VueExcelEditor);
Vue.use(VueGoogleMaps, {
  load: {
    language: "es",
    key: "AIzaSyCLJfqZYguLpF-6mLtztx7CnJwOmiWdHiU",
    libraries: "places"
  },
  installComponents: true
});

Vue.use(Vuetify);
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#2c3e50",
        secondary: "#021727", //el oscuro
        accent: "#c4c4c4", // el clarito
        info: "#55677b", // para cuando se seleccione
        success: '#44BBA4', // el verde menta
        error: "#a55518", // el naranja error
        warning: '#e79420', // el amarillo X
      }
    }
  }
});

// Proyecto test PGT
const config = {
  apiKey: "AIzaSyCLJfqZYguLpF-6mLtztx7CnJwOmiWdHiU",
  authDomain: "pgt-travinser.firebaseapp.com",
  projectId: "pgt-travinser",
  storageBucket: "pgt-travinser.appspot.com",
  messagingSenderId: "471649171461",
  appId: "1:471649171461:web:2b8dfe13a82822a8f9846c"
};

Vue.config.productionTip = false;
let app = "";
firebase.initializeApp(config);
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      el: "#app",
      vuetify,
      store,
      render: h => h(App),
      router
    }).$mount("#app");
  }
});
