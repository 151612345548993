<template>
  <v-container>
    <v-container>
      <v-icon @click="$router.push('/home')" large color="#c4c4c4"
        >mdi-arrow-left-bold</v-icon
      >
    </v-container>
    <v-card color="accent">
      <v-card-text>
        <v-container>
          <h1 class="medium secondaryT fit">Pagos</h1>
        </v-container>
      </v-card-text>
      <v-card-title>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
          class="zI"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fechaFiltro"
              label="Seleciona la fecha de consulta"
              :rules="[rules.required]"
              hint="YYYY-MM-DD"
              persistent-hint
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="es"
            v-model="fechaFiltro"
            no-title
            @change="filtroDia()"
            @input="menu1 = false"
          >
          </v-date-picker>
        </v-menu>
      </v-card-title>
      <v-card-subtitle>
        <v-container
          v-if="wait && permisos.escritura"
          class="container_pagos_table"
        >
          <vue-excel-editor
            v-model="rodamientos"
            filterRow
            :cell-style="metodoColor"
            :localized-label="myLabels"
          >
            <vue-excel-column field="Conductor" label="Conductor" />
            <vue-excel-column field="Vehículo" label="Vehículo" />
            <vue-excel-column field="Ruta" label="Ruta" />
            <vue-excel-column
              field="Hora salida"
              label="Hora salida"
              width="200px"
              type="string"
            />
            <vue-excel-column
              field="Hora llegada"
              label="Hora llegada"
              width="200px"
              type="string"
            />
            <!-- <vue-excel-column field="Estado de ruta" label="Estado de ruta" width='150px' type="string" /> -->
            <vue-excel-column
              field="Estado de pago"
              label="Estado de pago"
              type="select"
              width="200px"
              :change="openP"
              :options="['Pagado', 'Pendiente de pago']"
            />
          </vue-excel-editor>
        </v-container>
        <v-container
          v-if="wait && !permisos.escritura"
          class="container_pagos_table"
        >
          <vue-excel-editor
            v-model="rodamientos"
            filterRow
            :cell-style="metodoColor"
            :localized-label="myLabels"
          >
            <vue-excel-column field="Conductor" label="Conductor" />
            <vue-excel-column field="Vehículo" label="Vehículo" />
            <vue-excel-column field="Ruta" label="Ruta" />
            <vue-excel-column
              field="Hora salida"
              label="Hora salida"
              width="200px"
              type="string"
            />
            <vue-excel-column
              field="Hora llegada"
              label="Hora llegada"
              width="200px"
              type="string"
            />
            <vue-excel-column
              field="Estado de ruta"
              label="Estado de ruta"
              width="150px"
              type="string"
            />
          </vue-excel-editor>
        </v-container>
        <v-container v-if="rodamientos.length != 0">
          <v-btn color="primary" @click="reporte">
            Generar informe de pagos</v-btn
          >
        </v-container>
      </v-card-subtitle>
    </v-card>
    <v-dialog max-width="40%" v-if="openPagar" v-model="openPagar">
      <pago-individual :infoR="roda" v-on:toggle-pagos="close($event)">
      </pago-individual>
    </v-dialog>
  </v-container>
</template>

<script>
import getToken from "../Tools/token";
import { host } from "../Tools/variables";
import pagoIndividual from "../ModuloPagos/pagoIndividual.vue";

import tableMixims from "../../mixims/tablas";

export default {
  mixins: [tableMixims],
  components: { pagoIndividual },
  data() {
    return {
      openPagar: false,
      fechaFiltro: null,
      menu1: false,
      wait: false,
      roda: [],
      waitP: false,
      rodamientos: [],
      rules: {
        required: (value) => !!value || "Campo requerido.",
      },
    };
  },

  async created() {
    var findZona = this.$store.getters.getZonas.find(
      (data) => data.nameModule === "moduloPagos"
    );
    var permisosZona = findZona["permisos"];
    var findPermisos = permisosZona.find((pgna) => pgna.nombre === "Pagos");
    this.permisos = findPermisos;
    console.log(this.permisos);
  },

  mounted() {
    this.fechaFiltro = this.$moment(new Date().now).format("YYYY-MM-DD");
    this.filtroDia();
  },

  methods: {
    filtroDia() {
      this.wait = true;
      var fecha = this.$moment(this.fechaFiltro)
        .add("day", 1)
        .format("YYYY-MM-DD");
      getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("comienzo", this.fechaFiltro);
        fd.append("final", fecha);
        this.axios
          .post(host + "/listarRodamientos", fd)
          .then((result) => {
            this.wait = true;
            let data = result.data;
            for (let t = 0; t < result.data.lista.length; t++) {
              const element = result.data.lista[t];
              // console.log(element);
              element["Conductor"] = element["conductor"]["nombre"];
              element["Vehículo"] = element.bus.codigo;
              element["Ruta"] = element.ruta.nombre;
              element["Hora salida"] = this.$moment(element.horaSalida).format(
                "YYYY/MM/DD - HH:mm:ss"
              );
              element["Hora llegada"] = this.$moment(
                element.horaLlegada
              ).format("YYYY/MM/DD - HH:mm:ss");
              element["Estado de pago"] =
                element.estadoPago == false ? "Pendiente de pago" : "Pagado";
              element["Estado de ruta"] = element.estadoLinea;
            }
            this.rodamientos = data.lista;
          })
          .catch((error) => {
            this.$alert(error);
          });
      });
    },

    async openP(oldVal, newVal, row) {
      this.roda = row;
      this.openPagar = true;
    },

    metodoColor(row, col) {
      switch (col.name) {
        case "Estado de pago":
          if (row["Estado de pago"] == "Pendiente de pago") {
            return {
              "background-color": "#FF0000",
              color: "white",
              "font-weight": "700",
            };
          }
          if (row["Estado de pago"] == "Pagado") {
            return {
              "background-color": "#34a853",
              color: "white",
              "font-weight": "700",
            };
          }
          break;
        case "Estado de ruta":
          if (row["Estado de ruta"] == "Pendiente") {
            return {
              "background-color": "#FF0000",
              color: "white",
              "font-weight": "700",
            };
          }
          if (row["Estado de ruta"] == "En Ruta") {
            return {
              "background-color": "#34a853",
              color: "white",
              "font-weight": "700",
            };
          }
          if (row["Estado de ruta"] == "Finalizado") {
            return {
              "background-color": "#0000ff",
              color: "white",
              "font-weight": "700",
            };
          }
          break;
      }
      return {};
    },

    close(e) {
      if (e == "close") {
        return false;
      } else {
        this.openPagar = false;
        return e;
      }
    },

    reporte() {
      getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        this.axios
          .post(host + "/descargaReportePagos", fd)
          .then((result) => {
            this.waitP = true;
            let data = result.data;
            let fecha = this.$moment(Date.now()).format("DD/MM/YYYY");
            if (data.data != "OK") {
              this.$alert(
                "Ocurrió un problema con la generación del reporte de pagos"
              );
            } else {
              console.log(fecha);
              this.download(data.reporte, fecha + ".xlsx");
            }
          })
          .catch((error) => {
            this.$alert(error);
            return false;
          });
      });
    },

    download(content, fileName) {
      const data = Uint8Array.from(Buffer.from(content, "base64"));
      const url = URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ReportePagos - " + fileName);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.zI {
  z-index: 10000 !important;
}

.container_pagos_table {
  min-width: 100% !important;
}
</style>