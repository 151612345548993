<template>
  <v-container>
    <v-container>
      <v-icon @click="$router.push('/home')" large color="#c4c4c4"
        >mdi-arrow-left-bold</v-icon
      >
    </v-container>
    <v-card color="accent">
      <v-card-text>
        <v-container>
          <h1 class="medium secondaryT fit">Monitoreo</h1>
        </v-container>
      </v-card-text>
      <v-card-subtitle>
        <GmapMap
          id="googleMap"
          mapTypeId="roadmap"
          :center="centerM"
          :zoom="11"
          style="height: 500px"
        >
          <!-- MARKER!!! -->
          <GmapMarker
            v-show="!cargando"
            :icon="m.icon"
            v-model="markersM"
            :key="index[m]"
            v-for="(m, index) in markersM"
            v-bind::key="m"
            :position="m"
            @click="selected(m, index)"
            :clickable="true"
          >
            <GmapInfoWindow :opened="m.showInfo">
              <div class="columns">
                <h6 class="primaryT small thin">Bus: {{ m.bus }}</h6>
                <h6 class="primaryT small thin">
                  Estado de linea: {{ m.estadoLinea }}
                </h6>
                <h6 class="primaryT small thin">
                  Velocidad: {{ m.velocidad }}
                </h6>
                <h6 class="primaryT small thin">
                  fecha: {{ m.fecha | moment("YYYY/MM/DD - HH:mm:ss") }}
                </h6>
              </div>
            </GmapInfoWindow>
          </GmapMarker>
        </GmapMap>

        <!-- <GmapMap
          id="googleMap"
          :center="centerM"
          :zoom="11"
          style="height: 500px"
          ><GmapMarker></GmapMarker
        ></GmapMap> -->
      </v-card-subtitle>
      <v-card-title>
        <v-text-field
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar bus"
          single-line
          hide-details
        ></v-text-field
      ></v-card-title>
      <v-card-actions>
        <v-data-table
          dense
          :headers="headersM"
          :search="search"
          :items="markersM"
          :footer-props="{
            'items-per-page-text': 'items por página',
            'items-per-page-all-text': 'Todos',
          }"
        >
          <template v-slot:item.showInfo="{ item }">
            <v-checkbox dense v-model="item.showInfo"></v-checkbox>
          </template>
          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | moment("YYYY/MM/DD - HH:mm:ss") }} </span>
          </template>
          <template v-slot:item.estadoLinea="{ item }">
            <v-icon
              :color="
                item.estadoLinea == 0
                  ? 'yellow'
                  : item.estadoLinea == 1
                  ? 'orange'
                  : item.estadoLinea == '2'
                  ? 'green'
                  : item.estadoLinea == '3'
                  ? 'red'
                  : 'blue'
              "
            >
              mdi-square</v-icon
            >
          </template>
        </v-data-table>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
// import getToken from "../Tools/token";
import { host } from "../Tools/variables";
// import gmapApi from "vue2-google-maps";
const google = window.google;

export default {
  name: "Monitoreo",
  data() {
    return {
      dataShow: [],
      search: "",
      headersM: [
        {
          text: "",
          value: "showInfo",
        },
        {
          text: "Bus",
          value: "bus",
        },
        {
          text: "Velocidad",
          value: "velocidad",
        },
        {
          text: "Fecha geo",
          value: "fecha",
        },
        {
          text: "Estado línea",
          value: "estadoLinea",
        },
      ],
      centerM: { lat: 4.668131, lng: -74.092026 },
      indiIcon: require("../../assets/indi.png"),
      indiIcon1: require("../../assets/indi.svg"),
      markersM: [],
      cargando: true,
      intervalId: null,
      markersMtest: [{ lat: 4.768131, lng: -74.192026 }],
    };
  },

  async created() {
    this.consultaBuses();
  },

  mounted() {
    this.intervalId = setInterval(() => {
      this.updateBuses();
    }, 10000);

    window.onblur = () => {
      clearInterval(this.intervalId);
    };

    window.onfocus = () => {
      var path = this.$route.path;
      if (path == "/moduloMonitoreo") {
        clearInterval(this.intervalId);
        this.intervalId = setInterval(() => {
          this.updateBuses();
        }, 10000);
      }
    };
  },

  methods: {
    async consultaBuses() {
      this.cargando = true;
      const fd = new FormData();
      fd.append("token", "token");
      this.axios.post(host + "/posicionBuses", fd).then((result) => {
        let data = result.data;
        if (data.data == "OK") {
          this.markersM = [];
          for (let m = 0; m < data.lista.length; m++) {
            const element = data.lista[m];
            this.markersM.push({
              lat: element["lat"],
              lng: element["lon"],
              velocidad: element["speed"],
              angulo: element["angle"],
              fecha: element["datetime"],
              bus: element["name"],
              estadoLinea: element["estadoLinea"],
              id: `bus${element["name"]}`,
              showInfo: true,
              icon: {
                url: this.indiIcon,
                scaledSize: { width: 36, height: 16 },
              },
            });
          }
          this.cargando = false;
        } else {
          this.$alert(result.data.data);
        }
      });
    },

    async updateBuses() {
      this.cargando = true;
      const fd = new FormData();
      fd.append("token", "token");
      this.axios.post(host + "/posicionBuses", fd).then((result) => {
        let data = result.data;
        if (data.data == "OK") {
          for (let m = 0; m < data.lista.length; m++) {
            const element = data.lista[m];
            this.markersM[m].lat = element["lat"];
            this.markersM[m].lng = element["lon"];
            this.markersM[m].velocidad = element["speed"];
            this.markersM[m].angulo = element["angle"];
            this.markersM[m].fecha = element["datetime"];
            this.markersM[m].bus = element["name"];
            this.markersM[m].estadoLinea = element["estadoLinea"];
            this.markersM[m].icon = {
              url: this.indiIcon,
              scaledSize: { width: 36, height: 16 },
            };
          }
          this.cargando = false;
        } else {
          this.$alert(result.data.data);
        }
        return this.markersM;
      });
    },

    cargueShow() {
      for (let m = 0; m < this.markersM.length; m++) {
        this.dataShow.push({
          showInfo: true,
        });
      }
      this.cargando = true;
    },

    angle() {
      var symbol = {
        path: "M -2,0 0,-2 2,0 0,2 z",
        fillColor: "#FF0000",
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 0.5,
        rotation: 40,
      };

      google.maps.Marker({
        position: this.markersMtest,
        icon: symbol,
      });
      this.cargando = false;
    },

    selected(m, i) {
      this.centerM = m;
      for (var j = 0; j < this.markersM.length; j++) {
        this.markersM[j]["showInfo"] = false;
      }
      this.markersM[i]["showInfo"] = true;
    },
  },
};
</script>

<style scoped>
.mapRef {
  min-width: 100% !important;
  height: 100% !important;
}

.rotate {
  transform: rotate(20) !important;
}
</style>
