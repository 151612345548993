<template>
  <v-container>
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="fechaFiltro"
          label="Seleciona la fecha de consulta"
          :rules="[rules.required]"
          hint="YYYY-MM-DD"
          persistent-hint
          v-bind="attrs"
          v-on="on"
        >
        </v-text-field>
      </template>
      <v-date-picker
        locale="es"
        :max="hoy"
        v-model="fechaFiltro"
        no-title
        @change="filtroDia()"
        @input="menu1 = false"
      >
      </v-date-picker>
    </v-menu>
    <!-- EL NUEVO COMPONENTE -->
    <v-container
      v-if="
        rodamientos.length != 0 && fechaFiltro != null && noPuedeVer == false
      "
    >
      <filtros-despachos
        :rodamientos="rodamientos"
        :rodamientosB="rodamientosB"
        :permisos="permisos"
        :editar="generarDespacho"
        :fecha="fechaFiltro"
        v-on:toggle-filtro="eventosEditD($event)"
      ></filtros-despachos>
      <v-container
        v-if="!isBuscando && rodamientos.length == 0 && fechaFiltro != null"
      >
        <h5>* No tienes rodamientos agendados para este día</h5>
      </v-container>
      <v-container v-if="fechaFiltro != null && noPuedeVer == true">
        <h5>* No puedes ver las rutas para este día</h5>
      </v-container>
    </v-container>
    <!-- Modulos viejos ???  -->
    <!-- <v-container>
      <h1
        class="medium secondaryT fit center"
        v-if="
          rodamientos.length != 0 &&
          fechaFiltro != null &&
          mostarBusqueda == true &&
          noPuedeVer == false
        "
      >
        Busqueda de despachos
      </h1>
      <busqueda-despacho
        v-if="
          rodamientos.length != 0 &&
          fechaFiltro != null &&
          mostarBusqueda == true &&
          noPuedeVer == false
        "
        :rodamientos="rodamientos"
        :permisos="permisos"
        :editar="generarDespacho"
        :fecha="fechaFiltro"
        v-on:toggle-filtro="eventosEditD($event)"
      ></busqueda-despacho>
    </v-container>

    <v-container>
      <h1
        class="medium secondaryT fit center"
        v-if="
          rodamientos.length != 0 &&
          fechaFiltro != null &&
          mostarBusqueda == true &&
          noPuedeVer == false
        "
      >
        Tabla de despachos del día
      </h1>
      <dia-despacho
        v-if="
          rodamientos.length != 0 &&
          fechaFiltro != null &&
          mostarBusqueda == true &&
          noPuedeVer == false
        "
        :rodamientos="rodamientos"
        :permisos="permisos"
        :editar="generarDespacho"
        :fecha="fechaFiltro"
        v-on:toggle-tabla="eventosEditD($event)"
      ></dia-despacho>
    </v-container> -->

    <v-container v-if="isBuscando">
      <v-progress-circular
        :size="25"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-container>
  </v-container>
</template>

<script>
import getToken from "../Tools/token";
import { host } from "../Tools/variables";
// import EditDespcaho from './EditDespacho.vue'
// import DiaDespacho from "./DiaDespacho.vue";
// import BusquedaDespacho from "./BusquedaDespacho.vue";
import FiltrosDespachos from "./FiltrosDespachos.vue";

import permisosMixims from "../../mixims/permisos";

export default {
  mixins: [permisosMixims],

  name: "ByDespacho",

  props: ["fecha"],

  components: {
    // EditDespcaho,
    // DiaDespacho,
    // BusquedaDespacho,
    FiltrosDespachos,
  },

  data() {
    return {
      menu1: false,
      info: [],
      busId: null,
      rules: {
        required: (value) => !!value || "Campo requerido.",
      },
      fechaFiltro: "",
      hoy: this.$moment(new Date().now).format("YYYY-MM-DD"),
      noPuedeVer: false,
      generarDespacho: true,
      cargando: false,
      popupEdit: false,
      popupDesp: false,
      rodamientos: [],
      rodamientosB: [],
      hover: [],
      isBuscando: false,
    };
  },

  async created() {
    var findZona = this.$store.getters.getZonas.find(
      (data) => data.nameModule === "moduloDespachos"
    );
    var permisosZona = findZona["permisos"];
    // var findPermisos = permisosZona.find(pgna => pgna.nombre === "Rodamientos")
    var findPermisos2 = permisosZona.find(
      (pgna) => pgna.nombre === "Despachos"
    );
    this.permisos = findPermisos2;
    // this.permisosDesp = findPermisos2
  },

  watch: {
    fechaFiltro(newF) {
      if (newF <= this.hoy) {
        this.generarDespacho = false;
        this.filtroDia(newF, null);
      }
      if (newF == this.hoy) {
        this.generarDespacho = true;
      }
    },
  },

  mounted() {
    this.fechaFiltro = this.$moment(new Date().now).format("YYYY-MM-DD");
    this.filtroDia(this.fechaFiltro, null);
  },

  methods: {
    filtroDia(fecha, bus) {
      this.isBuscando = true;
      console.log("toy buscandis");
      this.rodamientos = [];
      this.rodamientosB = [];
      var fe = this.$moment(fecha).add("day", 1).format("YYYY-MM-DD");
      getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("comienzo", fecha);
        fd.append("final", fe);
        this.axios
          .post(host + "/listarRodamientos", fd)
          .then((result) => {
            this.rodamientos = result.data.lista;
            this.rodamientosB = result.data.lista;
            console.log("back ???");
            console.log(this.rodamientosB.length);

            this.isBuscando = false;
            if (bus) {
              this.filtrarByBus(bus);
            }
          })
          .catch((error) => {
            this.$alert(error);
          });
      });
    },

    filtrarByBus(a) {
      var list = [];
      for (let d = 0; d < this.rodamientosB.length; d++) {
        const element = this.rodamientosB[d];
        var fechaI = this.$moment(element.horaSalida).format("YYYY-MM-DD");
        if (element.bus.id == a && this.fechaFiltro == fechaI) {
          list.push(this.rodamientosB[d]);
        }
      }
      this.isBuscando = false;
      this.rodamientos = list;
    },

    eventosEditD(a) {
      if (a == "refresh") {
        console.log("by todos");
        this.filtroDia(this.fechaFiltro, null);
      }
      if (a.tipo == "pagos") {
        this.filtroDia(this.fechaFiltro, a.bus);
      } else {
        console.log("by bus");
        this.filtrarByBus(a);
      }
    },
  },
};
</script>

