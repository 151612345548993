<template>
    <v-card class="container_created">
        <v-card-title>
            <v-container v-if="!cargando">
                <h3 class="medium primaryT fat"> Bus: {{name}}</h3>
                <gmap-map v-if="centerP != null" ref="mapRef" :center="centerP" :zoom="14" style="height: 300px;">
                    <gmap-marker :icon="{url:require('../../assets/indi.png'), scaledSize: {width: 50, height: 50},}"
                        v-model="markersP" :key="index[m]" v-for="(m, index) in markersP" :position="m"
                        @click="centerP=m" :draggable="true" :clickable="true" />
                </gmap-map>
                <v-container v-else>
                    <h5> * No se tiene la posición de este bus</h5>
                </v-container>
            </v-container>
            <v-container v-else>
                <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
            </v-container>
        </v-card-title>

    </v-card>
</template>

<script>
import getToken from "../Tools/token"
import { host } from "../Tools/variables"


export default {
    name: 'verPosicion',
    props: {
        busId: String,
        name: String
    },
    data() {
        return {
            centerP: null,
            markersP: [],
            cargando: true,
        }
    },
    async created() {
        await this.getPos();
    },
    mounted() {
        
    },
    methods: {
       async getPos(){
            getToken().then(token=>{
                const fd = new FormData();
                fd.append('token', token),
                fd.append('busId', this.busId)
                this.axios.post(host + '/posicionBus' , fd).then(rta =>{
                    var data = rta.data.lista[0]
                    console.log(data['fecha'])
                    this.centerP = {lat: data['lat'], lng: data['lon'] }
                    this.markersP.push(this.centerP)
                    console.log(this.centerP)
                    this.cargando = false;
                })
                this.cargando = false;
            })
        }
    },

}
</script>

<style>

</style>