<template>
  <v-row class="container">
    <v-col class="border" cols="10" md="2" xs="2" sm="2" v-for="i in alertas" v-bind:key="alertas[i]">
      <h1 class="medium primaryT thin">{{i.tipo}}</h1>
      <br />
      <h1 class="normal primaryT thin">Unidad: {{i.unidad}}</h1>
      <v-row>
        <v-col cols="12" md="3" xs="3" sm="3">
          <v-container>
            <v-icon xs :color="'#ff0'">mdi-checkbox-blank-circle</v-icon>
          </v-container>
        </v-col>
        <v-col cols="12" md="8" xs="8" sm="8">
          <v-text-field type="number" label="Limite" v-model="i.amarillo"></v-text-field>
        </v-col>
        <v-col cols="12" md="3" xs="3" sm="3">
          <v-container>
            <v-icon xs :color="'#f00'">mdi-checkbox-blank-circle</v-icon>
          </v-container>
        </v-col>
        <v-col cols="12" md="8" xs="8" sm="3">
          <v-text-field type="number" label="Limite" v-model="i.rojo"></v-text-field>
        </v-col>
        <v-container class="container_save">
          <v-icon large color="#44BBA4" v-if="permisos.escritura" @click="guardar(i)">mdi-check</v-icon>
        </v-container>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import getToken from "../Tools/token";
import { host } from "../Tools/variables";
import permisosMixims from "../../mixims/permisos";

export default {
  mixins: [ permisosMixims],
  name: 'controlAlertas',
  data() {
    return {
      alertas: [],
    };
  },
  created() {
    this.cargarAlertas();
    var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloConfiguracion")
    var permisosZona = findZona['permisos']
    var findPermisos = permisosZona.find(pgna => pgna.nombre === "Alertas")
    this.permisos = findPermisos
  },
  mounted() {},
  methods: {
    cargarAlertas(){
      getToken().then(token => {
          const fd = new FormData();
          fd.append("token", token);
          this.axios.post(host + "/leerAlertas", fd).then(result => {
            console.log(result);
            this.alertas = result.data.lista;
            console.log(this.alertas.length);
          });
        });
    },
    guardar(i) {
      getToken().then(token => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("tipoAlerta", i.tipo);
        fd.append("amarillo", i.amarillo);
        fd.append("rojo", i.rojo);
        this.axios
          .post(host + "/configuraAlerta", fd)
          .then(result => {
            console.log(result);
            if (result.data.data == "OK")
              this.$router.replace("../controlAlertas");
          })
          .catch(error => {
            this.$alert(error);
          });
      });
    }
  }
};
</script>

<style scoped>
.border {
  border: #55677b 1px solid;
  border-radius: 10px;
  margin: 2%;
}
.container{
  background-color: transparent !important;
}
</style>
