<template>
  <v-container class="pt-8">
    <!-- FILTRO POR BUS -->
    <h1 class="medium secondaryT fit center mb-5">Busqueda de despachos</h1>
    <h5>{{ edicion.id }}</h5>
    <v-row>
      <v-col cols="12" md="4" xs="4">
        <h4 class="normal primaryT pb-4">Seleccione móvil</h4>
        <v-autocomplete
          chips
          clearable
          v-show="buses != []"
          @change="getInfoBus"
          class="contenedor__login-textField"
          dense
          :items="buses"
          item-text="nombre"
          item-value="id"
          v-model="edicion.bus"
          label="Seleccione el bus"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" xs="4">
        <h4 class="normal primaryT pb-4">Seleccione Ruta</h4>
        <v-autocomplete
          chips
          clearable
          v-show="rutas != []"
          @change="getInfo"
          class="contenedor__login-textField"
          dense
          :items="rutas"
          item-text="nombre"
          item-value="nombre"
          v-model="edicion.ruta"
          label="(*) Aún no tiene hora de despacho "
        >
        </v-autocomplete>
      </v-col>
      <v-col v-if="edicion.ruta == 'Otra'" cols="12" md="4" xs="4">
        <h4 class="normal primaryT pb-4">
          Seleccione la OTRA ruta (Modulo base de datos)
        </h4>
        <v-autocomplete
          chips
          clearable
          v-show="rutas != []"
          class="contenedor__login-textField"
          dense
          :items="rutasB"
          item-text="nombre"
          item-value="id"
          v-model="edicion.rutaB"
          label="(*) Aún no tiene otra ruta asignada "
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" xs="4">
        <h4 class="normal primaryT pb-4">Conductor</h4>
        <v-autocomplete
          chips
          clearable
          v-show="conductores != []"
          class="contenedor__login-textField"
          dense
          @change="changeConductor"
          :items="conductores"
          item-text="usuarioId__nombres"
          item-value="id"
          v-model="edicion.conductor"
          label="Conductor"
        >
        </v-autocomplete>
      </v-col>
      <v-col v-if="edicion.conductor == 'Otro'" cols="12" md="4" xs="4">
        <span class="normal primaryT fat pb-4">
          Nombre completo de relevo
        </span>
        <v-text-field label="Nombre" v-model="relevo.nombre"> </v-text-field>
      </v-col>
      <v-col v-if="edicion.conductor == 'Otro'" cols="12" md="4" xs="4">
        <span class="normal primaryT fat pb-4"> Cédula relevo </span>
        <v-text-field label="Documento" v-model="relevo.documento">
        </v-text-field>
      </v-col>
      <v-col v-if="edicion.conductor == 'Otro'" cols="12" md="4" xs="4">
        <v-btn class="success" @click="changeConductor"> Guardar relevo </v-btn>
      </v-col>
      <v-col cols="12" md="4" xs="4">
        <span class="normal primaryT fat pb-4"> Hora Despacho </span>
        <span class="normal thin errorT">{{
          sinD == true ? "* hora actual" : ""
        }}</span>
        <vue-timepicker
          drop-direction="bottom"
          input-width="100%"
          :drop-offset-height="400"
          container-id="auto-dropdown-containter"
          class="mt-5"
          hide-clear-button
          color="primary"
          input-class="picker_style"
          format="HH:mm"
          v-model="edicion.horaDespacho"
          placeholder="Hora despacho*"
        ></vue-timepicker>
      </v-col>
      <v-col cols="12" md="8" xs="8">
        <h4 class="normal primaryT">Observaciones</h4>
        <v-text-field label="Observaciones" v-model="edicion.observaciones">
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="12"
        xs="12"
        v-if="edicion.ruta && edicion.ruta != 'Otra'"
      >
        <h4 class="normal primaryT">
          Estado de pago:
          <v-icon x-small :color="edicion.estadoPago == false ? 'red' : 'green'"
            >mdi-square</v-icon
          >
        </h4>
      </v-col>
      <v-col
        cols="12"
        md="12"
        xs="12"
        v-if="edicion.ruta && edicion.ruta == 'Otra'"
      >
        <h4 class="normal errorT">
          Alerta! se cambiará la agenda programada, debe verificar los pagos de
          forma manual.
        </h4>
      </v-col>

      <v-col cols="12" md="3" xs="6" v-if="permisos.escritura && editar">
        <v-btn class="success" @click="EditLinea"> Generar despacho </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="3"
        xs="6"
        v-if="permisos.escritura && editar && edicion.horaDespacho"
      >
        <v-btn class="success" @click="openPago"> Generar pago </v-btn>
      </v-col>
    </v-row>
    <!-- TABLA -->
    <h1 class="medium secondaryT fit center my-5">
      Tabla de despachos del día
    </h1>
    <v-row>
      <v-container>
        <v-data-table
          v-if="rodamientos.length != 0"
          dense
          :sort-by="['rodamientoBase.numero', 'rodamientoBase.orden']"
          :sort-desc="[false, false]"
          :headers="headersD"
          :items-per-page="rodamientos.length"
          multi-sort
          :items="rodamientos"
          :footer-props="{
            'items-per-page-text': 'items por página',
            'items-per-page-all-text': 'Todos',
          }"
        >
          <template v-slot:item.horaDespacho="{ item }">
            <span>{{ item.horaDespacho | moment("HH:mm") }} </span>
          </template>
          <!-- CAMBIAR ESTOOOO -->
          <template v-slot:item.despacho="{ item }">
            <span>{{ item.despacho.nombre }} </span>
          </template>
          <template v-slot:item.estadoPago="{ item }">
            <v-icon x-small :color="item.estadoPago == false ? 'red' : 'green'"
              >mdi-square</v-icon
            >
          </template>
          <template
            v-if="permisos.escritura && editar"
            v-slot:item.edit="{ item }"
          >
            <v-btn x-small @click="editarByday(item)">editar</v-btn>
          </template>
          <template
            v-if="permisos.escritura && editar"
            v-slot:item.delete="{ item }"
          >
            <v-icon small @click="remover(item)">mdi-close</v-icon>
          </template>

          <template v-slot:header="{ headers }">
            <th
              v-for="item in headers"
              :key="headers[item]"
              class="large secondaryT fat"
            >
              {{ item.value }}
            </th>
          </template>
        </v-data-table>
        <v-dialog
          max-width="40%"
          v-if="popupEdit && permisos.escritura"
          v-model="popupEdit"
        >
          <edit-despacho
            :fecha="fecha"
            :info="info"
            v-on:toggle="eventosEditL($event)"
          ></edit-despacho>
        </v-dialog>
      </v-container>
      <v-container>
        <v-btn color="success" @click="exportarDesp">Exportar despachos</v-btn>
      </v-container>
    </v-row>
    <v-dialog max-width="40%" v-if="openPagar" v-model="openPagar">
      <pago-individual
        :infoR="rodamientoData"
        :conductorC="edicion.conductor"
        v-on:toggle-pagos="close($event)"
      >
      </pago-individual>
    </v-dialog>
  </v-container>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import data from "../Tools/getData";
import getToken from "../Tools/token";
import { host } from "../Tools/variables";
import pagoIndividual from "../ModuloPagos/pagoIndividual.vue";
import EditDespacho from "./EditDespacho.vue";
import axios from "axios";

export default {
  name: "FiltrosDespachos",
  components: { VueTimepicker, pagoIndividual, EditDespacho },

  props: ["rodamientos", "rodamientosB", "permisos", "editar", "fecha"],

  data() {
    return {
      rutas: [],
      buses: [],
      conductores: [],
      relevo: { nombre: null, documento: null },
      edicion: {
        ruta: null,
        rutaB: null,
        edicion: null,
        conductor: null,
        bus: null,
        horaDespacho: null,
        observaciones: null,
        id: null,
        estadoPago: null,
      },
      sinD: false,
      roda: [],
      openPagar: false,
      rodaFilter: [],
      rodamientoData: [],
      info: [],
      popupEdit: false,
      showTable: false,
      headersD: [
        {
          text: "Hora despacho",
          value: "horaDespacho",
        },
        {
          text: "Bus",
          value: "bus.codigo",
        },
        {
          text: "Ruta",
          value: "ruta.nombre",
        },
        {
          text: "Despacho",
          value: "despacho",
        },
        {
          text: "No. Rodamiento",
          value: "rodamientoBase.numero",
          sortable: true,
        },
        {
          text: "No. ruta",
          value: "rodamientoBase.orden",
          sortable: true,
        },

        {
          text: "Conductor",
          value: "conductor.nombre",
        },
        {
          text: "Est pago",
          value: "estadoPago",
        },
        {
          text: "",
          value: "edit",
        },
        {
          text: "",
          value: "delete",
        },
      ],
    };
  },
  async mounted() {
    // console.log(this.rodamientos)
    this.getRutas();
    await this.getRutasB();
    await this.getConductores();
  },

  methods: {
    async getRutasB() {
      if (this.$store.state.rutas == null)
        this.rutasB = await data.getData("/listarRutas", "rutas");
      else {
        this.rutasB = this.$store.state.rutas;
      }
    },
    async getConductores() {
      if (this.$store.state.conductores == null)
        this.conductores = await data.getData(
          "/listaConductores",
          "conductores"
        );
      else {
        this.conductores = this.$store.state.conductores;
      }
      this.conductores.push({ usuarioId__nombres: "Otro", id: "Otro" });
    },

    getRutas() {
      let entries = Object.values(this.rodamientos);
      let sorted = entries.sort(
        (a, b) => a["rodamientoBase"]["numero"] - b["rodamientoBase"]["numero"]
      );
      this.rutas = [];
      for (let r = 0; r < sorted.length; r++) {
        const element = sorted[r];
        this.buses.push({
          nombre: element["bus"]["codigo"],
          id: element["bus"]["id"],
        });
      }
    },

    getInfoBus() {
      if (this.edicion.bus != null) {
        this.rutas = [];
        this.rodaFilter = [];
        this.edicion.conductor = null;
        for (let r = 0; r < this.rodamientosB.length; r++) {
          const element = this.rodamientosB[r];
          if (element["bus"]["id"] == this.edicion.bus) {
            this.edicion.id = element["id"];
            if (element["horaDespacho"] == null) {
              this.rodaFilter.push(element);
              this.rutas.push({
                nombre: element["ruta"]["nombre"],
              });
              this.edicion.conductor = element["conductor"]["id"];
              break;
            }
          }
        }
        this.showTable = true;
        this.getNextRuta();
        this.rutas.push({ nombre: "Otra", id: "Otra" });
        this.$emit("toggle-filtro", this.edicion.bus);
      } else {
        this.edicion.conductor = null;
        this.$emit("toggle-filtro", "refresh");
      }
    },

    getNextRuta() {
      this.edicion.ruta = null;
      // console.log(this.rodaFilter)
      this.rodamientoData = [];
      for (let n = 0; n < this.rodaFilter.length; n++) {
        const element = this.rodaFilter[n];
        if (element["horaDespacho"] == null) {
          this.rodamientoData = element;
          this.edicion.ruta = this.rodaFilter[n]["ruta"]["nombre"];
          break;
        } else {
          this.rodamientoData = this.rodaFilter[n + 1];
          this.edicion.ruta = this.rodaFilter[n + 1]["ruta"]["nombre"];
        }
      }
      this.getInfo(this.rodamientoData);
    },

    getInfo(data) {
      if (data) {
        this.edicion.estadoPago = data["estadoPago"];
        this.edicion.horaDespacho =
          data["horaDespacho"] == null
            ? this.$moment(new Date()).format("HH:mm:ss")
            : this.$moment(data["horaDespacho"]).format("HH:mm:ss");
        if (data["horaDespacho"] == null) {
          this.sinD = true;
        } else {
          this.sinD = false;
        }
      } else {
        // this.edicion.estadoPago = data["estadoPago"];
        this.edicion.id = "Otra";
        this.edicion.horaDespacho = this.$moment(new Date()).format("HH:mm:ss");
      }
    },

    getIdRuta() {
      for (let r = 0; r < this.rodamientos.length; r++) {
        const element = this.rodamientos[r];
        if (
          element["horaDespacho"] == null &&
          element["ruta"]["nombre"] == this.edicion.ruta
        ) {
          return element["id"];
        } else {
          return null;
        }
      }
    },

    changeConductor() {
      var id = this.getIdRuta();
      if (id == null) {
        id = this.edicion.id;
      }
      if (this.edicion.conductor != null && this.edicion.conductor != "Otro") {
        getToken().then((token) => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("rodamientoId", id);
          fd.append("busId", this.edicion.bus);
          fd.append("conductorId", this.edicion.conductor);
          fd.append("eliminar", "NO");
          fd.append("observaciones", "Cambio desde despachos");
          fd.append("marcarSalida", "NO");
          fd.append("tipoEdicion", "Individual");
          this.axios
            .post(host + "/editarRodamiento", fd)
            .then((result) => {
              var data = result.data.data;
              if (data == "OK") {
                // this.$emit("toggle-filtro", "refresh");
                this.edicion.estadoPago = false;
                this.$alert(
                  "Se ajusto el conductor, debes generar el despacho para visualizarlo en la 'Tabla de despachos' y verificar en 'Generar pago' si el conductor tiene saldo o debe realizar el pago correspondiente a este ajuste."
                );
                // this.filtroDia();
                // se debe verificar el pago de ese conductor?
              } else {
                this.$alert("No se puede realizar este ajuste");
              }
            })
            .catch((error) => {
              this.disabledCon = false;
              this.$alert(error);
            });
        });
      }
      // SI EL CONDUCTOR ES UN RELEVO
      if (
        this.edicion.conductor == "Otro" &&
        this.relevo.nombre != null &&
        this.relevo.documento != null
      ) {
        console.log("entro aqui!!");
        getToken().then((token) => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("rodamientoId", id);
          fd.append("busId", this.edicion.bus);
          fd.append(
            "conductorId",
            this.relevo.nombre + ",-," + this.relevo.documento
          );
          fd.append("eliminar", "NO");
          fd.append("observaciones", "Cambio desde despachos");
          fd.append("marcarSalida", "NO");
          fd.append("tipoEdicion", "Individual");
          this.axios
            .post(host + "/editarRodamiento", fd)
            .then((result) => {
              var data = result.data.data;
              if (data == "OK") {
                // this.$emit("toggle-filtro", "refresh");
                this.edicion.estadoPago = false;
                this.$alert("Se ajusto el conductor por un relevador.");
              } else {
                this.$alert("No se puede realizar este ajuste");
              }
            })
            .catch((error) => {
              this.disabledCon = false;
              this.$alert(error);
            });
        });
      }
    },

    remover(a) {
      console.log(a);
      getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("rodamientoId", a.id);
        this.axios
          .post(host + "/anularDespacho", fd)
          .then((result) => {
            var data = result.data.data;
            if (data == "OK") {
              // this.getRutas();
              this.$emit("toggle-filtro", "refresh");
              this.$alert("Se removio correctamente este despacho");
            } else {
              this.$alert(data);
            }
          })
          .catch((error) => {
            this.disabledCon = false;
            this.$alert(error);
          });
      });
    },

    EditLinea() {
      var id = this.getIdRuta();
      if (id == null) {
        id = this.edicion.id;
      }
      var permiteCambiarR = true;
      if (
        this.edicion.ruta == "Otra" &&
        (this.edicion.observaciones == "" || this.edicion.observaciones == null)
      ) {
        permiteCambiarR = false;
        this.$alert("Debes marcar una observación sobre este ajuste");
      } else {
        if (this.edicion.horaDespacho && permiteCambiarR) {
          var fechaS = this.$moment(
            this.fecha + "T" + this.edicion.horaDespacho + ":00"
          ).add(-5, "hours");
          var dat = new Date(fechaS).toISOString();
          getToken().then((token) => {
            const fd = new FormData();
            fd.append("token", token);
            fd.append("rodamientoId", id);
            fd.append("busId", this.edicion.bus);
            fd.append("conductorId", this.edicion.conductor);
            fd.append("nuevaRutaId", this.edicion.rutaB);
            fd.append("eliminar", "NO");
            fd.append("observaciones", this.edicion.observaciones);
            fd.append("marcarSalida", dat);
            fd.append("tipoEdicion", "Individual");
            this.axios
              .post(host + "/editarRodamiento", fd)
              .then((result) => {
                var data = result.data.data;
                this.download(result.data.tirilla);
                if (data == "OK") {
                  // this.getRutas();
                  this.$emit("toggle-filtro", "refresh");
                  this.$alert(
                    "Se realizó correctamente este ajuste de despacho"
                  );
                } else {
                  this.$alert(data);
                }
              })
              .catch((error) => {
                this.disabledCon = false;
                this.$alert(error);
              });
          });
        } else {
          this.$alert("Debes marcar una hora de despacho");
        }
      }
    },

    openPago() {
      this.openPagar = true;
    },

    close(e) {
      if (e == true) {
        this.edicion.estadoPago = true;
        this.$emit("toggle-filtro", { tipo: "pagos", bus: this.edicion.bus });
      }
      this.openPagar = false;
    },

    download(content) {
      const url = Uint8Array.from(Buffer.from(content, "base64"));
      var blob = new Blob([url], { type: "application/pdf" });
      const link = URL.createObjectURL(blob);
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = link;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    },

    //   Metodos de la tabla
    editarByday(item) {
      this.info = item;
      this.popupEdit = true;
    },

    eventosEditL() {
      this.popupEdit = false;
      this.$emit("toggle-filtro", "refresh");
    },

    exportarDesp() {
      getToken()
        .then((token) => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("fInicio", this.fecha);
          fd.append("fFin", this.fecha);
          axios.post(host + "/reporteDespachos", fd).then((result) => {
            console.log(result);
            let fecha = this.$moment(Date.now()).format("DD/MM/YYYY");
            if (result.data.data == "OK") {
              this.downloadD(result.data.reporte, fecha + ".xlsx");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    downloadD(content, fileName) {
      const data = Uint8Array.from(Buffer.from(content, "base64"));
      const url = URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ReporteDespachos - " + fileName);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style>
</style>