<template>
  <v-card color="accent">
    <v-card-title>
      <v-icon large color="primary" @click="$emit('toggle', 'close')">mdi-arrow-left-bold</v-icon>
    </v-card-title>
    <v-card-text>
      <v-col>
        <h5 class="medium secondaryT fit">Crear rodamiento </h5>
      </v-col>
    </v-card-text>
    <v-card-subtitle v-if="!creando">
      <v-container>
        <v-row>
          <v-col cols="12" md="6" sm="6" xs="6">
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="mt-6" dense v-model="fecha" label="Fecha de inicio rodamiento" :rules="[rules.required]"
                  hint="YYYY-MM-DD" persistent-hint v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker locale="es" v-model="fecha" no-title @change="search()" @input="menu1 = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <v-select :disabled="disabledRod" label="Rodamiento" :append-icon="disabledRod ? 'mdi-reload' : ''"
              :items="rodamientos" v-model="rodamiento.rodamientoBase" item-text="nombre" item-value="id"></v-select>
          </v-col>
          <!-- <v-col cols="12" md="4" sm="4" xs="4">
            <v-select @change="buscarConductor" label="Bus" :disabled="disabledBus"
              :append-icon="disabledBus ? 'mdi-reload' : ''" :items="buses"  v-model="rodamiento.bus"
              item-text="identificacion" item-value="id"></v-select>
          </v-col>
          <v-col cols="12" md="4" sm="4" xs="4">
            <v-select label="Conductor" :disabled="disabledCon" :append-icon="disabledCon ? 'mdi-reload' : ''"
              :items="conductores" v-model="rodamiento.conductor" item-text="usuarioId__nombres" item-value="id">
            </v-select>
          </v-col> -->
        </v-row>
      </v-container>
    </v-card-subtitle>
    <v-card-subtitle v-else>
      <v-container>
        <h4 class="normal errorT thin">Estamos creando los rodamientos del mes, por favor esperar un momento</h4>
        <v-container>
          <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
        </v-container>
      </v-container>
    </v-card-subtitle>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-icon x-large color="warning" @click="$emit('toggle', 'close')">mdi-close-circle</v-icon>
      <v-icon x-large color="success" @click="saveR()">mdi-check</v-icon>
    </v-card-actions>
  </v-card>
</template>

<script>
import getToken from "../Tools/token";
import {host} from "../Tools/variables";
import data from "../Tools/getData";


export default {
  name: "crudRodamiento",
  props: ["fechaS"],
  data() {
    return {
      conductores: [],
      rodamientos: [],
      buses: [],
      rules: {
        required: value => !!value || "Campo requerido."
      },
      disabledRod: true, 
      disabledCon: true, 
      disabledBus: true,
      fecha : null,
      menu1: false,
      creando: false,
      rodamiento :{
        conductor: null, 
        bus: null,
        rodamientoBase: null,
      }
    };
  },
  mounted() {
    
  },
  created() {
  },

  methods: {
    async search(){
      await data.getDataEsp('/listarRodamientoBase','dia', this.fecha).then(info => {
        if (info != 'Error') {
          this.disabledRod = false;
          var data = Object.keys(info);
          var vls = Object.values(info);
          for (let d = 0; d < data.length; d++) {
            const element = vls[d];
            if (element.programada == false) {
              this.rodamientos.push({
                id: element.rutas[0].numero,
                nombre: "Rodamiento no." + element.rutas[0].numero
              });
            }
          }
          if (this.info != null) {
            this.rodamiento.rodamientoBase = this.info.rodamientoBase.id
          }
        }
        else {
          this.$alert('Error')
        }
      })
              
        await data.getData('/listarBuses', 'buses').then(info => {
          if (info != 'Error') {
            this.buses = info
            this.disabledBus = false;
          }
          else {
            this.$alert('Error')
          }
        })
      await data.getDataEsp('/listaConductoresRodamiento', 'dia', this.fecha).then(info => {
        if (info != 'Error') {
          this.conductores = info
          this.disabledBus = false;
        }
        else {
          this.$alert('Error')
        }
      })
    },
    buscarConductor() {
      var id = this.buses.find(e => e.id == this.rodamiento.bus)
      this.rodamiento.conductor = id['defaultCond'] != null ? id['defaultCond'] : null
      this.disabledCon = false;
    },
    saveR() {
      this.creando = true;
      getToken().then(token => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("dia", this.fecha);
        // fd.append("busId", this.rodamiento.bus);
        fd.append("rodamientoBase", this.rodamiento.rodamientoBase);
        // fd.append("conductorId", this.rodamiento.conductor);
        this.axios
          .post(host + "/nuevoRodamiento", fd)
          .then(result => {
            this.creando = false;
            if (result.data.data != "OK") {
              this.$alert(result.data.data);
            }
            else{
              this.$emit('toggle', 'refresh')
            }
          })
          .catch(error => {
            this.creando = false;
            this.$alert(error);
          });
      });
    },
    markEventS(evento) {
      this.markersS = [];
      this.markersS.push({
        lat: evento.latLng.lat(),
        lng: evento.latLng.lng()
      });
    }
  }
};
</script>

<style>
li {
  list-style-type: none;
}
.contentLeft {
  text-align: left !important;
}
.vue__time-picker .dropdown {
  background-color: #fff2f6;
  border: 1px solid rgb(58, 58, 58) !important;
}
.vue__time-picker input.display-time {
  border-radius: 5px;
  border: 1px solid grey !important;
  padding: 4.5% !important;
}
</style>