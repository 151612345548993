const permisosMixims = {
    data() {
        return {
            permisos: [],
            permisosSegui: [],
            permisosDesp:[],
        }
    },
}

export default permisosMixims