<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="9" sm="9" xs="12">
        <!-- <v-autocomplete chips small-chips dense clearable v-model="searchR" :items="rutas" item-text="nombre"
          item-value="id" label="Buscar" @change="ciudadInfo('search')"></v-autocomplete> -->
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="4">
        <v-btn @click="ciudadInfo(null)" v-if="permisos.escritura" color="success">Agregar</v-btn>
      </v-col>
    </v-row>
    <v-card elevation="0" color="accent">
      <v-card-subtitle v-if="ciudades.length != 0">
        <v-row>
          <v-col style="text-aling:center" v-for="i in ciudades" v-bind:key="i.id">
            <v-col @click="ciudadInfo(i)">
              <v-img max-height="100" max-width="100"
                src="https://cdn2.iconfinder.com/data/icons/map-pins-and-navigation-solid/32/zone_area_location_map_pin_address_origin_address_chek_point-512.png">
              </v-img>
            </v-col>
            <br />
            <v-container>
              <v-row>
                <h3>{{i.nombre}}</h3>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-subtitle v-else>
        <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
      </v-card-subtitle>
    </v-card>
    <v-dialog max-width="70%" v-if="openAdd" v-model="openAdd">
      <add-ciudad :permisos="permisos" :info="informacionCiudad" v-on:toggle="eventosCiudad($event)"></add-ciudad>
    </v-dialog>
  </v-container>
</template>
<script>
import addCiudad from "./addCiudad";
import data from "../Tools/getData";
import permisosMixims from "../../mixims/permisos";

export default {
  mixins: [permisosMixims],
  components: {
    addCiudad
  },

  data() {
    return {
      openAdd: false,
      informacionCiudad: [],
      ciudades: [],
      searchR: null,
    };
  },

  async created() {
    // this.cargarCiudades();
    var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloBaseDatos")
    var permisosZona = findZona['permisos']
    var findPermisos = permisosZona.find(pgna => pgna.nombre === "Ciudades")
    this.permisos = findPermisos
    if (this.$store.state.ciudades == null)
      this.ciudades = await data.getData('/leeCiudades', 'ciudades')
    else {
      this.ciudades = this.$store.state.ciudades
    }
  },

  methods: {
    eventosCiudad(a) {
      console.log(a);
      this.openAdd = false;
      if (a == "refresh") {
        this.cargarCiudades();
      }
    },

    ciudadInfo(a) {
      this.openAdd = true;
      if (a == null) {
        this.informacionCiudad = null;
      } else if (a == "search") {
        const resultado = this.rutas.find(rut => rut.id === this.searchR);
        this.informacionCiudad = resultado;
      } else {
        this.informacionCiudad = a;
      }
    }
  }
};
</script>
