<template>
    <v-container>
        <v-row class="justify-end">
            <v-col class="mx-0 px-0" cols="12" md="2">
                <v-btn color="warning" @click="renovar()" v-if="mes != null && permisos.escritura"> renovar mes
                </v-btn>
            </v-col>
            <v-col class="mx-1 px-0" cols="12" md="2">
                <v-btn color="success" v-if="mes && permisos.escritura" @click="rodamientoInfo(null, null)"> Crear
                    rodamiento </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="12">
                <v-select @change="listaRodamientosMes" item-value="key" item-text="text" v-model="mes"
                    label="Selecciona el mes de consulta" :items="listamMeses">
                </v-select>
            </v-col>
        </v-row>

        <v-container v-for="f, index in rodamientos" v-bind:key="f">
            <h5 class="large secondaryT bold center"> {{ index}}</h5>
            <v-container v-if="cargando[index]">
                <v-data-table v-if="items[index].length != 0" :footer-props="{
                    'items-per-page-text': 'items por página',
                    'items-per-page-all-text':'Todos'
                    }" dense 
                    :headers="headers[index]" 
                    :items-per-page="items[index].length"
                     :items="items[index]"
                     :sort-by="index != 'PORTAL'? 'hora' : 'numero'"
                     >
                    <!-- <template v-if="index != 'PORTAL'" v-slot:item.hora="{item}">
                        <b>{{item[index].hora | moment() }}</b>
                    </template> -->

                    <!-- <template v-if="index == portal" v-slot:item.numero="{item}">
                        <b>{{item[index].numero }}</b>
                    </template> -->

                    <template v-if="index != 'PORTAL'" v-slot:body>
                        <tbody>
                            <tr :key="items[index][i]" v-for="i in items[index]">
                                <td :key="headers[index][g]" v-for="g in headers[index]" @click="rodamientoInfo(i, g)">
                                    <h4
                                        v-bind:style="[g.value == 'hora' ? { 'color': '#e79420', 'font-weight': '700' } : getCol(i, g) == true ? { 'color': '#44BBA4', 'font-weight': '500' } : { 'color': '#021727', 'font-weight': '500' } ]">
                                        {{ g.value == 'hora' ? i.hora : getVal(i, g)}}</h4>
                                    <v-container class="noPadding" v-if="getVal(i, g)">
                                    </v-container>
                                </td>
                            </tr>
                        </tbody>
                    </template>

                    <template v-else v-slot:body>
                        <tbody>
                            <tr :key="items[index][f]" v-for="f in items[index]">
                                <td :key="headers[index][d]" v-for="d in headers[index]" @click="rodamientoInfo(f, d)">
                                    <h4
                                        v-bind:style="[d.value == 'numero' ? { 'color': '#e79420', 'font-weight': '700' } : getCol(f, d) == true ? { 'color': '#44BBA4', 'font-weight': '500' } : { 'color': '#021727', 'font-weight': '500' } ]">
                                        {{ d.value == 'numero' ? f.numero : getVal(f, d)}}</h4>
                                    <v-container class="noPadding" v-if="getVal(f, d)">
                                    </v-container>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template v-slot:header="{ header }">
                        <h4 class="middle errorT thin">{{ header }}</h4>
                    </template>
                </v-data-table>
                <!-- si items[index].length == 0 osea que no tiene rodas -->
                <v-container v-if="items[index].length == 0 && mes != null">
                    <h5> * No tienes rodamientos agendados </h5>
                </v-container>
            </v-container>
            <v-container v-else>
                <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
            </v-container>
        </v-container>
        <v-dialog v-if="openAdd" v-model="openAdd" max-width="40%">
            <crear-rodamiento :fechaS="fechaSearch" v-on:toggle="eventosCrear($event)"></crear-rodamiento>
        </v-dialog>

        <v-dialog v-if="openDelete" v-model="openDelete" max-width="40%">
            <delete-rodamiento :fechaS="fechaSearch" :infoR="dataR" v-on:toggle="eventosDelete($event)">
            </delete-rodamiento>
        </v-dialog>

    </v-container>
</template>

<script>
import getToken from "../Tools/token"
import {host} from "../Tools/variables";
import CrearRodamiento from './CrearRodamiento';
import DeleteRodamiento from "./DeleteRodamiento.vue"
import data from "../Tools/getData";
import createTree from 'functional-red-black-tree';

import permisosMixims from "../../mixims/permisos";

export default {
    mixins: [permisosMixims],
    name: 'ByMonth',
    components:{
        CrearRodamiento,
        DeleteRodamiento
        // EditarRodamiento
    },
    data() {
        return {
            openAdd:false,
            openDelete: false,
            rodamientos:[],
            trees: null,
            trees2: null,
            mes: null,
            listamMeses: [],
            listaTipo: ['Todas las líneas', 'Primera del día'],
            cargando: [],
            items:[],
            rodamientosB:[],
            dataR: null,
            headers: [],
            mostrar: 'Primera del día',
        }
    },
    async created() {
        var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloRodamientos")
        var permisosZona = findZona['permisos']
        var findPermisos = permisosZona.find(pgna => pgna.nombre === "Rodamientos")
        var findPermisos1 = permisosZona.find(pgna => pgna.nombre === "Seguimiento")
        this.permisos = findPermisos
        this.permisosSegui = findPermisos1
    
        var dt = new Date();
        var year = dt.getFullYear();
        for (let m = 0; m < 12; m++) {
        var info =
        this.$moment(new Date(year, m, "1")).format("MMMM") + "-" + year;
        var d = {
            text: this.capitalizeFirstLetter(info),
            key: this.$moment(new Date(year, m, "1")).format("YYYY-MM-DD") 
        }
            this.listamMeses.push(d);
        }
        this.rodamientosBase()
    },
    methods: {

        async listaRodamientosMes() {
            this.cargando = [];
            this.rodamientos = []
            await data.getDataRoda('/listarRodamientos', 'comienzo', this.mes, 'SI').then(info => {
                if (info != 'Error') {
                    this.rodamientos = info.listaDetallada
                    this.trees = new Object()
                    var d = Object.keys(this.rodamientos)
                    for (let index = 0; index < d.length; index++) {
                        const element = d[index];
                        
                        // Acá vamos a armar el arbolito
                        let arbolito = createTree()
                        this.rodamientos[element].sort((a,b) => a.horaSalida.localeCompare(b.horaSalida));
                        for (let aa = 0; aa < this.rodamientos[element].length; aa++) {
                            const rod_ = this.rodamientos[element][aa];
                            if(element == 'PORTAL'){
                                let numE = rod_.numEspecial
                                let numEP = arbolito.find(numE).value 
                                if(numEP == null){
                                    arbolito = arbolito.insert(numE, [rod_])
                                }
                                else{
                                    numEP.push(rod_);
                                    arbolito.remove(numE)
                                    arbolito = arbolito.insert(numE, numEP)
                                }
                            }else{
                                let horaHHmm = this.$moment(rod_.horaSalida).format("HH:mm")
                                let rodP = arbolito.find(horaHHmm).value;
                                if(rodP == undefined){
                                    arbolito = arbolito.insert(horaHHmm, [rod_])
                                }
                                else{
                                    rodP.push(rod_);
                                    arbolito.remove(horaHHmm);
                                    arbolito = arbolito.insert(horaHHmm, rodP)
                                }
                            }
                        }
                        this.trees[element] = arbolito
                        this.cargarMes(element)
                    }
                }
                else {
                    this.$alert('Error')
                }
            });
    },

        async rodamientosBase() {
            await data.getData('/listarRodamientoBase', 'rodamientosBase').then(info => {
                if (info != 'Error') {
                    this.rodamientosB = info
                }
                else {
                    this.$alert('Error')
                }
            })
        this.cargarMes();
    },

    cargarMes(index) {
        if(index == 'PORTAL'){
            this.headers[index] = [{
                text: "Numero",
                value: "numero"
                }];
            this.items[index] = []
            if (this.mes) {
                var laFech1 = this.mes.split('-')
                var dt1 = new Date(laFech1[0], laFech1[1] -1, laFech1[2]);
                var month1 = dt1.getMonth();
                var year1 = dt1.getFullYear();
                var daysInMonth1 = new Date(year1, month1+1, 0).getDate();
                for (let t = 0; t < daysInMonth1; t++) {
                    this.headers[index].push({
                        text: this.$moment(dt1)
                            .add(t, "days")
                            .format("dddd, D/MM/YYYY"),  // dddd, ....
                        value: this.$moment(dt1)
                            .add(t, "days")
                            .format("dddd, D/MM/YYYY"),
                        sortable: false
                    });
                }
                if (this.rodamientos[index].length != 0) {
                    var numeros = []
                    for (let r = 0, len = this.rodamientos[index].length; r < len; r++) {
                        if(!numeros.includes(this.rodamientos[index][r].numEspecial)){
                             numeros.push(this.rodamientos[index][r].numEspecial);
                        }
                    }
                    for (let n = 0; n < numeros.length; n++) {
                        var info1 = {};
                        const element = numeros[n];
                        info1['numero'] = element
                        var rod1 = this.trees[index].find(element).value;
                            for (let d = 0; d < rod1.length; d++) {
                            const toFech = rod1[d];
                            if (toFech.rodamientoBase.orden === 1) {
                                info1[this.$moment(toFech.horaSalida).format("dddd, D/MM/YYYY")] = {
                                    bus: toFech.bus.codigo,
                                    info: toFech
                                }
                            }
                        } 
                        this.items[index].push(info1);
                    }
                     this.cargando[index] = true;
                } 
            }
        }else{
            this.headers[index] = [{
                text: "Hora",
                value: "hora"
                }];
            this.items[index] = []
            if (this.mes) {
                var laFech = this.mes.split('-')
                var dt = new Date(laFech[0], laFech[1] -1, laFech[2]);
                var month = dt.getMonth();
                var year = dt.getFullYear();
                var daysInMonth = new Date(year, month+1, 0).getDate();
                for (let t = 0; t < daysInMonth; t++) {
                    this.headers[index].push({
                        text: this.$moment(dt)
                            .add(t, "days")
                            .format("dddd, D/MM/YYYY"),  // dddd, ....
                        value: this.$moment(dt)
                            .add(t, "days")
                            .format("dddd, D/MM/YYYY"),
                        sortable: false
                    });
                }
                if (this.rodamientos[index].length != 0) {
                    var horaV = []
                    for (let r = 0, len = this.rodamientos[index].length; r < len; r++) {
                        var info = {};
                        const element = this.rodamientos[index][r];
                            info['hora'] = this.$moment(element.horaSalida).format("HH:mm")
                            // Acá, la idea es generar una lista de rodamientos de la ciudad index
                            var rod = this.trees[index].find(info["hora"]).value;
                            for (let r = 0; r < rod.length; r++) {
                                const toFech = rod[r];
                                if (toFech.rodamientoBase.orden === 1) {
                                    info[this.$moment(toFech.horaSalida).format("dddd, D/MM/YYYY")] = {
                                        bus: toFech.bus.codigo,
                                        info: toFech
                                    }
                                }
                            }
                            if (horaV.indexOf(info['hora']) == -1) {
                                this.items[index].push(info);
                            }
                            if (horaV.indexOf(this.$moment(element.horaSalida).format('HH:mm')) == -1) horaV.push(this.$moment(element.horaSalida).format("HH:mm"))
                    }
                    this.items[index].push({ hora: '', });
                }
                this.cargando[index] = true;
                }
            }
        },

        cargarMesTodos() {
            this.cargando = []
            var d = Object.keys(this.rodamientos)
            for (let index = 0; index < d.length; index++) {
                const ele = d[index];
                console.log(ele)
                this.items[ele] = []
                this.headers[ele] = [{
                    text: "Hora",
                    value: "hora"
                }];
                var dt = new Date(this.mes);
                var month = dt.getMonth();
                var year = dt.getFullYear();
                var daysInMonth = new Date(year, month, 0).getDate();
                for (let t = 0; t < daysInMonth; t++) {
                    this.headers[ele].push({
                        text: this.$moment(dt)
                            .add(t, "days")
                            .format("dddd, D/MM/YYYY"),  // dddd, ....
                        value: this.$moment(dt)
                            .add(t, "days")
                            .format("dddd, D/MM/YYYY"),
                        sortable: false
                    });
                }
                if (this.rodamientos[ele].length != 0) {
                    var horaV = []
                    for (let r = 0, len = this.rodamientos[ele].length; r < len; r++) {
                        var info = {};
                        const element = this.rodamientos[ele][r];
                        info['hora'] = this.$moment(element.horaSalida).format("HH:mm")
                        var rod = this.rodamientos[ele].filter(data => {
                            return this.$moment(data.horaSalida).format('HH:mm') == info['hora']
                        });
                        for (let r = 0; r < rod.length; r++) {
                            const toFech = rod[r];
                                info[this.$moment(toFech.horaSalida).format("dddd, D/MM/YYYY")] = {
                                    bus: toFech.bus.codigo,
                                    info: toFech,
                                }
                        }
                        if (horaV.indexOf(info['hora']) == -1) {
                            this.items[ele].push(info);
                        }
                        if (horaV.indexOf(this.$moment(element.horaSalida).format('HH:mm')) == -1) horaV.push(this.$moment(element.horaSalida).format("HH:mm"))
                    }
                    this.items[ele].push({ hora: '', });
                }
                this.cargando[ele] = true;
            }
        },

    getVal(i, item) {
        var data = Object.keys(i)
        var idx = data.indexOf(item.text)
        if (idx != -1 ) return i[data[idx]].bus;
        else {
            return null;
        }
        },

        getCol(i, item) {
            var data = Object.keys(i)
            var idx = data.indexOf(item.text)
            if (idx != -1) {
                return i[data[idx]]['info']['editado'] == true;

            } else {
                return false;
            }
        },

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

        rodamientoInfo(a, y) {
            // console.log(a)
            // console.log(y)
        if(a == null){
            this.openAdd = true;
        }else{
        var kys = Object.keys(a)
        var vls = Object.values(a)
        var idx = kys.indexOf(y.text)
            var data = vls[idx]
            if(data.info){
                this.dataR = data.info;
                this.openDelete = true;
            }
        }
        var d = y.text.split(',')
        var date = d[1].split('/')
        this.fechaSearch = this.$moment(new Date(date[2], date[1]-1, date[0])).format('YYYY-MM-DD')
        },
    
        renovar(){
            getToken().then(token => {
                const fd = new FormData();
                fd.append("token", token);
                fd.append("mes",  this.$moment(this.mes).add(1, 'month').format('YYYY-MM'));
                this.axios
                .post(host + "/renovarRodamiento", fd)
                .then(result => {
                    var data = result.data
                    if(data.data == 'OK'){
                     this.listaRodamientosMes()
                    }
                })
                .catch(error => {
                    this.cargando = true;
                    this.$alert(error);
                });
            });
        },

        vistaMes() {
            this.items = []
            this.headers = []
            if (this.mostrar == 'Todas las líneas') {
                this.cargarMesTodos()
            } else {
                this.cargarMes()
            }
        },
        eventosCrear(a){
            this.openAdd = false;
            if(a ==  "refresh")
                this.listaRodamientosMes()
        },
        eventosDelete(a){
            this.openDelete = false;
            if(a ==  "refresh")
                this.listaRodamientosMes()
            if(a == 'irDia'){
                this.$emit('eventosM', {'name': 'irDia', 'value': this.fechaSearch})
            }
        }
    },

}
</script>
