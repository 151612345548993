<template>
  <v-card color="accent">
    <v-card-title>
      <v-icon large color="primary" @click="$emit('toggle', 'close')">mdi-arrow-left-bold</v-icon>
      <v-spacer></v-spacer>
      <v-icon color="primary" v-if="permisos.escritura" :disabled="wait" @click="edit('SI')">mdi-delete</v-icon>
    </v-card-title>
    <v-card-subtitle>
      <v-container>
        <v-col>
          <h3 class="medium primaryT fat">{{name}}</h3>
        </v-col>
        <v-row>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field class="contenedor__login-textField" outlined dense v-model="bus.identificacion"
              :rules="[rules.required]" label="Número de vehículo *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field class="contenedor__login-textField" outlined dense v-model="bus.deviceId"
              :rules="[rules.required]" label="ID DSS *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="bus.marca" :rules="[rules.required]" label="Marca *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="bus.modelo" :rules="[rules.required]" label="Modelo *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="bus.year" :rules="[rules.required]" label="Año *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="bus.noMatricula" :rules="[rules.required]"
              label="Número de matrícula *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="bus.placa" :rules="[rules.required]" label="Placa *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense v-model="bus.vencimientoSOAT" label="Fecha de vencimiento SOAT *"
                  hint="YYYY-MM-DD" :rules="[rules.required]" persistent-hint v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker locale="es" v-model="bus.vencimientoSOAT" no-title @input="menu1 = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <cargue-files :tipo="{documento:'SOAT', title: 'Foto del SOAT', id: 'fileInputS'}"
              :fotoDocumentos="fotoSOATGuardadasF" v-on:data-delete="deleteFiles($event, 'SOAT')"
              v-on:toggle="eventosFiles($event, 'SOAT')"></cargue-files>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense v-model="bus.vencimientoTM" label="Fecha de vencimiento tecnomecánica *"
                  hint="YYYY-MM-DD" :rules="[rules.required]" persistent-hint v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker locale="es" v-model="bus.vencimientoTM" no-title @input="menu2 = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <cargue-files :tipo="{documento:'TM', title: 'Foto de la Técnico mecánica', id: 'fileInputT'}"
              :fotoDocumentos="fotoTMGuardadasF" v-on:data-delete="deleteFiles($event, 'TM')"
              v-on:toggle="eventosFiles($event, 'TM')"></cargue-files>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="bus.noChasis" :rules="[rules.required]" label="No. Chasis *">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="bus.noMotor" :rules="[rules.required]" label="No. Motor *">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-select label="Conductor" v-if="conductores" :items="conductores" v-model="bus.defaultCond"
              item-text="usuarioId__nombres" item-value="id"></v-select>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="bus.observaciones" label="Observaciones"></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <h3 class="normal primaryT fit">Información del propietarío:</h3>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="bus.nombreProp" :rules="[rules.required]" label="Nombre *">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model=" bus.direccionProp " label="Dirección y ciudad"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="bus.docProp" label="Documento *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="bus.correoProp" label="Correo electrónico *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <cargue-files :tipo="{documento:'Bus', title: 'Fotos del Bus', id: 'fileInputB'}"
              :fotoDocumentos="fotoBusGuardadasF" v-on:data-delete="deleteFiles($event, 'Bus')"
              v-on:toggle="eventosFiles($event, 'Bus')"></cargue-files>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" md="4" sm="6" xs="12">
            <cargue-files :tipo="{documento:'Extras', title: 'Documentos adicionales', id: 'fileInputDE'}"
              :fotoDocumentos="fotoDEGuardadasF" v-on:data-delete="deleteFiles($event, 'Extras')"
              v-on:toggle="eventosFiles($event, 'Extras')"></cargue-files>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-container v-for="i, n in bus.vencimientos" v-bind:key="n">
              <v-divider></v-divider>
              <v-row>
                <h3 class="middle errorT fat pBtn"> • Alerta extra {{n+1}}</h3>
                <v-icon class="pBtn" @click="reduce(n)"> mdi-delete</v-icon>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" sm="6" xs="12">
                  <v-menu :ref="menus[n]" v-model="menus[n]" :close-on-content-click="false"
                    transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined dense clearable v-model="bus.vencimientos[n]" label="Fecha de vencimiento"
                        hint="YYYY-MM-DD" :rules="[rules.required]" persistent-hint v-bind="attrs" v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker locale="es" v-model="bus.vencimientos[n]" no-title @input="menus[n] = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" xs="12" sm="6">
                  <v-select item-text="tipo" item-value="tipo" label="Tipo alerta" v-model="alertaId[n]"
                    @change="agregarNombre(n, alertaId[n])" :items="listaAlertas"></v-select>
                </v-col>
                <v-col v-if="alertaId[n] == 'Otra'" cols="12" md="4" xs="4" sm="12">
                  <v-text-field outlined dense v-model="alertaNames[n]" label="Nombre alerta"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-btn :disabled="!permisos.escritura" @click="addA(n)" color="primary">Agregar alertas</v-btn>
          </v-col>
          <!-- <h3>{{fotoBusSend}}</h3>
              <h3>{{fotoSOATSend}}</h3>
              <h3>{{fotoTMSend}}</h3>
              <h3>{{fotoDESend}}</h3> -->
          <v-col cols="12" md="12" sm="12" xs="12" v-if="infoBus != null">
            <ver-posicion :busId="bus.id" :name="bus.deviceId"></ver-posicion>
          </v-col>
        </v-row>
      </v-container>
    </v-card-subtitle>
    <v-card-actions v-if="permisos.escritura">
      <v-spacer></v-spacer>
      <v-icon x-large color="warning" @click="$emit('toggle', 'close')">mdi-close-circle</v-icon>
      <v-icon x-large color="success" :disabled="wait" @click="bus.id == null ?  saveBus(): edit('')">mdi-check</v-icon>
    </v-card-actions>
  </v-card>
</template>

<script>
import {host} from "../Tools/variables";
import getToken from "../Tools/token";
import cargueFiles from "../ComponentesGenerales/CargueFiles.vue";
import VerPosicion from '../ComponentesGenerales/VerPosicion.vue';
export default {
  name: "addBus",
  props: ["infoBus", "permisos"],
  components:{
    cargueFiles,
    VerPosicion
  },
  data() {
    return {
      name: 'Agregar bus', 
      wait: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menus:[],
      bus: {
        id: null,
        deviceId: null,
        correoProp: null,
        direccionProp: null,
        docProp: null,
        fotoBus: [],
        fotoSOAT: [],
        fotoTM: [],
        identificacion: null,
        marca: null,
        modelo: null,
        noChasis: null,
        noMatricula: null,
        noMotor: null,
        nombreProp: null,
        organizacion: null,
        placa: null,
        vencimientoSOAT: null,
        vencimientoTM: null,
        year: null,
        defaultCond: null, 
        fotosExtra: [],
        vencimientos:[],
      },
      fotoBusSend: [],
      fotoSOATSend: [],
      fotoTMSend: [],
      fotoDESend: [],
      // fotos guardadas mostrar
      fotoBusGuardadasF: [],
      fotoSOATGuardadasF: [],
      fotoTMGuardadasF: [],
      fotoDEGuardadasF: [],
      // fotos a borrar
      bBus: [],
      bSOAT: [],
      bTM: [],
      bDE: [],
      rules: {
        required: value => !!value || "Campo requerido."
      },
      markersS: [],
      center: { lat: 4.668131,lng: -74.092026},
      conductores: [], 
      documentos: {
        num: 0
      }, 
      alertaNames:[], 
      alertaId: [],
      listaAlertas:[],
      // noLineasAl: 0
    };
  },
  created() {
    getToken().then(token => {
      const fd = new FormData();
      fd.append("token", token);
      fd.append("dia", this.fecha);
      this.axios.post(host + "/listaConductores", fd).then(data => {
        let backConductores = data.data.lista;
        this.conductores = backConductores;
      });
    });
    getToken().then(token => {
      const fd = new FormData();
      fd.append("token", token);
      this.axios.post(host + "/leerAlertas", fd).then(result => {
        var alert = result.data.lista
        for (let r = 0; r < alert.length; r++) {
          const element = alert[r];
          console.log(element)
          if (element.tipo != 'SOAT' && element.tipo != 'Técnico Mecánico')
             this.listaAlertas.push(element)
        }
        // this.listaAlertas.push({tipo: 'Otra', id: 'otra'})
      });
    });
  },
  mounted() {
    if (this.infoBus != null) {
      this.name = 'Editar bus'
      this.bus = this.infoBus;
      this.bus.vencimientoSOAT = this.$moment(
        this.infoBus.vencimientoSOAT
      ).format("YYYY-MM-DD");
      this.bus.vencimientoTM = this.$moment(this.infoBus.vencimientoTM).format(
        "YYYY-MM-DD"
      );
      this.fotoSOATGuardadasF = this.infoBus.fotoSOAT;
      this.fotoTMGuardadasF = this.infoBus.fotoTM;
      this.fotoBusGuardadasF = this.infoBus.fotoBus;
      this.fotoDEGuardadasF = this.infoBus.fotosExtra;
      this.bus.fotoSOAT = [];
      this.bus.fotoTM = [];
      this.bus.fotoBus = [];
      this.bus.fotosExtra = [];
      if(this.infoBus.vencimientos.length != 0){
        this.cargarAlertas(this.bus.vencimientos)
      }
    }
  },
  methods: {
    eventosFiles(e, tipo){
      console.log('Evento File')
      console.log(e)
      switch (tipo){
        case 'SOAT':
          this.bus.fotoSOAT = this.fotoSOATSend = [];
          this.fotoSOATSend = e;
          var s = this.fotoSOATSend;
          for (let i = 0; i < s.length; i++) {
            this.bus.fotoSOAT.push(s[i].name);
          }
          console.log(this.fotoSOATSend)
          break;
        case 'TM':
          this.bus.fotoTM = this.fotoTMSend = [];
          this.fotoTMSend = e;
          var tm = this.fotoTMSend;
          console.log(this.fotoTMSend)
          for (let i = 0; i < tm.length; i++) {
            this.bus.fotoTM.push(tm[i].name);
          }
          break;
        case 'Bus':
          this.bus.fotoBus = this.fotoBusSend = [];
          this.fotoBusSend = e;
          var b = this.fotoBusSend;
          for (let i = 0; i < b.length; i++) {
            this.bus.fotoBus.push(b[i].name);
          }
          break;
        case 'Extras':
          this.bus.fotosExtra = this.fotoBusSend = [];
          this.fotoDESend = e;
          var de = this.fotoDESend;
          for (let i = 0; i < de.length; i++) {
            this.bus.fotosExtra.push(de[i].name);
          }
          break;
        default:
          break;
      }
    },
    deleteFiles(i, tipo) {
      switch (tipo) {
        case "SOAT": {
          this.bSOAT.push(i);
          break;
        }
        case "Bus": {
          this.bBus.push(i);
          break;
        }
        case "TM": {
          this.bTM.push(i);
          break;
        }
        case 'Extras': {
          this.bDE.push(i);
          break;
        }
        default:
          break;
      }
    },
    cargarAlertas(a){
      var va = Object.values(a)
      this.noLineasAl = va.length
      for (let t = 0; t < va.length; t++) {
        const element = va[t];
        this.bus.vencimientos[t] = this.$moment(element.fecha).format('YYYY-MM-DD')
          this.alertaNames[t] = element.nombre
          this.alertaId[t] = element.nombre
       
      }
    },
    markEventS(evento) {
      this.markersS = [];
      this.markersS.push({
        lat: evento.latLng.lat(),
        lng: evento.latLng.lng()
      });
    },
    addA(a) {
      this.bus.vencimientos.push(null)
      this.bus.vencimientos[a] = this.$moment(Date.now()).format("YYYY-MM-DD")
      this.menus[a] = false
      this.alertaNames.push('null')
    },
    
     reduce(n) {
      this.alertaNames.splice(n,1)
      this.menus.splice(n,1)
      this.bus.vencimientos.splice(n,1)
    },
    saveBus() {
      let sAlertas = ''
      if(this.alertaNames.length != 0) {
        sAlertas = this.alertaNames.toString().replaceAll(',', '=Solkaflam777=')
      } 
      let fAlertas = []
      for (let d = 0; d < this.bus.vencimientos.length; d++) {
        const element = this.bus.vencimientos[d];
        if(element == this.$moment(Date.now()).format("YYYY-MM-DD") || element == null){
          fAlertas.push('null')
        }else{
          fAlertas.push(element)
        }
      }
      if (
        this.bus.placa == null ||
        this.bus.deviceId == null ||
        this.bus.identificacion == null ||
        this.bus.vencimientoSOAT == null ||
        this.bus.year == null ||
        this.bus.vencimientoTM == null ||
        this.bus.marca == null ||
        this.bus.modelo == null ||
        this.bus.noMatricula == null ||
        this.bus.noChasis == null ||
        this.bus.noMotor == null ||
        this.bus.nombreProp == '' ||
        this.bus.docProp == '' ||
        this.bus.correoProp == ''
      ) {
        this.$alert(
          "Debes agregar todos los datos obligatorios, están marcados por un asterisco (*)"
        );
      } else {
        this.wait = true;
        getToken().then(token => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("deviceId", this.bus.deviceId);
          fd.append("correoProp", this.bus.correoProp);
          fd.append("direccionProp", this.bus.direccionProp);
          fd.append("docProp", this.bus.docProp);
          for (var i = 0; i < this.fotoBusSend.length; i++) {
            let file = this.fotoBusSend[i];
            fd.append("fotoBus", file);
          }
          // fd.append("fotoBus", this.fotoBusSend);
          for (var s = 0; s < this.fotoSOATSend.length; s++) {
            let fileS = this.fotoSOATSend[s];
            fd.append("fotoSOAT", fileS);
          }
          // fd.append("fotoSOAT", this.fotoSOATSend);
          // fd.append("tecnicoMecanico", this.fotoTMSend);
          for (var t = 0; t < this.fotoTMSend.length; t++) {
            let fileT = this.fotoTMSend[t];
            fd.append("tecnicoMecanico", fileT);
          }
          for (var de = 0; de < this.fotoDESend.length; de++) {
            let fileDE = this.fotoDESend[de];
            fd.append("fotosExtra", fileDE);
          }
          fd.append("identificacion", this.bus.identificacion);
          fd.append("marca", this.bus.marca);
          fd.append("modelo", this.bus.modelo);
          fd.append("noChasis", this.bus.noChasis);
          fd.append("noMatricula", this.bus.noMatricula);
          fd.append("noMotor", this.bus.noMotor);
          fd.append("nombreProp", this.bus.nombreProp);
          fd.append("placa", this.bus.placa);
          fd.append("defaultCond", this.bus.defaultCond);
          fd.append("vencimientoSOAT", this.bus.vencimientoSOAT);
          fd.append("vencimientoTM", this.bus.vencimientoTM);
          fd.append("year", this.bus.year);
          fd.append("fechas_alertas", fAlertas.toString());
          fd.append("alertas", sAlertas );
          let headers = {
            "Content-Type": "multipart/form-data"
          };
          this.axios.post(host + "/nuevoBus", fd, headers).then(result => {
            this.wait = false;
            let data = result.data;
            if (data.data == "OK") this.$emit("toggle", "refresh");
            else {
              this.wait = false;
              this.$alert(data.data);
            }
          });
        });
      }
    },
    edit(eliminar) {
       let sAlertas = ''
      if(this.alertaNames.length != 0) {
        sAlertas = this.alertaNames.toString().replaceAll(',', '=Solkaflam777=')
      } 
      let fAlertas = []
      for (let d = 0; d < this.bus.vencimientos.length; d++) {
        const element = this.bus.vencimientos[d];
        if(element == this.$moment(Date.now()).format("YYYY-MM-DD") || element == null){
          fAlertas.push('null')
        }else{
          fAlertas.push(element)
        }
      }
      if (
        this.bus.placa == "" ||
        this.bus.deviceId == "" ||
        this.bus.identificacion == "" ||
        this.bus.vencimientoSOAT == "" ||
        this.bus.vencimientoSOAT == "" ||
        this.bus.year == "" ||
        this.bus.vencimientoTM == "" ||
        this.bus.marca == "" ||
        this.bus.modelo == "" ||
        this.bus.noMatricula == "" ||
        this.bus.noChasis == "" ||
        this.bus.noMotor == "" ||
        this.bus.nombreProp == "" ||
        this.bus.docProp == "" ||
        this.bus.correoProp == ""
      ) {
        this.$alert(
          "Debes agregar todos los datos obligatorios, están marcados por un asterisco (*)"
        );
      } else {
        this.wait = true;
        getToken().then(token => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("busId", this.bus.id);
          fd.append("deviceId", this.bus.deviceId);
          fd.append("eliminar", eliminar);
          fd.append("correoProp", this.bus.correoProp);
          fd.append("direccionProp", this.bus.direccionProp);
          fd.append("docProp", this.bus.docProp);
          if (this.fotoBusSend.length != 0)
            for (var i = 0; i < this.fotoBusSend.length; i++) {
              let file = this.fotoBusSend[i];
              fd.append("fotoBus", file);
            }
          fd.append("fotoBus_borra", this.bBus);
          if (this.fotoSOATSend.length != 0)
            for (var s = 0; s < this.fotoSOATSend.length; s++) {
              let fileS = this.fotoSOATSend[s];
              fd.append("fotoSOAT", fileS);
            }
          fd.append("fotoSOAT_borra", this.bSOAT);
          if (this.fotoTMSend.length != 0)
            for (var t = 0; t < this.fotoTMSend.length; t++) {
              let fileT = this.fotoTMSend[t];
              fd.append("tecnicoMecanico", fileT);
            }
          fd.append("tecnicoMecanico_borra", this.bTM);
          if (this.fotoDESend.length != 0)
           for (var de = 0; de < this.fotoDESend.length; de++) {
            let fileDE = this.fotoDESend[de];
            fd.append("fotosExtra", fileDE);
          }
          fd.append("fotosExtra_borra", this.bDE);
          fd.append("identificacion", this.bus.identificacion);
          fd.append("marca", this.bus.marca);
          fd.append("modelo", this.bus.modelo);
          fd.append("noChasis", this.bus.noChasis);
          fd.append("noMatricula", this.bus.noMatricula);
          fd.append("noMotor", this.bus.noMotor);
          fd.append("nombreProp", this.bus.nombreProp);
          fd.append("placa", this.bus.placa);
          fd.append("vencimientoSOAT", this.bus.vencimientoSOAT);
          fd.append("vencimientoTM", this.bus.vencimientoTM);
          fd.append("year", this.bus.year);
          fd.append("fechas_alertas", fAlertas.toString());
          fd.append("alertas", sAlertas );
          fd.append(
            "defaultCond",
            this.bus.defaultCond == null ? -1 : this.bus.defaultCond
          );
          let headers = {
            "Content-Type": "multipart/form-data"
          };
          this.axios.post(host + "/editarBus", fd, headers).then(result => {
            this.wait = false;
            let data = result.data;
            if (data.data == "OK") this.$emit("toggle", "refresh");
            else {
              this.wait = false;
              this.$alert(data.data);
            }
          });
        });
      }
    },
    agregarNombre(b,a){
      if(a != 'otra'){
        this.alertaNames[b] = a
      }else{
        this.alertaNames[b] = 'null'
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.pBtn{
  padding-top: 2% !important;
  padding-bottom: 1% !important;
}


</style>