<template>
  <v-container>
    <v-card elevation="0" class="container_created">
      <v-card-subtitle>
        <v-row>
          <v-data-table no-results-text='Sin resultados' :headers="tiposUsuarios" :items="zonas"
            :items-per-page="zonas.length" group-by="tipo" class="elevation-0"
            :header-props="{'sortByText': 'Organizar por'}" :footer-props="{
                    'items-per-page-text': 'items por página',
                    'items-per-page-all-text':'Todos'
                    }">
            <template v-slot:[`item.permisos`]="{item}">
              <v-select :disabled="!permisos.escritura" v-model="item.permisos" multiple
                :items="['lectura', 'escritura', 'aprobacion', 'aprobador']" @change="editarP(item)"></v-select>
            </template>
          </v-data-table>
        </v-row>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>
<script>
import getToken from "../Tools/token";
import { host } from "../Tools/variables";

import permisosMixims from "../../mixims/permisos";

export default {
  mixins: [permisosMixims],

  data() {
    return {
      zonas: [],
      per: [],
      usuarios:[],
      tiposUsuarios:[{ text: 'Tipo de usuario', value: 'tipo', align: 'right', sortable: true,},
        { text: 'Zonas', value: 'zona', sortable: false},
        { text: 'Permisos', value: 'permisos', sortable: false}],
    };
  },

  created() {
    var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloConfiguracion")
    var permisosZona = findZona['permisos']
    var findPermisos = permisosZona.find(pgna => pgna.nombre === "Permisos")
    this.permisos = findPermisos

    getToken().then(token =>{
      const fd = new FormData();
      fd.append('token', token)
      this.axios.post(host + '/listarPermisos', fd).then(result =>{
        this.per = result.data.lista
        var vls = Object.values(this.per)
        // console.log(vls)
        for (let v = 0; v < vls.length; v++) {
          const element = vls[v];
          for (let tU = 0; tU < element.length; tU++) {
            const ele = element[tU];
            var per = [];
            if(ele['lectura']){
            per.push('lectura')
            }if(ele['escritura']){
              per.push('escritura')
            }if(ele['aprobador']){
              per.push('aprobador')
            }if(ele['aprobacion']){
              per.push('aprobacion')
            }
            this.zonas.push({'tipo': ele['tipo'], 'zona': ele['zona'], 'permisos': per})
          }
        }
      })
    })
    this.consultarUsuarios();
  },
  methods: {
    consultarUsuarios(){
      this.usuarios = []
      getToken().then(token=>{
      const fd = new FormData();
      fd.append("token", token);
      this.axios.post(host + "/listaUsuarios", fd).then(result =>{
        this.usuarios = result.data.lista
      })
    })
    },
      editarP(info){
         console.log(info)
         var data = Object.values(info.permisos)
        //  console.log(data.find(e => e == 'lectura'))
         console.log(data.indexOf('lectura'))
         getToken().then(token=>{
           const fd = new FormData();
           fd.append('token', token)
           fd.append('tipo', info.tipo)
           fd.append('zona', info.zona)
           fd.append('lectura', data.indexOf('lectura') == -1 ? false : true)
           fd.append('escritura', data.indexOf('escritura') == -1 ? false : true)
           fd.append('abrobacion', data.indexOf('abrobacion') == -1 ? false : true)
           fd.append('aprobador', data.indexOf('aprobador') == -1 ? false : true)
           this.axios.post(host + '/modificarPermisos' , fd).then(result=>{
             console.log(result)
             if(result.data.data == 'OK'){
               this.$alert(`Se modificaron los permisos de ${info.tipo} en la zona de ${info.zona}`)
             } else {
               this.$alert(`Error al modificar los permisos de ${info.tipo} en la zona de ${info.zona}`)
             }
           })
         })
      }
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-data-table{
  background-color: transparent !important;
}
h3 {
  font-size: 15px;
  font-weight: 400;
  /* color: red; */
}
</style>