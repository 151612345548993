<template>
  <v-card>
    <v-card-text>
      {{text}}
    </v-card-text>
    <v-card-subtitle v-for="k  in infoColors" v-bind:key="k">
      <h4 class="middle secondaryT thin"> {{ Object.keys(k).toString()}}: </h4>
      <li v-for="data  in Object.values(k)[0]" v-bind:key="data">
        {{Object.keys(data)[0].toString()}}: {{Object.values(data)[0].toString()}}
         <v-icon x-small
        :color="data.color">
        mdi-square</v-icon>
      </li>
     
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'PopupSemaforos',

    data() {
        return {
          infoColors: [{
            'Estado de ruta':
              [
                {
                  'Rojo': 'El bus ya salió pero no va muy bien de tiempo comparando los puntos de control',
                  'color': 'red'
                },
                {
                  'Amarillo': 'No ha salido el bus de la línea a tomar la ruta',
                  'color': 'yellow'
                },
                {
                  'Verde': 'Ya salió el bus y va bien de tiempo',
                  'color': 'green'
                },
                {
                  'Naranaja': 'No ha salido el bus y ya debería haber salido',
                  'color': 'orange'
                },
                {
                  'Azul': 'Terminó el recorrido de la línea',
                  'color': 'blue'
                }
              ]
          },
          {
            'Estado de pago':
                [
                  {
                    'Rojo': 'Pendiente de pago',
                    'color': 'red'
                  },
                  {
                    'Verde': 'Ya está pago',
                    'color': 'green'
                  },
                ]
            }
          ]

        }
    },

}
</script>

<style>

</style>