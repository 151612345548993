<template>
  <v-container>
    <v-spacer></v-spacer>


    <v-row class="justify-end">
      <v-col class="12" md="11">
        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="fechaFiltro" label="Seleciona la fecha de consulta" :rules="[rules.required]"
              hint="YYYY-MM-DD" persistent-hint v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker locale="es" v-model="fechaFiltro" no-title @change="filtroDia()" @input="menu1 = false">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="1">
        <v-icon class="pt-4" color="secondary" @click="popupinfo = true"> mdi-information-outline </v-icon>
        <v-dialog max-width="40%" v-if="popupinfo" v-model="popupinfo">
          <popup-semaforos>
          </popup-semaforos>
        </v-dialog>
      </v-col>
    </v-row>
      <v-data-table v-if="rodamientos.length" dense :sort-by="['horaSalida']" :sort-desc="[true]"
        multi-sort :headers="headersD" :items-per-page="rodamientos.length" :items="rodamientos" :footer-props="{
      'items-per-page-text': 'items por página',
      'items-per-page-all-text':'Todos'
      }">
        <template v-slot:item.horaSalida="{ item }">
          <span>{{ item.horaSalida | moment('HH:mm') }} </span>
        </template>
        <template v-slot:item.horaLlegada="{ item }">
          <span>{{ item.horaLlegada | moment('HH:mm') }} </span>
        </template>
        <template v-slot:item.estadoPago="{ item }">
          <v-icon :color="item.estadoPago == false ? 'red': 'green'">mdi-square</v-icon>
        </template>
        <template v-slot:item.estadoLinea="{ item }">
          <v-icon
            :color="item.estadoLinea == 0 ? 'yellow': item.estadoLinea == 1 ? 'orange' : item.estadoLinea == '2' ? 'green': item.estadoLinea == '3' ? 'red' : 'blue'">
            mdi-square</v-icon>
        </template>
        <template v-slot:item.edit="{ item }">
          <v-icon v-if="permisos.escritura" small @click="editarByday(item)">mdi-pencil</v-icon>
        </template>
        <template v-slot:item.mapa="{ item }">
          <v-btn v-if="permisosSegui.lectura" @click="verM(item)" small plain>Ver mapa</v-btn>
        </template>
        <template v-slot:header="{ headers}">
          <th v-for="item in headers" :key="headers[item]" class="large secondaryT fat">{{item.value}}</th>
        </template>
      </v-data-table>

      <v-container v-if="!isBuscando && rodamientos.length == 0 && fechaFiltro != null">
        <h5> * No tienes rodamientos agendados para este día</h5>
      </v-container>
      <v-container v-if="isBuscando">
            <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
      </v-container>
     
      <v-dialog max-width="60%" v-if="popupEdit && permisos.escritura" v-model="popupEdit">
        <edit-linea :fecha="fechaFiltro" :info="info" v-on:toggle="eventosEditL($event)"></edit-linea>
      </v-dialog>

      <v-dialog max-width="60%" v-if="verMapa && permisosSegui.lectura" v-model="verMapa">
        <ver-posicion :busId="busId" :name='nameBus' v-on:toggle="eventosVerMapa($event)"></ver-posicion>
      </v-dialog>
    
  </v-container>

</template>

<script>
import getToken from "../Tools/token"
import {host} from "../Tools/variables"
import EditLinea from './EditLinea.vue'
import verPosicion from "../ComponentesGenerales/VerPosicion"

import permisosMixims from "../../mixims/permisos"
import PopupSemaforos from "../ComponentesGenerales/PopupSemaforos.vue"

export default {
  mixins: [permisosMixims],

  name: 'ByDay',

  props: ['fecha'],

  components: {
    EditLinea,
    verPosicion,
    PopupSemaforos
},

  data() {
    return {
      menu1: false,
      info: [],
      popupinfo: false,
      busPosition: null,
      verMapa: false,
      busId: null,
      rules: {
        required: value => !!value || "Campo requerido."
      },
      fechaFiltro: null,
      cargando: false,
      popupEdit: false,
      popupDesp: false,
      rodamientos: [],
      isBuscando: false,
      hover: [],
      headersD: [
        {
          text: "Hora salida",
          value: "horaSalida"
        },
        {
          text: "Hora llegada",
          value: "horaLlegada"
        },
        {
          text: "Bus",
          value: "bus.codigo"
        },
        {
          text: "Ruta",
          value: "ruta.nombre"
        },
         {
          text: "Despacho",
          value: "despacho.nombre"
        },
        {
          text: "No. Rodamiento",
          value: "rodamientoBase.numero",
          sortable: true,
        },
        {
          text: "No. ruta",
          value: "rodamientoBase.orden",
          sortable: true,
        },
        {
          text: "Conductor",
          value: "conductor.nombre"
        },
        {
          text: "Estado pago",
          value: "estadoPago"
        },
        {
          text: "Estado Ruta",
          value: "estadoLinea"
        },

        {
          text: "Ver mapa",
          value: "mapa"
        },
        {
          text: "",
          value: "edit"
        }
      ],
      
    }
  },

  async created() {
    var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloRodamientos")
    var permisosZona = findZona['permisos']
    var findPermisos = permisosZona.find(pgna => pgna.nombre === "Rodamientos")
    var findPermisos1 = permisosZona.find(pgna => pgna.nombre === "Seguimiento")
    var findPermisos2 = permisosZona.find(pgna => pgna.nombre === "Despacho")
    this.permisos = findPermisos
    this.permisosSegui = findPermisos1
    this.permisosDesp = findPermisos2
  },

  mounted() {
    if(this.fecha){
      this.fechaFiltro = this.fecha
      this.filtroDia()
    }
  },
  methods: {
    verM(item) {
      this.verMapa = true;
      this.busId = item.bus.id
      this.nameBus = item.bus.codigo
      // la idea es mostrar el mapa con el hover
    },
    filtroDia() {
      this.isBuscando = true;
          var fecha = this.$moment(this.fechaFiltro).add('day', 1).format("YYYY-MM-DD") 
          getToken().then(token => {
              const fd = new FormData();
              fd.append("token", token);
              fd.append("comienzo",  this.fechaFiltro);
              fd.append("final", fecha);
              this.axios
              .post(host + "/listarRodamientos", fd)
                .then(result => {
                  this.isBuscando = false;
                  this.rodamientos = result.data.lista;
                  this.rodamientos.sort((a,b) => a.horaSalida.localeCompare(b.horaSalida));
                  for (let s = 0; s < this.rodamientos.length; s++) {
                    const element =  this.rodamientos[s];
                    this.hover[element.id]  = true
                  }
              })
              .catch(error => {
                  this.$alert(error);
              });
          });
    },
    editarByday(item) {
      this.info = item
      this.popupEdit = true;
      
    },

    eventosEditL(a) {
        this.popupEdit = false;
        if(a == 'refresh'){
        this.filtroDia()
      }
    },

    eventosDespacho(a) {
      this.popupDesp = false;
      if (a == 'refresh') {
        this.filtroDia()
      }
    },

    eventosVerMapa(){
      this.verMapa = false;
    }
  },
}
</script>

