<template>
  <v-container>
    <v-container>
      <v-icon @click="$router.push('/home')" large color="accent">mdi-arrow-left-bold</v-icon>
    </v-container>
    <v-card color="accent">
      <v-card-text>
        <h1 class="medium secondaryT fit ">Módulo Base de datos</h1>
      </v-card-text>
      <v-card-subtitle>
        <v-tabs v-model="tab" background-color="primary" center-active dark>
          <v-tab v-for="i in modulos" :class="i.escritura == true ? '' : 'info'" @click="clickTab(i)" :key="i.nombre">
            {{i.nombre}}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="tabsContainer">
          <v-tab-item>
            <conductores v-if="tienePermiso"></conductores>
            <h5 v-else> * No tienes permiso</h5>
          </v-tab-item>
          <v-tab-item>
            <buses v-if="tienePermiso"></buses>
            <h5 v-else> * No tienes permiso </h5>
          </v-tab-item>
          <v-tab-item>
            <ciudades v-if="tienePermiso"></ciudades>
            <h5 v-else> * No tienes permiso </h5>
          </v-tab-item>
          <v-tab-item>
            <rutas v-if="tienePermiso"></rutas>
            <h5 v-else> * No tienes permiso </h5>
          </v-tab-item>
          <v-tab-item>
            <rodamientos-b v-if="tienePermiso"></rodamientos-b>
            <h5 v-else> * No tienes permiso </h5>
          </v-tab-item>
        </v-tabs-items>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
import Buses from './../ModuloBaseDatos/Buses';
import Conductores from './../ModuloBaseDatos/Conductores';
import RodamientosB from './../ModuloBaseDatos/RodamientosB';
import Rutas from './../ModuloBaseDatos/Rutas';
import Ciudades from './../ModuloBaseDatos/Ciudades.vue';
export default {
  components: { Conductores, Buses, Rutas, RodamientosB, Ciudades },
  data() {
    return {
      tab: 0,
      tienePermiso: true,
      modulos: [],
    };
  },
   created(){
    var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloBaseDatos")
     this.modulos = findZona['permisos']
  },
  mounted() {},
  methods: {
    clickTab(data){
      this.tienePermiso = data.lectura
    }
   
  }
};
</script>

<style lang="scss" scoped>
.tabsContainer{
  background-color: transparent !important;
}
</style>