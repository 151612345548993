<template>
  <v-card color="accent">
    <v-card-title>
      <v-icon large color="primary" @click="$emit('toggle', 'close')"
        >mdi-arrow-left-bold</v-icon
      >
      <v-spacer></v-spacer>
      <v-icon
        color="primary"
        :disabled="wait"
        v-if="permisos.escritura"
        @click="edit('SI')"
        >mdi-delete</v-icon
      >
    </v-card-title>
    <v-card-subtitle>
      <v-container>
        <v-col>
          <h3 class="medium primaryT fat">{{ name }}</h3>
        </v-col>
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-text-field
              class="contenedor__login-textField"
              outlined
              dense
              v-model="ruta.nombre"
              :rules="[rules.required]"
              label="Nombre de la ruta *"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-select
              class="contenedor__login-textField"
              dense
              :items="ciudades"
              item-text="nombre"
              v-model="ruta.ciudadSalida"
              label="Ciudad de salida *"
            ></v-select>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <h4 class="normal primaryT">
              {{ ruta.puntos[1]["nombre"] }}
            </h4>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <GmapMap
              :center="centerS"
              :zoom="10"
              style="height: 200px"
              @click="markEventS"
            >
              <GmapMarker
                v-model="markersS"
                :key="index[m]"
                v-for="(m, index) in markersS"
                :position="m"
                @click="centerS = m"
                :draggable="true"
                :clickable="true"
              />
            </GmapMap>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-select
              class="contenedor__login-textField"
              dense
              :items="ciudades"
              item-text="nombre"
              v-model="ruta.ciudadLlegada"
              label="Ciudad de llegada *"
            ></v-select>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <h4 class="normal primaryT">
              {{ ruta.puntos[2]["nombre"] }}
            </h4>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <GmapMap
              ref="mapRef"
              :center="centerL"
              :zoom="10"
              style="height: 200px"
              @click="markEventL"
            >
              <GmapMarker
                :key="index[m]"
                v-for="(m, index) in markersL"
                :position="m"
                @click="centerL = m"
                :draggable="true"
                :clickable="true"
              />
            </GmapMap>
          </v-col>
          <v-col cols="12" md="12" sm="6" xs="12">
            <v-text-field
              outlined
              dense
              v-model="ruta.observaciones"
              label="Observaciones"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field
              outlined
              dense
              v-model="ruta.horas"
              label="Duración en horas"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field
              outlined
              dense
              v-model="ruta.minutos"
              label="Duración en minutos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field
              outlined
              dense
              v-model="ruta.segundos"
              label="Duración en segundos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field
              outlined
              dense
              v-model="ruta.precio"
              label="Valor de despacho"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col>
          <h3 class="normal primaryT fit">Puntos de control:</h3>
        </v-col>
        <v-col cols="12" md="12" sm="6" xs="12">
          <v-text-field
            outlined
            dense
            v-model="ruta.cantidadPuntos"
            @change="calcularPC('edit')"
            label="Cantidad puntos de control"
          ></v-text-field>
        </v-col>
        <template v-if="ruta.puntosControl != {}">
          <v-col
            cols="12"
            md="12"
            sm="6"
            xs="12"
            v-for="(j, i) in parseInt(ruta.cantidadPuntos)"
            v-bind:key="j + i"
          >
            <GmapMap
              ref="mapRef"
              :center="
                name == 'Editar ruta' && centerE[i] != undefined
                  ? centerE[i]
                  : centerC
              "
              :zoom="10"
              style="height: 200px"
              @click="markEventPC(i, $event)"
            >
              <GmapMarker
                :key="index[m]"
                v-for="(m, index) in puntosControl[i]"
                :position="m"
                @click="centerC = m"
                :draggable="true"
                :clickable="true"
              />
            </GmapMap>
            <v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  outlined
                  dense
                  v-model="ruta.puntosControl[i].minutes"
                  label="Tiempos en minutos"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  outlined
                  dense
                  v-model="ruta.puntosControl[i].seconds"
                  label="Tiempos en segundos"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <h2 hidden>{{ ruta.puntosControl }}</h2>
            <h2 hidden>{{ puntosControl }}</h2>
          </v-col>
        </template>
      </v-container>
    </v-card-subtitle>
    <v-card-actions v-if="permisos.escritura">
      <v-spacer></v-spacer>
      <v-icon x-large color="warning" @click="$emit('toggle', 'close')"
        >mdi-close-circle</v-icon
      >
      <v-icon
        x-large
        color="success"
        :disabled="wait"
        @click="infoRuta == null ? comprobarRuta() : edit('')"
        >mdi-check
      </v-icon>
    </v-card-actions>
  </v-card>
</template>

<script>
import { host } from "../Tools/variables";
import getToken from "../Tools/token";
export default {
  name: "addRuta",
  props: ["infoRuta", "permisos"],
  data() {
    return {
      wait: false,
      centerE: [],
      centerC: { lat: 4.668131, lng: -74.092026 },
      centerS: { lat: 4.668131, lng: -74.092026 },
      centerL: { lat: 4.668131, lng: -74.092026 },
      ruta: {
        precio: 0,
        id: null,
        ciudadLlegada: "",
        ciudadSalida: "",
        nombre: null,
        puntos: {
          1: {
            nombre: null,
            lat: null,
            lon: null,
          },
          2: {
            nombre: null,
            lat: null,
            lon: null,
          },
        },
        horas: 0,
        minutos: 0,
        segundos: 0,
        observaciones: null,
        cantidadPuntos: 0,
        puntosControl: {},
      },
      rules: {
        required: (value) => !!value || "Campo requerido.",
      },
      markersS: [],
      markersL: [],
      puntosControl: [],
      name: "Agregar ruta",
      ciudades: [],
    };
  },
  async mounted() {
    if (this.infoRuta != null) {
      this.name = "Editar ruta";
      this.ruta = this.infoRuta;
      this.ruta.cantidadPuntos = this.ruta.puntosControl.length;
      await this.calcularPC(this.ruta.puntosControl);
      var tiempos = this.ruta.duracion.split(":");
      this.ruta.horas = parseInt(tiempos[0].replace("h", ""));
      this.ruta.minutos = parseInt(tiempos[1].replace("m", ""));
      this.cargarPC(this.ruta.puntosControl, this.ruta.puntos);
    }
    this.getCiudades();
  },
  methods: {
    getCiudades() {
      getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        this.axios.post(host + "/leeCiudades", fd).then((result) => {
          console.log(result.data.lista);
          this.ciudades = result.data.lista;
        });
      });
    },
    cargarPC(a, p) {
      this.markersS = [];
      this.markersS.push({
        lat: p[1].lat,
        lng: p[1].lon,
      });
      this.centerS = { lat: p[1].lat, lng: p[1].lon };
      this.markersL = [];
      this.markersL.push({
        lat: p[2].lat,
        lng: p[2].lon,
      });
      this.centerL = { lat: p[2].lat, lng: p[2].lon };
      for (let r = 0; r < a.length; r++) {
        const element = a[r];
        this.puntosControl[r].push({
          lat: element.lat,
          lng: element.lon,
        });
      }
    },
    markEventS(evento) {
      this.markersS = [];
      this.markersS.push({
        lat: evento.latLng.lat(),
        lng: evento.latLng.lng(),
      });
      this.ruta.puntos[1]["lat"] = evento.latLng.lat();
      this.ruta.puntos[1]["lon"] = evento.latLng.lng();
    },
    markEventL(evento) {
      this.markersL = [];
      this.markersL.push({
        lat: evento.latLng.lat(),
        lng: evento.latLng.lng(),
      });
      this.ruta.puntos[2]["lat"] = evento.latLng.lat();
      this.ruta.puntos[2]["lon"] = evento.latLng.lng();
    },
    markEventPC(i, evento) {
      // console.log("evento");
      // console.log(evento);
      this.puntosControl[i] = [];
      this.puntosControl[i].push({
        lat: evento.latLng.lat(),
        lng: evento.latLng.lng(),
      });
      this.ruta.puntosControl[i]["lat"] = evento.latLng.lat();
      this.ruta.puntosControl[i]["lon"] = evento.latLng.lng();
    },
    calcularPC(a) {
      if (a != "edit") {
        var data = [];
        for (let s = 0; s < this.ruta.puntosControl.length; s++) {
          data[s] = {
            lat: a[s][0].lat,
            lon: a[s][0].lon,
            minutes: a[s][0].minutes,
            seconds: a[s][0].seconds,
          };
          this.puntosControl[s] = [];
          this.centerE.push({ lat: a[s][0].lat, lng: a[s][0].lon });
          console.log(this.centerE);
        }
        this.ruta.puntosControl = data;
      } else {
        var info = [];
        for (let j = 0; j < this.ruta.cantidadPuntos; j++) {
          info[j] = {
            lat: null,
            lon: null,
            minutes: null,
            seconds: null,
          };
          this.puntosControl[j] = [];
        }
        this.ruta.puntosControl = info;
      }
    },
    comprobarRuta() {
      var puntos = [];
      if (this.ruta.cantidadPuntos != 0) {
        puntos = this.ruta.puntosControl;
        for (let a = 0; a < puntos.length; a++) {
          const element = puntos[a];
          var lista = Object.values(element);
          for (let l = 0; l < lista.length; l++) {
            console.log(lista[l]);
            if (lista[l] == null)
              this.$alert(
                "Debes llenar toda la información de los puntos de control"
              );
          }
        }
      }
      this.saveRuta();
    },
    saveRuta() {
      if (this.ruta.nombre == null) {
        this.$alert(
          "Debes agregar todos los datos obligatorios, están marcados por un asterisco (*)"
        );
      } else {
        this.wait = true;
        // console.log(this.ruta.puntosControl);
        getToken().then((token) => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("nombre", this.ruta.nombre);
          fd.append("observaciones", this.ruta.observaciones);
          fd.append("ciudadSalida", this.ruta.ciudadSalida);
          fd.append("inicio", JSON.stringify(this.ruta.puntos[1]));
          fd.append("ciudadLlegada", this.ruta.ciudadLlegada);
          fd.append("final", JSON.stringify(this.ruta.puntos[2]));
          fd.append("control", JSON.stringify(this.ruta.puntosControl));
          fd.append("horas", this.ruta.horas);
          fd.append("minutos", this.ruta.minutos);
          fd.append("segundos", this.ruta.segundos);
          fd.append("precio", this.ruta.precio);
          this.axios.post(host + "/nuevaRuta", fd).then((result) => {
            this.wait = false;
            if (result.data.data == "OK") this.$emit("toggle", "refresh");
            else {
              this.wait = false;
              this.$alert(result.data.data);
            }
          });
        });
      }
    },
    edit(eliminar) {
      if (this.ruta.nombre == "") {
        this.$alert(
          "Debes agregar todos los datos obligatorios, están marcados por un asterisco (*)"
        );
      } else {
        this.wait = true;
        getToken().then((token) => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("rutaId", this.ruta.id);
          fd.append("eliminar", eliminar);
          fd.append("observaciones", this.ruta.observaciones);
          fd.append("nombre", this.ruta.nombre);
          fd.append("horas", this.ruta.horas);
          fd.append("minutos", this.ruta.minutos);
          fd.append("segundos", this.ruta.segundos);
          fd.append("control", JSON.stringify(this.ruta.puntosControl));
          fd.append("inicio", JSON.stringify(this.ruta.puntos[1]));
          fd.append("ciudadSalida", this.ruta.ciudadSalida);
          fd.append("final", JSON.stringify(this.ruta.puntos[2]));
          fd.append("ciudadLlegada", this.ruta.ciudadLlegada);
          fd.append("precio", this.ruta.precio);
          this.axios.post(host + "/editaRuta", fd).then((result) => {
            this.wait = false;

            if (result.data.data == "OK") this.$emit("toggle", "refresh");
            else {
              this.wait = false;
              this.$alert(result.data.data);
            }
          });
        });
      }
    },
  },
};
</script>
