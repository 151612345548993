import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        permisosGlobal: [],
        permisos: [],
        infoProfile: [],
        rol: '',
        conductores: null,
        buses: null,
        ciudades: null, 
        rutas: null,
        rodamientosBase: null,
        rodamientos: null,
        zonas: [
            // {
            //     nameModule: 'modulo en front (si sirve de algo más adelante)',
            //     to:'/ el ir a ->',
            //     name: 'Nombre que aparece en la card',
            //     show: true - false (si se muestra o no),
            //     img: "link img",
            //     permisos: [
            //         {
            //             nombre: 'Para imprimir en el tab del modulo y nombre que se le dío a la zona en back ', 
            //             lectura: bool,
            //             escritura: bool,
            //             aprobacion: bool,
            //             aprobador:bool,
            //         }, 
            //     ]
            // {
            //     nameModule: 'moduloPerfil',
            //     to:'/moduloPerfil',
            //     name: 'Mi perfil',
            //     show: true,
            //     img: "https://media.istockphoto.com/vectors/user-profile-icon-vector-avatar-portrait-symbol-flat-shape-person-vector-id1270368615?k=20&m=1270368615&s=170667a&w=0&h=qpvA8Z6L164ZcKfIyOl-E8fKnfmRZ09Tks7WEoiLawA=",
            //     permisos: [
            //         {
            //             nombre: 'Perfil',
            //             lectura: true,
            //             escritura: true, 
            //             aprobacion: true, 
            //             aprobador: true,
            //         }, 
            //     ]
            // },
            {
                nameModule: 'moduloBaseDatos',
                to:'/moduloBaseDatos',
                name: 'Bases de datos',
                show: true,
                img: require('./assets/modulos/moduloBaseDatos.png'),
                permisos: [
                    // AQUÍ
                    {
                        nombre: 'Conductores',
                    },
                    {
                        nombre:'Buses',
                    },
                    {
                        nombre:'Ciudades',
                    },
                    {
                        nombre:'Rutas',
                    },
                    {
                        nombre:'Rodamientos Base',
                    },
                ]
            },
            {
                nameModule: 'moduloRodamientos',
                to:'/moduloRodamientos',
                name: 'Rodamientos',
                show: true,
                img: require('./assets/modulos/moduloRodamientos.png'),
                permisos: [
                    {
                        nombre: 'Rodamientos',
                    },
                    {
                        nombre: 'Seguimiento',
                    },
                    {
                        nombre: 'Despacho',
                    },
                ]
            },
            {
                nameModule: 'moduloDespachos',
                to:'/moduloDespachos',
                name: 'Despachos',
                show: true,
                img: require('./assets/modulos/moduloDespacho.png'),
                permisos: [
                    {
                        nombre: 'Despachos',
                    },
                ]
            },
             {
                nameModule: 'moduloMonitoreo',
                to:'/moduloMonitoreo',
                name: 'Monitoreo',
                show: true,
                img: require("./assets/modulos/moduloMonitoreo.png"),
                permisos: [
                    {
                        nombre: 'Monitoreo',
                    }, 
                ]
            },
            {
                nameModule: 'moduloPagos',
                to:'/moduloPagos',
                name: 'Pagos',
                show: true,
                img: require("./assets/modulos/moduloPagos.png"),
                permisos: [
                    {
                        nombre: 'Pagos',
                    }, 
                ]
            },
            {
                nameModule: 'moduloConfiguracion',
                to:'/moduloConfiguracion',
                name: 'Configuración',
                show: true,
                img: require("./assets/modulos/moduloConfiguracion.png"),
                permisos: [
                    {
                        nombre: 'Usuarios',
                    },
                    
                     {
                        nombre:'Permisos',
                    },
                     {
                        nombre:'Alertas',
                    },
                ]
            },
        ]
    },
    mutations: {
        addPermisos(state, data) {
            var nameModul = data['name']
            var config = state.zonas.find(data => data.nameModule === nameModul)
            var idx = state.zonas.indexOf(config)
            if (data['data'].length != 0) {
                state.zonas[idx]['permisos'] = []
                state.zonas[idx]['permisos'] = data['data']
            }
        },
        addShowModules(state, data) {
            var nameModul = data
            var show = state.zonas.find(data => data.nameModule === nameModul)
            var idx = state.zonas.indexOf(show)
            // console.log(state.zonas[idx])
            state.zonas[idx]['show'] = false
        }
    },
    actions: {
        addP(context, data) {
            context.commit("addPermisos", data);
        },
        addShow(context, data) {
            context.commit("addShowModules", data);
        }
    },
    getters: {
        // Permisos de todos
        getPermisosG(state) {
            return state.permisosGlobal;
        },
        // permisos del rol
         getPermisos(state) {
            return state.permisos;
        },
        //  info del usuario
        getInfoProfile(state) {
            return state.infoProfile;
        },
        // rol en string
        getRol(state) {
            // console.log(state.rol)
            return state.rol;
        },
        // Zonas -> módulos
        getZonas(state) {
            return state.zonas;
        },
    }
})
