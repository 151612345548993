<template>
  <v-card color="accent">
    <v-card-title>
      <v-icon large color="primary" @click="$emit('toggle', 'close')"
        >mdi-arrow-left-bold</v-icon
      >
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-col>
        <h5 class="medium secondaryT fit">Editar linea:</h5>
        <h5 class="medium secondaryT thin">
          {{ "Conductor " + info.conductor.nombre }}
        </h5>
        <h5 class="medium secondaryT thin">{{ "Bus " + info.bus.codigo }}</h5>
        <h5 class="medium secondaryT thin">
          {{ "Rodamiento no. " + info.rodamientoBase.numero }}
        </h5>
        <h5 class="medium errorT thin">
          fecha : {{ info.horaSalida | moment("YYYY-MM-DD") }}
        </h5>
      </v-col>
    </v-card-text>
    <v-card-subtitle>
      <v-container>
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <vue-timepicker
              drop-direction="bottom"
              input-width="100%"
              :drop-offset-height="400"
              container-id="auto-dropdown-containter"
              hide-clear-button
              :close-on-complete="true"
              color="primary"
              input-class="picker_style"
              format="HH:mm"
              v-model="edicion.horaDespacho"
              placeholder="Hora despacho*"
            ></vue-timepicker>
          </v-col>

          <v-col cols="12" md="12" sm="12" xs="12">
            <v-text-field label="Observaciones" v-model="edicion.observaciones">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-subtitle>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-icon x-large color="warning" @click="$emit('toggle', 'close')"
        >mdi-close-circle</v-icon
      >
      <v-icon
        v-if="edicion.horaDespacho"
        x-large
        color="success"
        @click="EditLinea"
        >mdi-check
      </v-icon>
    </v-card-actions>
  </v-card>
</template>

<script>
import getToken from "../Tools/token";
import { host } from "../Tools/variables";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
// import data from "../Tools/getData";

export default {
  name: "EditDespacho",
  props: {
    fecha: String,
    info: Object,
  },
  components: {
    VueTimepicker,
  },

  data() {
    return {
      time: "Solo la linea",
      buses: [],
      conductores: [],
      disabledCon: true,
      disabledBus: true,
      disableEdit: false,
      edicion: {
        horaDespacho: null,
        conductor: null,
        bus: null,
        observaciones: null,
      },
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      this.edicion.horaDespacho =
        this.info["horaDespacho"] == null
          ? this.$moment(new Date()).format("HH:mm:ss")
          : this.$moment(this.info["horaDespacho"]).format("HH:mm:ss");
    },

    EditLinea() {
      if (this.edicion.horaDespacho) {
        var fechaS = this.$moment(
          this.fecha + "T" + this.edicion.horaDespacho
        ).add(-5, "hours");
        var dat = new Date(fechaS).toISOString();
        // console.log(dat)
        getToken().then((token) => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("rodamientoId", this.info.id);
          fd.append("busId", this.edicion.bus);
          fd.append("conductorId", this.edicion.conductor);
          fd.append("eliminar", "NO");
          fd.append("observaciones", this.edicion.observaciones);
          fd.append("marcarSalida", dat);
          fd.append("tipoEdicion", "Individual");
          this.axios
            .post(host + "/editarRodamiento", fd)
            .then((result) => {
              var data = result.data.data;
              console.log(data);
              if (data == "OK") {
                this.download(result.data.tirilla);
                this.$emit("toggle", "refresh");
                this.$alert("Se realizó correctamente este ajuste de despacho");
              } else {
                this.$alert(data);
              }
            })
            .catch((error) => {
              this.disabledCon = false;
              this.$alert(error);
            });
        });
      } else {
        this.$alert("Debes marcar una hora de despacho");
      }
    },

    download(content) {
      const url = Uint8Array.from(Buffer.from(content, "base64"));
      var blob = new Blob([url], { type: "application/pdf" });
      const link = URL.createObjectURL(blob);
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = link;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    },
  },
};
</script>

