<template>
  <v-container>
      <v-select label="Seleciona el mes de consulta" @change="listaBusesMes" v-model="mes" :items="listamMeses"></v-select>
        <v-container v-if="!cargando">
          <v-list  dense color="#c4c4c4" v-for="i in buses" v-bind:key="buses[i]">
              <v-list-item-title>
                No. interno bus: {{i.identificacion}}
                <v-icon :color="i.agendado == true ? 'green' : 'red'">mdi-alert-circle</v-icon>
              </v-list-item-title>
          </v-list>
        </v-container>
        <v-container v-else>
          <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
        </v-container>
    </v-container>
</template>

<script>
import data from "../Tools/getData";


export default {
  name: 'ByBus',
  data() {
    return {
      mes: [],
      listamMeses: [],
      buses: [],
      cargando: false,
    }
  },
  created() {
     var dt = new Date();
        var year = dt.getFullYear();
        for (let m = 0; m < 12; m++) {
        var info =
            this.$moment(new Date(year, m, "1")).format("MMMM") + "-" + year;
            var d = {
            text: this.capitalizeFirstLetter(info),
            key: this.$moment(new Date(year, m, "1")).format("YYYY-MM-DD") 
            }
        this.listamMeses.push(d);
        }
  },
  methods: {
    async listaBusesMes() {
      this.cargando = true
      await data.getDataEsp('/listarBuses', 'mes', this.mes).then(info => {
        if (info != 'Error') {
          this.buses = info
          // this.edicion.bus = this.info.bus.id
          this.cargando = false;
        } else {
          this.$alert('Error')
        }
      })
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },

}
</script>

<style>

</style>