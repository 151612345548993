<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
td {
  font-size: 11px !important;
  font-weight: 500;
}

.xlarge {
  font-size: 40px;
}

.large {
  font-size: 30px;
}

.medium {
  font-size: 25px;
}

.middle {
  font-size: 18px;
}

.normal {
  font-size: 15px;
}

.small {
  font-size: 10px;
}

.thin {
  font-weight: 400;
}
.fit {
  font-weight: 600;
}
.fat {
  font-weight: 800;
}

.greyT {
  color: #78909c;
}

.whiteT {
  color: #ffffff;
}

.primaryT {
  color: #2c3e50;
}

.secondaryT {
  color: #021727;
}

.errorT {
  color: #a55518;
}

.warningT {
  color: #e7bb41;
}

.noPadding {
  margin: 0 !important;
  padding: 0 !important;
}

.center {
  text-align: center !important;
}

.loadingCenter {
  text-align: center;
}

.container_created {
  background-color: #c4c4c4 !important;
}
</style>
