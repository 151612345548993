<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" sm="8" xs="12">
        <!-- <v-autocomplete chips small-chips dense clearable v-model="searchC" :items="conductores"
          item-text="usuarioId__nombres" item-value="id" label="Buscar"></v-autocomplete> -->
      </v-col>
      <v-col cols="12" md="2" sm="2" xs="12">
        <v-btn @click="carga = true" color="warning" v-if="permisos.escritura">Carga masiva</v-btn>
      </v-col>
      <v-col cols="12" md="2" sm="2" xs="12">
        <v-btn @click="conductorInfo(null)" v-if="permisos.escritura" color="success">Agregar</v-btn>
      </v-col>
    </v-row>
    <v-card elevation="0" color="accent">
      <v-card-subtitle v-if="conductores.length != 0">
        <v-row>
          <v-col style="text-aling:center" v-for="i, index in conductores" v-bind:key="conductores[i]">
            <v-col @click="conductorInfo(i, index)">
              <v-img max-height="100" max-width="100"
                :src="i.foto.length == 0 ? 'https://cdn-icons-png.flaticon.com/512/90/90471.png' : i.foto[0]"></v-img>
            </v-col>
            <br />
            <v-container>
              <v-row>
                <h3>{{i.usuarioId__nombres}}</h3>
              </v-row>
              <v-row>
                <h3>{{i.documento}}</h3>
              </v-row>
              <v-row>
                <h3 class="mr-3">Estado licencia:</h3>
                <!-- Alerta de vencimiento de licencia  -->
                <v-icon xs :color="i.estadoLic == 'rojo' ? '#f00': i.estadoLic == 'amarillo' ? '#F7F826 ' : '#05BA1D'">
                  mdi-checkbox-blank-circle</v-icon>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-subtitle v-else>
        <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
      </v-card-subtitle>
    </v-card>
    <v-dialog persistent v-if="openAdd" v-model="openAdd">
      <addConductor :permisos="permisos" :infoConductor="informacionConductor" v-on:toggle="eventosConductor($event)">
      </addConductor>
    </v-dialog>
    <v-dialog max-width="30%" v-if="carga" v-model="carga">
      <cargas-masivas :link="link" url="/cargaConductores" v-on:toggle="eventosCarga"></cargas-masivas>
    </v-dialog>
  </v-container>
</template>
<script>
import addConductor from "./addConductor";
import CargasMasivas from "../ComponentesGenerales/CargasMasivas";

import data from '../Tools/getData'

import permisosMixims from "../../mixims/permisos";

export default {
  mixins: [permisosMixims],

  components: {
    addConductor,
    CargasMasivas
  },

  data() {
    return {
      conductores: [],
      openAdd: false,
      informacionConductor: null,
      searchC: null,
      carga: false,
      link: "/PlantillaConductores.xlsx",
      savePic: '',
      indexBus: null,
    };
  },

 async created() {
  await this.cargarCond();
  },

  methods: {
    async cargarCond(){
      this.conductores = []
      this.$store.state.conductores = null;
      var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloBaseDatos")
          var permisosZona = findZona['permisos']
          var findPermisos = permisosZona.find(pgna => pgna.nombre === "Conductores")
          this.permisos = findPermisos
          if (this.$store.state.conductores == null)
            this.conductores = await data.getData('/listaConductores', 'conductores') 
          else {
            this.conductores = this.$store.state.conductores
          }
    },
    
    eventosConductor(a) {
      this.openAdd = false;
      if (a == "refresh") this.cargarCond();
    },
    
    conductorInfo(a, index) {
      this.openAdd = true;
      if (a == null) {
        this.informacionConductor = null;
        this.savePic = ''
        this.indexCon = null
      } else {
        this.informacionConductor = a;
        this.savePic = a.foto
        this.indexCon = index
      }
    },
    searchBy() {
      this.conductores.sort();
    },
    eventosCarga() {
      this.carga = false;
      this.cargarCond();
    }
  }
};
</script>
