<template>
  <v-card color="accent">
    <v-card-title>
      <v-icon large color="primary" @click="$emit('toggle', 'close')">mdi-arrow-left-bold</v-icon>
      <v-spacer></v-spacer>
      <v-icon color="primary" v-if="permisos.escritura" @click="deleteC('SI')">mdi-delete</v-icon>
    </v-card-title>
    <v-card-subtitle>
      <v-container>
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-text-field class="contenedor__login-textField" outlined dense v-model="ciudad"
              label="Nombre de la zona *"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-subtitle>
    <v-card-actions v-if="info == null && permisos.escritura">
      <v-spacer></v-spacer>
      <v-icon @click="$emit('toggle', 'close')" x-large color="warning">mdi-close-circle</v-icon>
      <v-icon @click="save" :disabled="wait" x-large color="success">mdi-check</v-icon>
    </v-card-actions>
  </v-card>
</template>

<script>
import getToken from "../Tools/token";
import {host} from "../Tools/variables";

export default {
  components: {  },
  name: "addCiudad",
  props: ["info", "permisos"],
  data() {
    return {
      ciudad: null,
      wait: false,
    };
  },
  mounted() {
    console.log(this.info)
    if(this.info != null){
      this.ciudad = this.info.nombre
    }
  },
  methods: {
    save() {
      this.wait = true;
      getToken().then(token => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("ciudad", this.ciudad);
        this.axios.post(host + "/agregaCiudad", fd).then(result => {
          this.wait = false;
          let data = result.data;
              if (data.data == "OK") this.$emit("toggle", "refresh");
              else{
                this.$alert(data)
              }
        });
      });
    },
    deleteC() {
      this.wait = true;
      getToken().then(token => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("ciudad", this.info.nombre);
        this.axios.post(host + "/borraCiudad", fd).then(result => {
          this.wait = false;
          let data = result.data;
              if (data.data == "OK") this.$emit("toggle", "refresh");
              else{
                this.$alert(data)
              }
        });
      });
    }
  }
};
</script>
