<template>
  <v-card color="accent">
    <v-card-title>
      <v-icon large color="primary" @click="$emit('toggle', 'close')">mdi-arrow-left-bold</v-icon>
      <v-spacer></v-spacer>
      <v-icon color="primary" v-if="permisos.escritura" :disabled="wait" @click="edit('SI')">mdi-delete</v-icon>
    </v-card-title>
    <v-card-subtitle>
      <v-container>
        <v-col>
          <h3 class="medium primaryT fat">{{name}}</h3>
          <br />
          <h3 class="normal primaryT fit">Datos principales:</h3>
        </v-col>
        <v-row>
          <v-col>
            <v-switch v-model="conductor.activo" :label="`Estado:  ${
                conductor.activo == true ? 'Activo' : 'Inactivo'
              }`"></v-switch>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field class="contenedor__login-textField" outlined dense v-model="conductor.usuarioId__nombres"
              :rules="[rules.required]" label="Nombres y apellidos *"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.documento" :rules="[rules.required]"
              label="Cédula de ciudadanía *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4" xs="12">
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense v-model="conductor.expedicionDoc" label="Fecha de expedición *"
                  :rules="[rules.required]" hint="YYYY-MM-DD" persistent-hint v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker locale="ES" v-model="conductor.expedicionDoc" no-title @input="menu1 = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" sm="4" xs="12">
            <cargue-files :tipo="{title: 'Foto documento', id: 'fileInputDoc'}"
              :fotoDocumentos="fotoDocumentoGuardadasF" v-on:data-delete="deleteFiles($event, 'documento')"
              v-on:toggle="eventosFiles($event, 'documento')"></cargue-files>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.telFijo" label="Teléfono fijo "></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.celular" :rules="[rules.required]"
              label="Teléfono celular *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.whatsapp" label="WhatsApp *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.email" label="Correo electrónico *"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.direccion" :rules="[rules.required]"
              label="Dirección y barrio de residencia *"></v-text-field>
          </v-col>

          <v-col cols="12" md="4" sm="4" xs="12">
            <cargue-files :tipo="{title: 'Foto del conductor', id: 'fileInputCod'}"
              :fotoDocumentos="fotoConductorGuardadasF" v-on:data-delete="deleteFiles($event, 'conductor')"
              v-on:toggle="eventosFiles($event, 'conductor')"></cargue-files>
          </v-col>
        </v-row>
        <v-col>
          <h3 class="normal primaryT fit">Información de contacto para emergencias:</h3>
        </v-col>
        <v-row>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.infoContacto" :rules="[rules.required]" label="Nombre *">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.dirContacto" label="Dirección *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.emailContacto" label="Correo *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.telContacto" :rules="[rules.required]"
              label="Teléfono fijo  *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.celContacto" label="Teléfono celular  *"></v-text-field>
          </v-col>
        </v-row>
        <v-col>
          <h3 class="normal primaryT fit">Datos de la licencia de conducción:</h3>
        </v-col>
        <v-row>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.licenciaId__documento" :rules="[rules.required]"
              label="Número  *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense v-model="conductor.licenciaId__expedicion" label="Fecha de expedición *"
                  :rules="[rules.required]" hint="YYYY-MM-DD" persistent-hint v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker locale="ES" v-model="conductor.licenciaId__expedicion" no-title @input="menu2 = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense v-model="conductor.licenciaId__vencimiento" label="Fecha de vencimiento *"
                  hint="YYYY-MM-DD" :rules="[rules.required]" persistent-hint v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker locale="ES" v-model="conductor.licenciaId__vencimiento" no-title @input="menu3 = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.licenciaId__restricciones" :rules="[rules.required]"
              label="Restricciones del conductor  *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.sangre" :rules="[rules.required]" label="Sangre (RH)  *">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6" xs="12">
            <v-text-field outlined dense v-model="conductor.licenciaId__tipo" :rules="[rules.required]"
              label="Categoría *"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4" xs="12">
            <v-text-field outlined dense v-model="conductor.observaciones" label="Observaciones"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <cargue-files :tipo="{title: 'Foto de licencia', id: 'fileInputLic'}"
              :fotoDocumentos="fotoLicenciaGuardadasF" v-on:data-delete="deleteFiles($event, 'licencia')"
              v-on:toggle="eventosFiles($event, 'licencia')"></cargue-files>
          </v-col>
        </v-row>
      </v-container>
      <!-- <h3>{{fotoConductorSend}}</h3>
      <h3>{{fotoDocumentoSend}}</h3>
      <h3>{{fotoLicenciaSend}}</h3>-->
    </v-card-subtitle>
    <v-card-actions v-if="permisos.escritura">
      <v-spacer></v-spacer>
      <v-icon x-large color="warning" @click="$emit('toggle', 'close')">mdi-close-circle</v-icon>
      <v-icon x-large :disabled="wait" color="success" @click="conductor.id == null ? saveConductor() :edit('')">
        mdi-check</v-icon>
    </v-card-actions>
  </v-card>
</template>

<script>
import {host} from "../Tools/variables";
import getToken from "../Tools/token";
import cargueFiles from "../ComponentesGenerales/CargueFiles.vue";

export default {
  name: "addConductor",
  props: ["infoConductor", "permisos"],
  components:{
    cargueFiles
  },
  data() {
    return {
      name: 'Agregar conductor',
      wait: false,
      menu1: null, // exp documento
      menu2: null, // exp licencia
      menu3: null, // venc licencia
      conductor: {
        id: null,
        activo: false,
        // foto de docuemnto
        cedulaFoto: [],
        celContacto: null,
        celular: null,
        dirContacto: null,
        direccion: null,
        documento: null,
        email: null,
        emailContacto: null,
        expedicionDoc: null,
        // foto del conductor
        foto: [],
        infoContacto: null,
        // foto de licencia
        fotoLicencia: [],
        licenciaId__documento: null,
        licenciaId__expedicion: null,
        licenciaId__licenciaFoto: [],
        licenciaId__restricciones: null,
        licenciaId__tipo: null,
        licenciaId__vencimiento: null,
        observaciones: null,
        sangre: null,
        telContacto: null,
        telFijo: null,
        usuarioId__nombres: null,
        whatsapp: null
      },
      fotoDocumentoSend: [],
      fotoLicenciaSend: [],
      fotoConductorSend: [],
      fotoLicenciaGuardadasF: [],
      fotoDocumentoGuardadasF: [],
      fotoConductorGuardadasF: [],
      // las fotos a borrar
      bLicencia: [],
      bDocumento: [],
      bConductor: [],
      rules: {
        required: value => !!value || "Campo requerido."
      },
    };
  },
  created() {
    console.log(this.infoConductor);
    if (this.infoConductor != null) {
      this.name= 'Editar conductor'
      this.conductor = this.infoConductor;
      this.conductor.expedicionDoc = this.$moment(
        this.infoConductor.expedicionDoc
      ).format("YYYY-MM-DD");
      this.conductor.licenciaId__expedicion = this.$moment(
        this.infoConductor.licenciaId__expedicion
      ).format("YYYY-MM-DD");
      this.conductor.licenciaId__vencimiento = this.$moment(
        this.infoConductor.licenciaId__vencimiento
      ).format("YYYY-MM-DD");
      this.fotoLicenciaGuardadasF = this.infoConductor.fotoLicencia;
      this.fotoDocumentoGuardadasF = this.infoConductor.cedulaFoto;
      this.fotoConductorGuardadasF = this.infoConductor.foto;
      this.infoConductor.fotoLicencia = [];
      this.infoConductor.cedulaFoto = [];
      this.infoConductor.foto = [];
    }
  },
  methods: {
    eventosFiles(e, tipo){
       switch (tipo) {
        case "licencia": {
        this.conductor.fotoLicencia = this.fotoLicenciaSend = []
        this.fotoLicenciaSend = e;
          var l = this.fotoLicenciaSend;
          for (let fl = 0; fl < l.length; fl++) {
              this.conductor.fotoLicencia.push(l[fl].name);
          }
          break;
        }
        case "documento": {
        this.conductor.cedulaFoto = this.fotoDocumentoSend = []
        this.fotoDocumentoSend = e;
          var d = this.fotoDocumentoSend;
          for (let fd = 0; fd < d.length; fd++) {
              this.conductor.cedulaFoto.push(d[fd].name);
          }
          break;
        }
        case "conductor": {
          this.conductor.foto = this.fotoConductorSend = []
          this.fotoConductorSend = e;
          var c = this.fotoConductorSend;
          for (let fc = 0; fc < c.length; fc++) {
              this.conductor.foto.push(c[fc].name);
          }
          break;
        }
        default:
          break;
      }
    },
     deleteFiles(i, tipo) {
       switch (tipo) {
        case "licencia": {
          this.bLicencia.push(i);
          break;
        }
        case "documento": {
          this.bDocumento.push(i);
          break;
        }
        case "conductor": {
          this.bConductor.push(i);
          break;
        }
        default:
          break;
      }
    },
    saveConductor() {
      if (
        this.conductor.usuarioId__nombres == null ||
        this.conductor.documento == null ||
        this.conductor.expedicionDoc == null ||
        this.conductor.celular == null ||
        this.conductor.whatsapp == null ||
        this.conductor.direccion == null ||
        this.conductor.infoContacto == null ||
        this.conductor.dirContacto == null ||
        this.conductor.emailContacto == null ||
        this.conductor.telContacto == null ||
        this.conductor.celContacto == null ||
        this.conductor.licenciaId__documento == null ||
        this.conductor.licenciaId__expedicion == null ||
        this.conductor.licenciaId__vencimiento == null ||
        this.conductor.licenciaId__restricciones == null ||
        this.conductor.sangre == null ||
        this.conductor.licenciaId__tipo == null
      ) {
        this.$alert(
          "Debes agregar todos los datos obligatorios, están marcados por un asterisco (*)"
        );
      } else {
        this.wait = true;
        getToken().then(idToken => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("estado", this.conductor.activo == false ? 0 : 1);
          fd.append("nombresApellidos", this.conductor.usuarioId__nombres);
          fd.append("cedula", this.conductor.documento);
          fd.append("expedicion", this.conductor.expedicionDoc);
          for (var i = 0; i < this.fotoDocumentoSend.length; i++) {
            let file = this.fotoDocumentoSend[i];
            fd.append("fotoDocumento", file);
          }
          fd.append(
            "telFijo",
            this.conductor.telFijo == null ? "" : this.conductor.telFijo
          );
          fd.append("telCelular", this.conductor.celular);
          fd.append("direccion", this.conductor.direccion);
          fd.append(
            "email",
            this.conductor.email == null ? "" : this.conductor.email
          );
          for (var c = 0; c < this.fotoConductorSend.length; c++) {
            let fileC = this.fotoConductorSend[c];
            fd.append("fotoConductor", fileC);
          }
          fd.append("nombreEmg", this.conductor.infoContacto);
          fd.append(
            "dirEmg",
            this.conductor.dirContacto == null ? "" : this.conductor.dirContacto
          );
          fd.append(
            "emailEmg",
            this.conductor.emailContacto == null
              ? ""
              : this.conductor.emailContacto
          );
          fd.append(
            "telEmg",
            this.conductor.telContacto == null ? "" : this.conductor.telContacto
          );
          fd.append("celEmg", this.conductor.celContacto);
          fd.append("numLic", this.conductor.licenciaId__documento);
          fd.append("vencLic", this.conductor.licenciaId__vencimiento);
          fd.append("expLic", this.conductor.licenciaId__expedicion);
          fd.append("restriccLic", this.conductor.licenciaId__restricciones);
          fd.append("sangre", this.conductor.sangre);
          fd.append("categLic", this.conductor.licenciaId__tipo);
          fd.append(
            "observaciones",
            this.conductor.observaciones == null
              ? ""
              : this.conductor.observaciones
          );
          for (var l = 0; l < this.fotoLicenciaSend.length; l++) {
            let fileL = this.fotoLicenciaSend[l];
            fd.append("fotoLicencia", fileL);
          }
          fd.append(
            "whatsapp",
            this.conductor.whatsapp == null ? "" : this.conductor.whatsapp
          );
          let headers = {
            "Content-Type": "multipart/form-data"
          };
          this.axios
            .post(host + "/nuevoConductor", fd, headers)
            .then(result => {
              this.wait = false;
              let data = result.data;
              if (data.data == "OK") this.$emit("toggle", "refresh");
              else if (
                data ==
                "The user with the provided phone number already exists (PHONE_NUMBER_EXISTS)."
              )
                this.$alert(
                  "Error: No. celular ya se encuentra registrado para otro conductor"
                );
              else {
                this.$alert(result.data);
              }
            })
            .catch(error => {
              this.wait = false;
              this.$alert(error);
            });
        });
      }
    },
    edit(eliminar) {
      if (
        this.conductor.usuarioId__nombres == "" ||
        this.conductor.documento == "" ||
        this.conductor.expedicionDoc == "" ||
        this.conductor.celular == "" ||
        this.conductor.whatsapp == "" ||
        this.conductor.direccion == "" ||
        this.conductor.email == "" ||
        this.conductor.infoContacto == "" ||
        this.conductor.dirContacto == "" ||
        this.conductor.emailContacto == "" ||
        this.conductor.telContacto == "" ||
        this.conductor.celContacto == "" ||
        this.conductor.licenciaId__documento == "" ||
        this.conductor.licenciaId__expedicion == "" ||
        this.conductor.licenciaId__vencimiento == "" ||
        this.conductor.licenciaId__restricciones == "" ||
        this.conductor.sangre == "" ||
        this.conductor.licenciaId__tipo == ""
      ) {
        this.$alert(
          "Debes agregar todos los datos obligatorios, están marcados por un asterisco (*)"
        );
      } else {
        this.wait = true;
        getToken().then(token => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("eliminar", eliminar);
          fd.append("conductorId", this.conductor.id);
          fd.append("nombresApellidos", this.conductor.usuarioId__nombres);
          fd.append("cedula", this.conductor.documento);
          fd.append("expedicion", this.conductor.expedicionDoc);
          if (this.fotoDocumentoSend.length != 0)
            for (var i = 0; i < this.fotoDocumentoSend.length; i++) {
              let file = this.fotoDocumentoSend[i];
              fd.append("fotoDocumento", file);
            }
          // borrar los archivos del doc
          fd.append("fotoDocumento_borra", this.bDocumento);
          fd.append(
            "telFijo",
            this.conductor.telFijo == null ? "" : this.conductor.telFijo
          );
          fd.append("telCelular", this.conductor.celular);
          fd.append("direccion", this.conductor.direccion);
          fd.append(
            "email",
            this.conductor.email == null ? "" : this.conductor.email
          );
          if (this.fotoConductorSend.length != 0)
            for (var c = 0; c < this.fotoConductorSend.length; c++) {
              let fileC = this.fotoConductorSend[c];
              fd.append("fotoConductor", fileC);
            }
          // borrar los archivos del conductor
          fd.append("fotoConductor_borra", this.bConductor);
          fd.append("nombreEmg", this.conductor.infoContacto);
          fd.append(
            "dirEmg",
            this.conductor.dirContacto == null ? "" : this.conductor.dirContacto
          );
          fd.append(
            "emailEmg",
            this.conductor.emailContacto == null
              ? ""
              : this.conductor.emailContacto
          );
          fd.append(
            "telEmg",
            this.conductor.telContacto == null ? "" : this.conductor.telContacto
          );
          fd.append("celEmg", this.conductor.celContacto);
          fd.append("numLic", this.conductor.licenciaId__documento);
          fd.append("vencLic", this.conductor.licenciaId__vencimiento);
          fd.append("expLic", this.conductor.licenciaId__expedicion);
          fd.append("restriccLic", this.conductor.licenciaId__restricciones);
          fd.append("sangre", this.conductor.sangre);
          fd.append("categLic", this.conductor.licenciaId__tipo);
          fd.append(
            "observaciones",
            this.conductor.observaciones == null
              ? ""
              : this.conductor.observaciones
          );
          if (this.fotoLicenciaSend.length != 0)
            for (var l = 0; l < this.fotoLicenciaSend.length; l++) {
              let fileL = this.fotoLicenciaSend[l];
              fd.append("fotoLicencia", fileL);
            }
          // fotos a borrar de liecencia
          fd.append("fotoLicencia_borra", this.bLicencia);
          fd.append(
            "whatsapp",
            this.conductor.whatsapp == null ? "" : this.conductor.whatsapp
          );
          let headers = {
            "Content-Type": "multipart/form-data"
          };
          this.axios
            .post(host + "/editarConductor", fd, headers)
            .then(result => {
              this.wait = false;
              let data = result.data;
              if (data.data == "OK") this.$emit("toggle", "refresh");
              else {
                this.$alert(result.data.data);
              }
            })
            .catch(error => {
              this.wait = false;
              this.$alert(error);
            });
        });
      }
    }
  }
};
</script>
