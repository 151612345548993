import firebase from "firebase";

async function getToken() {
  return new Promise(resolve => {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(idToken => {
        resolve(idToken);
      })
      .catch(error => {
        this.$alert("Error :" + error.message);
      });
  });
}

export default getToken;
