<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" xs="12">
      <image-uploader
        :debug="1"
        :maxWidth="1600"
        :maxHeight="1600"
        :quality="0.7"
        :autoRotate="true"
        outputFormat="verbose"
        :preview="false"
        :className="['fileinput']"
        :capture="false"
        accept=".gif, .jpg, .png, .svg"
        doNotResize="['gif', 'svg']"
        @input="cargueFiles"
        :id="tipo.id"
      >
        <label :for="tipo.id" slot="upload-label">
          <span class="normal secondaryT fat">{{ tipo.title }}</span>
          <figure>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path
                class="path1"
                d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
              ></path>
            </svg>
          </figure>
          <span class="normal secondaryT fit">{{
            dataReturn.length == 0 ? "Agregar foto" : "Foto cargada"
          }}</span>
          <v-container v-if="dataReturn.length != 0">
            <v-row v-for="(f, j) in dataShow" :key="j">
              <span class="normal secondaryT fit">{{ f }}</span>
              <v-btn icon plain small @click="deleteD(j)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-row>
          </v-container>
        </label>
      </image-uploader>
    </v-col>
    <v-divider></v-divider>
    <b class="textPs" v-if="fotoDocumentos.length != 0">Archivos guardados</b>
    <template v-if="fotoDocumentos.length != 0">
      <v-container
        v-for="(doc, index) in fotoDocumentos"
        :key="fotoDocumentos[doc]"
        :value="index"
      >
        <v-row>
          <h5 class="normal primaryT fit">
            • {{ tipo.title + " " + (index + 1) }}
          </h5>
          <v-spacer></v-spacer>
          <v-btn small plain target="_blank" :href="doc">Ver</v-btn>
          <v-btn small plain @click="deleteImg(doc)">
            Eliminar
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </template>
  </v-row>
</template>

<script>
export default {
  name: "cargue-files",
  props: {
    tipo: {
      // documento : String,
      title: String,
      id: String,
    },
    fotoDocumentos: [],
    // tipoDocumento : String,
  },
  data() {
    return {
      dataReturn: [],
      dataShow: [],
      indexDel: [],
    };
  },
  watch: {
    dataReturn: function (e) {
      this.$emit("toggle", e);
    },
  },
  created() {
    document.getElementById(this.tipo.id).style.display = "none";
  },
  mounted() {
    document.getElementById(this.tipo.id).style.display = "none";
  },
  methods: {
    // convierte de blob a file
    async dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    async cargueFiles(file) {
      var data = await this.dataURLtoFile(file.dataUrl, file.info.name);
      // var data = new File([file.dataUrl], file.info.name,  {
      //     type: file.info.type,
      //     });
      // console.log('____________________')
      // console.log(data)
      this.dataReturn.push(data);
      var d = this.dataReturn;
      this.dataShow = [];
      for (let i = 0; i < d.length; i++) {
        // if(!(d[i].name.includes(this.dataShow))){
        this.dataShow.push(d[i].name);
        // }
      }
    },
    deleteD(i) {
      this.dataReturn.splice(i, 1);
      this.dataShow.splice(i, 1);
    },
    deleteImg(i) {
      let posB = this.fotoDocumentos.indexOf(i);
      this.fotoDocumentos.splice(posB, 1);
      this.$emit("data-delete", posB);
    },
  },
};
</script>

<style scoped>
#fileInputS,
#fileInputT,
#fileInputB,
#fileInputDE,
#fileInputDoc,
#fileInputCon,
#fileInputLic {
  display: none;
}
</style>