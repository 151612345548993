<template>
  <v-app id="app">
    <v-container class="fill">
      <v-container class="container_title_login">
        <h1 class="xlarge greyT">Plataforma para gestión de transporte</h1>
      </v-container>
      <v-container class="mt-10">
        <v-row align="center" justify="center">
          <v-col cols="12" md="8" xs="8" sm="8" lg="8">
            <v-card min-width="50%" min-height="50%" elevation="2" color="#c4c4c4">
              <div>
                <v-card-subtitle v-if="!registro">
                  <v-col class="pt-5">
                    <v-text-field outlined dense v-model="user" :rules="[rules.required]" label="Correo electrónico">
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0">
                    <v-text-field outlined dense :append-icon="showP ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showP ? 'text' : 'password'" v-model="pass" :rules="[rules.required]" label="Contraseña"
                      @click:append="showP = !showP" v-on:keyup.enter="entra"></v-text-field>
                  </v-col>
                  <v-col class="pt-0 center">
                    <a @click="change(true)" color="pimary" class="fit" text>¿No tienes una cuenta?</a>
                  </v-col>
                </v-card-subtitle>
                <v-card-subtitle v-else>
                  <v-container>
                    <v-icon large color="primary" @click="change(false)">mdi-arrow-left-bold</v-icon>
                  </v-container>
                  <v-col>
                    <v-text-field outlined dense v-model="user" :rules="[rules.required]" label="Correo electrónico *">
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field outlined dense :append-icon="showP ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showP ? 'text' : 'password'" v-model="pass" :rules="[rules.required]" label="Contraseña *"
                      @click:append="showP = !showP"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field outlined dense :append-icon="showCP ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showCP ? 'text' : 'password'" v-model="confirmPass" :rules="[rules.required]"
                      label="Confirmar contraseña *" @click:append="showCP = !showCP"></v-text-field>
                  </v-col>
                </v-card-subtitle>
                <v-card-actions v-if="!registro">
                  <v-spacer></v-spacer>
                  <v-btn color="success" @click="entra">Ingresar</v-btn>
                </v-card-actions>
                <v-card-actions v-else>
                  <v-spacer></v-spacer>
                  <v-btn color="success" @click="create">Crear cuenta</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" xs="4" sm="4" lg="4">
            <v-img max-width="400" src="../assets/cotransa.png"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-app>
</template>
<script>
import firebase from "firebase";
import {host} from "./Tools/variables";
import getToken from "./Tools/token";

export default {
  data() {
    return {
      user: null,
      showP: false,
      pass: null,
      showCP: false,
      confirmPass: null,
      registro: false,
      inicio: false,
      rules: {
        required: value => !!value || "Campo requerido."
      }
    };
  },
  watch: {},
  mounted() {
    this.inicio = true;
  },
  methods: {
    change(a) {
      this.registro = a;
    },
    create() {
      if (this.confirmPass != this.pass)
        this.$alert("Error: Las contraseñas no coninciden");
      else {
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.user, this.confirmPass)
          .then(userCredential => {
            console.log(userCredential);
            getToken()
              .then(idToken => {
                const fd = new FormData();
                fd.append("token", idToken);
                this.axios.post(host + "/login", fd).then(result => {
                  let data = result.data.data;
                  if (data == "OK") {
                    this.$router.push("/home");
                  }
                });
              })
              .catch(error => {
                this.$alert(error);
              });
          })
          .catch(error => {
            var mensaje = "";
            if (error == "Error: The email address is badly formatted.") {
              mensaje = "Error: Formato de correo invalido";
            } else if (
              error == "Error: Password should be at least 6 characters"
            ) {
              mensaje = "Error: La contraseña debe contener 6 caracteres";
            } else {
              mensaje = error;
            }
            this.$alert(mensaje);
          });
      }
    },
    entra() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.user, this.pass)
        .then(userCredential => {
          // Signed in
          // ...
          this.$router.push("/home");
          console.log(userCredential);
        })
        .catch(error => {
          var mensaje = "";
          if (error == "Error: The email address is badly formatted.") {
            mensaje = "Error: Formato de correo invalido";
          } else if (
            error ==
            "Error: There is no user record corresponding to this identifier. The user may have been deleted."
          ) {
            mensaje =
              "Error: Usuario no registrado o eliminado de la base de datos";
          } else {
            mensaje = error;
          }
          this.$alert(mensaje);
        });
    }
  }
};
</script>

<style>
#app {
  background-color: #2c3e50;
}
/* creo que este ta en homep */
/* .container_title-login {
  font-weight: 400;
  font-size: 40px;
  color: #fff;
} */

.container_title_login {
  text-align: center;
  width: 70% !important;
}

/* input {
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding-left: 40px;
} */



</style>
