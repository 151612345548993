<template>
  <v-card class="container_created">
    <v-card-title>
      <h4 class="medium secondaryT fat">Cargar los archivos</h4>
    </v-card-title>
    <v-card-text>
      <v-btn plain :href="link" download>
        Descargar el formato de subida
        <v-icon>mdi-cloud-download</v-icon>
      </v-btn>
    </v-card-text>
    <v-card-subtitle class="center">
      <input id="files" ref="file" @change="getFile" type="file" />
    </v-card-subtitle>
    <v-card-actions>
      <v-container class="container_save">
        <v-icon x-large color="#E7BB41" @click="$emit('toggle', 'close')">mdi-close-circle</v-icon>
        <v-icon
          x-large
          v-if="this.files.length != 0"
          :disabled="wait"
          @click="convertFile"
          color="#44BBA4"
        >mdi-upload</v-icon>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import getToken from "../Tools/token";
import {host} from "../Tools/variables";

export default {
  name: 'CargasMasivas',
  props: ["link", "url"],
  data() {
    return {
      base64: null,
      files: [],
      wait: false
    };
  },
  methods: {
    getFile(event) {
      this.files = [];
      this.files = Array.prototype.slice.call(event.target.files);
    },
    convertFile() {
      this.wait = true;
      getToken()
        .then(token => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("excel", this.files[0]);
          this.axios.post(host + this.url, fd).then(result => {
            this.wait = false;
            let data = result.data;
            if (data.data == "OK") {
              this.$emit("toggle");
            } else {
              this.$alert("Error: " + result.data.data);
            }
          });
        })
        .catch(function(error) {
          this.wait = false;

          this.$alert("Error " + error.message);
        });
    }
  }
};
</script>

<style>
</style>