<template>
  <v-card color="accent">
    <v-card-text>
      <v-container>
        <h1 class="large secondaryT pt-6">Mi perfil</h1>
      </v-container>
    </v-card-text>
    <v-card-subtitle v-if="!wait">
      <v-col cols="12" md="12" sm="12" xs="12">
        <v-text-field outlined dense v-model="usuario.nombres" label="Nombres *"></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" xs="12">
        <v-text-field outlined dense v-model="usuario.apellidos" label="Apellidos *"></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" xs="12">
        <v-btn  @click="out()" text color="primary" x-large>Cerrar sesión</v-btn>
      </v-col>
    </v-card-subtitle>
    <v-card-subtitle v-else>
      <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
    </v-card-subtitle>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-icon x-large color="warning" @click="$emit('perfil', true)">mdi-close-circle</v-icon>
      <v-icon x-large color="success" @click="saveProfile">mdi-check</v-icon>
    </v-card-actions>
  </v-card>
</template>

<script>
import {host} from "./Tools/variables";
import getToken from "./Tools/token";
import firebase from "firebase";

export default {
  data() {
    return {
      usuario: {
        nombres: null,
        apellidos: null
      },
      wait: false,
      permisos: []
    };
  },
  created(){
    // var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloPerfil")
    // var permisosZona = findZona['permisos']
    // console.log(permisosZona)
    // var findPermisos = permisosZona.find(pgna => pgna.nombre === "Perfil")
    // this.permisos = findPermisos
  },
  mounted() {
    this.wait = true;
    getToken().then(token => {
      const fd = new FormData();
      fd.append("token", token);
      this.axios
        .post(host + "/infoProfile", fd)
        .then(result => {
          this.wait = false;
          this.usuario = result.data.user;
        })
        .catch(error => {
          this.wait = false;
          this.$alert(error);
        });
    });
  },
  methods: {
    saveProfile() {
      getToken().then(token => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("nombres", this.usuario.nombres);
        fd.append("apellidos", this.usuario.apellidos);
        this.axios.post(host + "/editProfile", fd).then(result => {
          if (result.data.data == "OK"){
              this.$emit('perfil', true);
              this.$alert("Se agregó la información satisfactoriamente");
            }
          else {
            this.$alert(result.data.data);
          }
        });
      });
    },
    out() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("/");
        });
    },
  }
};
</script>
