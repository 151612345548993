<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" sm="8" xs="12">
        <!-- <v-autocomplete chips small-chips dense clearable v-model="searchC" :items="roda" item-text="usuarioId__nombres"
          item-value="id" label="Buscar"></v-autocomplete> -->
      </v-col>
      <v-col cols="12" md="2" sm="2" xs="12">
        <v-btn @click="carga = true" color="warning" v-if="permisos.escritura">Carga masiva</v-btn>
      </v-col>

      <v-col cols="12" md="2" sm="2" xs="12">
        <v-btn @click="rodamientoInfo(null)" v-if="permisos.escritura" color="success">Agregar</v-btn>
      </v-col>
    </v-row>
    <v-card elevation="0" color="accent">
      <v-card-subtitle v-if="roda.length != 0">
        <v-row>
          <v-col @click="rodamientoInfo(i)" v-for="i, index in roda" v-bind:key="roda[i]">
            <v-col>
              <v-img max-height="100" max-width="100"
                :src="'https://icon-library.com/images/items-icon/items-icon-29.jpg'"></v-img>
            </v-col>
            <v-container>
              <v-row>
                <h3 class="middle errorT">
                  Rodamiento no.{{ index }}
                </h3>
              </v-row>
              <v-row>
                <h3>
                  Hora Salida: {{ i[0].horaSalida }}
                </h3>
              </v-row>
              <v-row>
                <h3>
                  Lineas:
                </h3>
                <ol>
                  <li v-for="u in i" :key="u.id">
                    {{ getData(u.rutaId_id) }}
                  </li>
                </ol>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-subtitle v-else>
        <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
      </v-card-subtitle>
      <v-card-subtitle v-if="isDeleting">
      <v-progress-linear
        v-model="value"
        :query="true"
      ></v-progress-linear>
      </v-card-subtitle>
      <v-col cols="12" md="2" sm="2" xs="12">
        <v-btn @click="deleteAll(null)" dark v-if="permisos.escritura" color="red">Eliminar
          todos</v-btn>
      </v-col>
    </v-card>
    <v-dialog persistent max-width="70%" v-if="openAdd" v-model="openAdd">
      <addRodamiento :permisos="permisos" :length="Object.keys(roda).length" :info="informacionRodamiento"
        v-on:toggle="eventosRodamiento($event)"></addRodamiento>
    </v-dialog>
    <v-dialog max-width="30%" v-if="carga" v-model="carga">
      <cargas-masivas :link="link" url="/cargaMasivaRodamientosBase" v-on:toggle="eventosCarga"></cargas-masivas>
    </v-dialog>
  </v-container>
</template>
<script>
import addRodamiento from "./addRodamientoB";
import {host} from "../Tools/variables";
import getToken from "../Tools/token";
import CargasMasivas from "../ComponentesGenerales/CargasMasivas.vue";
import data from "../Tools/getData";

import permisosMixims from "../../mixims/permisos";

export default {
  mixins: [permisosMixims],

  components: {
    addRodamiento,
    CargasMasivas
  },

  data() {
    return {
      openAdd: false,
      informacionRodamiento: null,
      rutas: [],
      roda: null,
      length: 0,
      isDeleting: false,
      carga: false,
      link: "/PlantillaRodamientos.xlsx",
      // el progress linear 
      value: 10,
      val: 100,

    };
  },
  async created() {
    await this.cargarRB();
   
  },


  
  methods: {
    async cargarRB(){
      this.roda = [];
      this.$store.state.rodamientosBase = null;
      var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloBaseDatos")
          var permisosZona = findZona['permisos']
          var findPermisos = permisosZona.find(pgna => pgna.nombre === "Conductores")
          this.permisos = findPermisos
          if (this.$store.state.rodamientosBase == null)
            this.roda = await data.getData('/listarRodamientoBase', 'rodamientosBase')
          else {
            this.roda = this.$store.state.rodamientosBase
          }
          if (this.$store.state.rutas == null)
            this.rutas = await data.getData('/listarRutas', 'rutas')
          else {
            this.rutas = this.$store.state.rutas
          }
    },
    eventosRodamiento(a) {
      this.openAdd = false;
      if (a == "refresh") this.cargarRB();
    },
    rodamientoInfo(a) {
      this.openAdd = true;
      if (a == null) {
        this.informacionRodamiento = null;
      } else {
        this.informacionRodamiento = a;
      }
    },
    getData(a) {
      for (let r = 0; r < this.rutas.length; r++) {
        const element = this.rutas[r];
        if (a == element["id"]) {
          return element["nombre"];
        }
      }
    },
    asignar(s) {
      this.popupAsignar = true;
      this.infoRoda = s;
    },

    async buscarConductor() {
      await data.getDataEsp('/listaConductoresRodamiento', 'dia', this.fecha).then(info => {
        if (info != 'Error') {
          this.conductores = info
        } else {
          this.$alert('Error')
        }
      })
    },
    
    asignarRodamiento() {
      getToken().then(token => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("dia", this.fecha);
        fd.append("rodamientoBase", this.infoRoda[0].numero);
        fd.append("conductorId", this.selectedConductor);
        fd.append("busId", this.selectedBus);
        this.axios.post(host + "/nuevoRodamiento", fd).then(data => {
          console.log(data);
          var rta = data.data;
          if (rta == "OK") {
            this.$alert("Rodamiento asignado");
          }
        });
      });
    },
    eventosCarga() {
      this.carga = false;
      this.cargarRB();
    }, 
    deleteAll() {
      this.isDeleting = true;
      getToken().then(token => {
      this.interval = setInterval(() => {
        this.value += 5
      }, 1000)
        const fd = new FormData();
        fd.append("token", token);
        fd.append("eliminar",'TODOS');
        this.axios.post(host + "/editarRodamientoBase", fd).then(result => {
          this.isDeleting = false;
          this.wait = false;
          let data = result.data;
          if (data.data == "OK") {
            clearInterval(this.interval)
            this.cargarRB()
          } else {
            this.$alert(result.data.data);
          }
        });
      });
    },

    // queryAndIndeterminate () {
    //   this.query = true
    //   this.show = true
    //   this.value = 0
    //   setTimeout(() => {
    //     this.query = false
    //     this.interval = setInterval(() => {
    //       if (this.value === 100) {
    //         clearInterval(this.interval)
    //         this.show = false
    //         return setTimeout(this.queryAndIndeterminate, 2000)
    //       }
    //       this.value += 50
    //     }, 1000)
    //   }, 2500)
    // },
  }
};
</script>

