import getToken from "./token"
import {host} from "./variables";
import axios from 'axios'
import store from "../../store";



async function getPermissions() {
    return new Promise(resolve => {
        getToken().then(token => {
            const fd = new FormData()
            fd.append('token', token)
            axios.post(host + "/listarPermisos", fd).then(rta => {
                if (rta.data.data == 'OK') {
                    store.state.permisosGlobal = rta.data.lista;
                    getInfoProfile().then(rta => {
                        if (rta) {
                            store.state.infoProfile = rta;
                            store.state.rol = rta.tipo;
                            var permisos = store.getters.getPermisosG
                            var send = permisos[store.getters.getRol]
                            visualizacionModulos(send).then(rta => {
                                store.state.permisos = rta;
                                llenarStore(store.getters.getPermisos, store.getters.getZonas);
                                resolve('OK')
                            })
                        }
                    });
                } else {
                    resolve(null)
                }
           })
       })
    });
}

async function getInfoProfile() {
    return new Promise(resolve => {
        getToken().then(token => {
            const fd = new FormData()
            fd.append('token', token)
            axios.post(host + "/infoProfile", fd).then(rta => {
                if (rta.data.data == 'OK') {
                    resolve(rta.data.user)
                } else {
                    resolve(null)
                }
           })
       })
    });
}


async function visualizacionModulos(data) {
    return new Promise(resolve => {
        var info=[];
        for (let t = 0; t < data.length; t++) {
            const element = data[t];
            info.push(element)
        }   
        resolve(info)
    })
    
}

//  permisos de bak - zonas en el store
async function llenarStore(per, zon){
    return new Promise(resolve => {
        var zonasStore = zon
        var perBack = per;
        // var paraVer = [];
        for (let i = 0; i < zonasStore.length; i++) {
            var nuevo = [];
            var eleZ = zonasStore[i]['permisos'];
            for (let j = 0; j < eleZ.length; j++) {
                // eleP es el permiso como tal 
                var eleP = eleZ[j];
                for (let y = 0; y < perBack.length; y++) {
                    var elePB = perBack[y];
                    if (eleP.nombre == elePB.zona) {
                        var kys = Object.keys(elePB)
                        var vls = Object.values(elePB)
                        elePB['nombre'] = eleP.nombre
                        for (let k = 0; k < kys.length; k++) {
                            var elemK = kys[k];
                            elePB[elemK] = vls[k]
                        }    
                        nuevo.push(elePB)
                    }
                }
                
            }
            var veo = [];
            for (let f = 0; f < nuevo.length; f++) {
                const itemsNuevo = nuevo[f];
                if (itemsNuevo['lectura'] == false &&
                    itemsNuevo['escritura'] == false &&
                    itemsNuevo['aprobacion'] == false &&
                    itemsNuevo['aprobador'] == false) {
                    veo.push(false);
                } else {
                    veo.push(true);
                }
            }
            if (!veo.includes(true)) {
                store.dispatch("addShow", zonasStore[i]['nameModule'])
            }
            // cambia la estructura del state segu lo que llega de back
            store.dispatch("addP",{name: zonasStore[i]['nameModule'], data: nuevo})
        }
        // store.dispatch("addShow",paraVer)
        resolve(true)
    })
}

export {getPermissions, getInfoProfile, visualizacionModulos, llenarStore}