<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" sm="8" xs="12">
        <!-- <v-autocomplete chips small-chips dense clearable v-model="searchB" :items="buses" item-text="identificacion"
          item-value="id" label="Buscar"></v-autocomplete> -->
      </v-col>
      <v-col cols="12" md="2" sm="2" xs="12">
        <v-btn @click="carga = true" color="warning" v-if="permisos.escritura">Carga masiva</v-btn>
      </v-col>
      <v-col cols="12" md="2" sm="2" xs="12">
        <v-btn @click="busInfo(null)" v-if="permisos.escritura" color="success">Agregar</v-btn>
      </v-col>
    </v-row>
    <v-card elevation="0" color="accent">
      <v-card-subtitle v-if="buses.length != 0">
        <v-row>
          <v-col style="text-aling:center" v-for="i, index in buses" v-bind:key="buses[i]">
            <v-col @click="busInfo(i, index)">
              <v-img max-height="100" max-width="100"
                :src="i.fotoBus.length == 0? 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/BSicon_BUS.svg/500px-BSicon_BUS.svg.png' : i.fotoBus[0]">
              </v-img>
            </v-col>
            <br />
            <v-container>
              <v-row>
                <h3>{{'No. interno: ' + i.identificacion}}</h3>
              </v-row>
              <v-row>
                <h3>{{'Placa: '+ i.placa}}</h3>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-subtitle v-else>
        <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
      </v-card-subtitle>
    </v-card>
    <v-dialog persistent v-if="openAdd" v-model="openAdd">
      <addBus :permisos="permisos" :infoBus="informacionBus" v-on:toggle="eventosBus($event)"></addBus>
    </v-dialog>
    <v-dialog max-width="30%" v-if="carga" v-model="carga">
      <cargas-masivas :link="link" url="/cargaBuses" v-on:toggle="eventosCarga"></cargas-masivas>
    </v-dialog>
  </v-container>
</template>
<script>
import addBus from "./addBus";
import CargasMasivas from '../ComponentesGenerales/CargasMasivas.vue';
import data from "../Tools/getData";

import permisosMixims from "../../mixims/permisos";

export default {
  mixins: [permisosMixims],

  components: {
    addBus,
    CargasMasivas
  },

  data() {
    return {
      openAdd: false,
      informacionBus: [],
      buses: [],
      searchB: null,
      carga: false,
      link: "/PlantillaBuses.xlsx", 
      savePic: '',
      indexBus: null,
    };
  },
  async created() {
    this.cargarBus();
    
  },
  methods: {
    async cargarBus(){
      this.buses = []
      this.$store.state.buses = null;
      var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloBaseDatos")
          var permisosZona = findZona['permisos']
          var findPermisos = permisosZona.find(pgna => pgna.nombre === "Buses")
          this.permisos = findPermisos
          if (this.$store.state.buses == null)
            this.buses = await data.getData('/listarBuses', 'buses')
          else {
            this.buses = this.$store.state.buses
      }
    },


    eventosBus(a) {
      this.openAdd = false;
      if(this.indexBus != null){
        this.buses[this.indexBus].fotoBus = this.savePic
      }
      if (a == "refresh") {
        this.cargarBus();
      }
    },
    busInfo(a, index) { 
      this.openAdd = true;
      if (a == null) {
        this.informacionBus = null;
        this.indexBus = null
        this.savePic = '';
      } else {
        this.informacionBus = a;
        this.savePic = a.fotoBus
        this.indexBus = index
      }
    },
    eventosCarga() {
      this.cargarBus();
      this.carga = false;
    }
  }
};
</script >