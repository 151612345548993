<template>
  <v-container>
    <v-container>
      <v-icon @click="$router.push('/home')" large color="accent"
        >mdi-arrow-left-bold</v-icon
      >
    </v-container>
    <v-card color="accent">
      <v-card-text>
        <h1 class="medium secondaryT fit">
          Módulo Rodamientos - vista {{ tipo }}
        </h1>
      </v-card-text>
      <v-card-text>
        <v-tabs
          icons-and-text
          v-model="tab"
          background-color="primary"
          center-active
          dark
        >
          <v-tab>
            Mes
            <v-icon color="white">mdi-calendar</v-icon>
          </v-tab>
          <v-tab>
            Dia
            <v-icon color="white">mdi-calendar-cursor</v-icon>
          </v-tab>
          <v-tab>
            Bus
            <v-icon color="white">mdi-format-list-bulleted</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="tabsContainer">
          <v-tab-item>
            <by-month
              :fecha="fechaS"
              v-on:eventosM="eventosRoda($event)"
            ></by-month>
          </v-tab-item>
          <v-tab-item>
            <by-day :fecha="fechaS"></by-day>
          </v-tab-item>
          <v-tab-item>
            <by-bus></by-bus>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
// import crudR from "./ModuloRodamientos/crudRodamiento"; // Editar el roda
import ByMonth from "../ModuloRodamientos/ByMonth.vue";
import ByDay from "../ModuloRodamientos/ByDay.vue";
import ByBus from "../ModuloRodamientos/ByBus.vue";

export default {
  components: {
    //  asignarR,
    //  editarR,
    // crudR,
    ByMonth,
    ByDay,
    ByBus,
  },

  data() {
    return {
      tab: 0,
      fechaS: null,
      tipo: "Rodamientos",
      modulos: [],
    };
  },

  created() {
    var findZona = this.$store.getters.getZonas.find(
      (data) => data.nameModule === "moduloRodamientos"
    );
    this.modulos = findZona["permisos"];
  },

  watch: {
    tab: function (a) {
      if (a === 0) {
        this.tipo = "Rodamientos";
      } else if (a === 1) {
        this.tipo = "Líneas";
      } else {
        this.tipo = "Buses";
      }
    },
  },
  methods: {
    eventosCrud(a) {
      this.openAdd = false;
      if (a == "refresh") {
        this.listaRodamientosMes();
      }
    },

    eventosRoda(a) {
      if (a.name == "irDia") {
        this.fechaS = a.value;
        this.tab = 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tabsContainer {
  background-color: transparent !important;
  /* width: 100% !important;  */
  width: auto !important;
}
</style>