<template>
  <v-container>
    <v-card elevation="0" color="accent">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="10" sm="10" xs="12">
            <h1 class="large secondaryT">Usuarios</h1>
          </v-col>
          <v-col cols="12" md="2" sm="2" xs="12">
            <v-btn @click="addNew = true" v-if="permisos.escritura" color="success">Agregar tipo</v-btn>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-subtitle>
        <v-row>
          <v-col cols="12" md="3" sm="3" xs="12" style="text-aling:center" v-for="i in usuarios" v-bind:key="i.id">
            <v-col>
              <v-img max-height="100" max-width="100"
                src="https://www.iconsdb.com/icons/preview/black/administrator-xxl.png"></v-img>
            </v-col>
            <br />
            <v-container>
              <v-row>
                <h3 class="medium secondaryT thin">{{i.nombres + ' ' + i.apellidos}}</h3>
              </v-row>
              <v-row>
                <h3>Tipo usuario:</h3>
                <v-select :disabled="!permisos.escritura" v-model="i.tipo" type="map" @change="modificarTU(i)"
                  :items="roles"></v-select>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-card>
    <v-dialog max-width="50%" v-if="addNew" v-model="addNew">
      <v-card elevation="0" class="container_created">
        <v-card-title>
          <h1 class="medium secondaryT">Nuevo usuario</h1>
        </v-card-title>
        <v-card-subtitle>
          <v-col>
            <v-text-field v-model="nameNewUser" outlined dense label="Nuevo tipo usuario "></v-text-field>
          </v-col>
        </v-card-subtitle>
        <v-card-actions v-if="permisos.escritura">
          <v-spacer></v-spacer>
          <v-icon @click="addNew = false" x-large color="warning">mdi-close-circle</v-icon>
          <v-icon @click="addTipo(nameNewUser)" x-large color="success">mdi-check</v-icon>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import getToken from '../Tools/token';
import { host } from '../Tools/variables';
import permisosMixims from '../../mixims/permisos';

export default {
  mixins: [permisosMixims],
  
  data() {
    return {
      usuarios: [],
      roles:[],
      addNew: false,
      nameNewUser: '',
    };
  },
  
  created() {
    this.consultarUsuarios()
    this.consultarPermisos()
    var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloConfiguracion")
    var permisosZona = findZona['permisos']
    var findPermisos = permisosZona.find(pgna => pgna.nombre === "Usuarios")
    this.permisos = findPermisos
  },
  mounted() {
    
  },
  methods: {
    consultarUsuarios(){
      this.usuarios = []
      getToken().then(token=>{
      const fd = new FormData();
      fd.append("token", token);
      this.axios.post(host + "/listaUsuarios", fd).then(result =>{
        this.usuarios = result.data.lista
      })
    })
    },
    consultarPermisos(){
      getToken().then(token=>{
      const fd = new FormData();
      fd.append("token", token);
      this.axios.post(host + "/listarPermisos", fd).then(result =>{
        this.roles =  Object.keys(result.data.lista)
      })
    })
    },
    addTipo(a){
      getToken().then(token=>{
        const fd = new FormData();
        fd.append('token', token)
        fd.append('tipoUsuario', a)
        this.axios.post(host + '/agregarTipoUsuario', fd).then(result=>{
          console.log()
          if (result.data.data == 'OK') {
            this.addNew = false
            this.consultarUsuarios()
          }else{
            this.$alert(result.data.data)
          }
        })
      })
    }, 
    modificarTU(usuario){
        getToken().then(token=>{
        const fd = new FormData();
        fd.append('token', token)
        fd.append('id', usuario.id)
        fd.append('tipo', usuario.tipo)
        this.axios.post(host + '/modificarTipoUsuario', fd).then(result=>{
          if(result.data.data == 'OK'){
            this.consultarUsuarios()
          }else{
            this.$alert(result.data.data)
            this.consultarUsuarios()
          }
        })
      })  
    }
  },
};
</script>

<style>
h3 {
  font-size: 15px;
  font-weight: 400;
  /* color: red; */
}


</style>