import Vue from "vue";
import firebase from 'firebase'
import Router from "vue-router";
import login from "@/components/LoginPage.vue";
import home from "@/components/HomePage.vue";
import ModuloRodamientos from "../Modulos/ModuloRodamientos.vue";
// import ModuloPerfil from "@/components/ModuloPerfil.vue";
import ModuloPagos from "../Modulos/ModuloPagos.vue";
import ModuloBaseDatos from "../Modulos/ModuloBaseDatos.vue";
import ModuloConfiguracion from "../Modulos/ModuloConfiguracion.vue";
import ModuloDespachos from "../Modulos/ModuloDespachos.vue";
import ModuloMonitoreo from "../Modulos/ModuloMonitoreo.vue";
import { getPermissions } from "../Tools/utilities"
import store from "../../store"

Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: "/",
      name: "login",
      component: login,
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: "/home",
      name: "home",
      component: home,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: "/moduloPagos",
      name: "ModuloPagos",
      component: ModuloPagos,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: "/moduloMonitoreo",
      name: "ModuloMonitoreo",
      component: ModuloMonitoreo,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: "/moduloRodamientos",
      name: "ModuloRodamientos",
      component: ModuloRodamientos,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: "/moduloConfiguracion",
      name: "ModuloConfiguracion",
      component: ModuloConfiguracion,
       meta: {
        requiresAuth: true,
      }
    }
    ,
    {
      path: "/moduloBaseDatos",
      name: "ModuloBaseDatos",
      component: ModuloBaseDatos,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: "/moduloDespachos",
      name: "ModuloDespachos",
      component: ModuloDespachos,
      meta: {
        requiresAuth: true,
      }
    }
  ]
});



router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !(await firebase.auth().currentUser)) {
    next({ name: "login" });
  } else if (!requiresAuth && (await firebase.auth().currentUser)) {
    if (store.state.permisosGlobal.length == 0) { // si se cambian mucho los permisos ajustar eso con el ELSE
      await getPermissions().then(rta => {
        if (rta == 'OK')
          store.state.permisosGlobal = rta;
        next({ name: "home" });
      })
    }
    else {
      next({ name: "home" });
    }
  } else {
    if (await firebase.auth().currentUser != null) {
      if (store.state.permisosGlobal.length == 0) {
        await getPermissions().then(rta => {
          if (rta == 'OK')
            store.state.permisosGlobal = rta;
          next();
        })
    }
    else {
        next();
      }
    } else {
      next();
   }
  }
});


export default router;
