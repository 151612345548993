<template>
  <v-card color="accent">
    <v-card-title>
      <v-icon large color="primary" @click="$emit('toggle', 'close')"
        >mdi-arrow-left-bold</v-icon
      >
      <v-spacer></v-spacer>
      <v-icon color="primary" @click="deleteR(infoR)">mdi-delete</v-icon>
    </v-card-title>
    <v-card-text>
      <v-col>
        <h5 class="medium secondaryT fit">Rodamiento</h5>
        <h5 class="medium secondaryT thin">
          {{ "Conductor " + infoR.conductor.nombre }}
        </h5>
        <h5 class="medium secondaryT thin">{{ "Bus " + infoR.bus.codigo }}</h5>
        <h5 class="medium secondaryT thin">
          {{ "Rodamiento no. " + infoR.rodamientoBase.numero }}
        </h5>
        <h5 class="medium errorT thin">
          fecha : {{ infoR.horaSalida | moment("YYYY-MM-DD") }}
        </h5>
      </v-col>
    </v-card-text>
    <v-card-subtitle>
      <h5 class="normal errorT thin">
        * Tenga en cuenta que se eliminaran rodamientos a partir del siguiente
        día seleccionado
      </h5>
    </v-card-subtitle>
    <v-card-subtitle v-if="wait">
      <v-progress-circular
        :size="25"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-card-subtitle>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('toggle', 'irDia')" plain color="primary">
        Ir al día
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import getToken from "../Tools/token";
import { host } from "../Tools/variables";

export default {
  name: "DeleteRodamiento",
  props: ["fechaS", "infoR"],
  data() {
    return {
      wait: false,
    };
  },
  mounted() {},
  created() {},
  methods: {
    deleteR() {
      this.wait = true;
      getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("busId", this.infoR.bus.id);
        this.axios
          .post(host + "/borrarRodamientosBus", fd)
          .then((result) => {
            this.wait = false;
            if (result.data.data == "OK") {
              this.$emit("toggle", "refresh");
            } else {
              this.$alert(result.data.text);
            }
          })
          .catch((error) => {
            this.wait = false;
            this.$alert(error);
          });
      });
    },
  },
};
</script>

<style>
</style>