<template>
    <v-card color="accent">
        <v-card-title>
            <v-icon large color="primary" @click="$emit('toggle', 'close')">mdi-arrow-left-bold</v-icon>
            <v-spacer></v-spacer>
            <v-icon color="primary" @click="EditLinea('SI')">mdi-delete</v-icon>
        </v-card-title>
        <v-card-text>
            <v-col>
                <h5 class="medium secondaryT fit"> Editar linea:</h5>
                <h5 class="medium secondaryT thin">{{ 'Conductor ' + info.conductor.nombre}}</h5>
                <h5 class="medium secondaryT thin">{{ 'Bus ' + info.bus.codigo}}</h5>
                <h5 class="medium secondaryT thin">{{ 'Rodamiento no. ' + info.rodamientoBase.numero}}</h5>
                <h5 class="medium errorT thin">fecha : {{ info.horaSalida | moment("YYYY-MM-DD")}}</h5>
            </v-col>
        </v-card-text>
        <v-card-subtitle>
            <v-container>
                <v-row>
                    <v-col cols="12" md="4" sm="4" xs="4">
                        <v-select label="Conductor" :disabled="disabledCon"
                            :append-icon="disabledCon ? 'mdi-reload' : ''" :items="conductores"
                            v-model="edicion.conductor" item-text="usuarioId__nombres" item-value="id"></v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="4" xs="4">
                        <v-select label="Bus" :disabled="disabledBus" :append-icon="disabledBus ? 'mdi-reload' : ''"
                            :items="buses" v-model="edicion.bus" item-text="identificacion" item-value="id"></v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="4" xs="4">
                        <v-select label="Tipo edición" v-model="edicion.tipoEdicion"
                            :items="['Individual', 'Día','Semana', 'Mes']" item-text="identificacion" item-value="id"
                            :disabled="disableEdit">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" xs="12">
                        <v-text-field label="Observaciones" v-model="edicion.observaciones">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-subtitle>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-icon x-large color="warning" @click="$emit('toggle', 'close')">mdi-close-circle</v-icon>
            <v-icon v-if="!(disabledCon && disabledBus)" x-large color="success" @click="EditLinea('NO')">mdi-check
            </v-icon>
        </v-card-actions>
    </v-card>
</template>

<script>
import getToken from "../Tools/token";
import {host} from "../Tools/variables";
// import VueTimepicker from "vue2-timepicker";
// import "vue2-timepicker/dist/VueTimepicker.css";
import data from "../Tools/getData";


export default {
    name: 'EditLinea',
    props: {
        fecha: String,
        info: Object
    },
    components:{
        // VueTimepicker
    },
    data() {
        return {
            time: 'Solo la linea',
            buses: [],
            conductores: [],
            disabledCon: true,
            disabledBus:true,
            disableEdit: false,
            edicion: {
                tipoEdicion: 'Individual',
                conductor: null, 
                bus: null,
                observaciones: null,
            },
            // listaMarcarSalida: ['No', 'Otra hora']
            // now: this.$moment(new Date.now()).format('HH:mm:ss')
        }
    },
    created() {
        this.search()
        // this.createListaSalida()
    },
    mounted() {
        console.log(this.info)
    },
    methods: {


        async search() {
            await data.getDataEsp('/listaConductores', 'dia', this.fecha).then(info => {
                if (info != 'Error') {
                    this.conductores = info
                    this.edicion.conductor = this.info.conductor.id
                    this.disabledCon = false;
                } else {
                    this.$alert('Error')
                }
            })
            await data.getDataEsp('/listarBuses', 'mes', this.fecha).then(info => {
                if (info != 'Error') {
                    this.buses = info
                    this.edicion.bus = this.info.bus.id
                    this.disabledBus = false;
                } else {
                    this.$alert('Error')
                }
            })
            },
            EditLinea(eliminar){
                getToken().then(token => {
                    const fd = new FormData();
                    fd.append("token", token);
                    fd.append("rodamientoId", this.info.id);
                    fd.append("busId", this.edicion.bus);
                    fd.append("conductorId", this.edicion.conductor);
                    fd.append("eliminar", eliminar);
                    fd.append("observaciones", this.edicion.observaciones);
                    fd.append("marcarSalida", 'NO');
                    fd.append("tipoEdicion", this.edicion.tipoEdicion);
                    this.axios
                    .post(host + "/editarRodamiento", fd)
                    .then(result => {
                        var data = result.data.data
                        if(data == 'OK'){
                            this.$emit('toggle', 'refresh')
                        }else{
                            this.$alert('No se puede realizar este ajuste')
                        }
                    })
                    .catch(error => {
                        this.disabledCon = false;
                        this.$alert(error);
                    });
                });
            }, 
            
    },

}
</script>

