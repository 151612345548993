const host = "https://api.capitalbranch.org/travinser_api";

const zonas = [{
    front: 'moduloPerfil',
    id: 'perfil',
    to:'/perfil',
    name: 'Mi perfil',
    img: "https://media.istockphoto.com/vectors/user-profile-icon-vector-avatar-portrait-symbol-flat-shape-person-vector-id1270368615?k=20&m=1270368615&s=170667a&w=0&h=qpvA8Z6L164ZcKfIyOl-E8fKnfmRZ09Tks7WEoiLawA=",
    permisos: {
        lectura: true,
        escritura: false,
        aprobacion: true,
        aprobador:true,
        }
    }
]

export { host, zonas };
