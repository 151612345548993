<template>
  <v-card color="accent">
    <v-card-title>
      <v-icon large color="primary" @click="$emit('toggle', 'close')"
        >mdi-arrow-left-bold</v-icon
      >
      <v-spacer></v-spacer>
      <v-icon color="primary" v-if="permisos.escritura" @click="edit('SI')"
        >mdi-delete</v-icon
      >
    </v-card-title>
    <v-card-subtitle v-if="cargado">
      <v-container>
        <v-row>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              class="contenedor__login-textField"
              outlined
              dense
              v-model="rodamiento.num"
              :rules="[rules.required]"
              label="No.Rodamiento *"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" xs="12" sm="6">
            <vue-timepicker
              @change="activaEdicion()"
              drop-direction="bottom"
              input-width="100%"
              :drop-offset-height="700"
              container-id="auto-dropdown-containter"
              hide-clear-button
              close-on-complete="true"
              color="primary"
              input-class="picker_style"
              format="HH:mm:ss"
              v-model="salida"
              placeholder="Selecciona la hora de salida *"
            ></vue-timepicker>
          </v-col>
          <!-- <h5>{{rodamientosB}}</h5> -->
          <v-container v-for="n in noLineas" v-bind:key="n">
            <v-row>
              <v-col cols="12" md="12" xs="12" sm="12">
                <v-select
                  @change="activaEdicion()"
                  :items="rutas"
                  item-text="nombre"
                  item-value="valor"
                  item-key="valor"
                  v-model="rodamientosB[n - 1]"
                  label="Ruta"
                  dense
                  outlined
                  append-outer-icon="mdi-close"
                  @click:append-outer="reduce(n - 1)"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-btn @click="add" color="primary">Agregar linea</v-btn>
          </v-col>
          <v-col cols="12" md="6" xs="6" ms="12">
            <li
              class="contentLeft"
              v-for="(rut, y) in rodamiento.rutas"
              v-bind:key="rut"
            >
              <v-row>
                <v-col cols="12" md="9" xs="9" ms="9">
                  <h3>{{ rut }}</h3>
                </v-col>
                <v-col cols="12" md="3" xs="3" ms="3">
                  <v-btn icon @click="deleteRuta(y)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </li>
          </v-col>
        </v-row>
      </v-container>
    </v-card-subtitle>
    <v-card-subtitle v-else>
      <v-progress-circular
        :size="25"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-card-subtitle>
    <v-card-actions v-if="permisos.escritura">
      <v-spacer></v-spacer>
      <v-icon @click="$emit('toggle', 'close')" x-large color="warning"
        >mdi-close-circle</v-icon
      >
      <v-icon
        :disabled="wait"
        @click="info == null ? save() : edit()"
        x-large
        color="success"
        >mdi-check</v-icon
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import getToken from "../Tools/token";
import { host } from "../Tools/variables";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: { VueTimepicker },
  name: "addRodamiento",
  props: ["length", "info", "permisos"],
  data() {
    return {
      rutas: [],
      rodamiento: {
        num: 0,
      },
      noLineas: 0,
      ruta: null,
      rules: {
        required: (value) => !!value || "Campo requerido.",
      },
      wait: false,
      cargado: false,
      rodamientosB: [],
      salida: null,
    };
  },

  async created() {
    if (this.info != null) {
      this.salida = null;
      this.rodamiento.num = this.info[0]["numero"];
      var data = this.info.length;
      for (let f = 0; f < data; f++) {
        this.add();
      }
      for (let d = 0; d < this.info.length; d++) {
        const element = this.info[d];
        this.rodamientosB[d] = element.rutaId_id;
      }
      this.salida = this.info[0]["horaSalida"];
      console.log(this.salida);
      // this.salida[0].hh =  hora[0]
    } else {
      this.rodamiento.num = this.length + 1;
    }

    let rta;
    if (this.$store.state.rutas == null)
      rta = await data.getData("/listarRutas", "rutas");
    else {
      rta = this.$store.state.rutas;
    }
    // getToken().then(token => {
    //   const fd = new FormData();
    //   fd.append("token", token);
    //   this.axios.post(host + "/listarRutas", fd).then(result => {
    //     let rta = result.data.lista;
    for (let y = 0; y < rta.length; y++) {
      const element = rta[y];
      this.rutas.push({ nombre: element.nombre, valor: element.id });
      this.wait = true;
    }
    this.cargado = true;
    // });
    // });
  },
  methods: {
    deleteRuta(index) {
      this.rodamiento.rutas.splice(index, 1);
    },
    add() {
      this.noLineas++;
      this.wait = false;
    },
    activaEdicion() {
      this.wait = false;
    },
    reduce(a) {
      this.noLineas--;
      this.rodamientosB.splice(a, 1);
    },
    save() {
      this.wait = true;
      let data = [];
      for (let d = 0; d < this.rodamientosB.length; d++) {
        const element = this.rodamientosB[d];
        data.push({
          rutaId: element,
          numero: this.rodamiento.num,
          orden: d + 1,
          hora: d != 0 ? 0 : this.salida.HH,
          minutos: d != 0 ? 0 : this.salida.mm,
          segundos: d != 0 ? 0 : this.salida.ss,
          operador: "",
        });
      }
      getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("data", JSON.stringify(data));
        this.axios.post(host + "/nuevoRodamientoBase", fd).then((result) => {
          this.wait = false;
          let data = result.data;
          if (data.data == "OK") {
            this.$emit("toggle", "refresh");
          } else {
            this.$alert(result.data.data);
          }
        });
      });
    },
    edit(a) {
      this.wait = true;
      let data = [];
      for (let d = 0; d < this.rodamientosB.length; d++) {
        const element = this.rodamientosB[d];
        var hora = this.salida.split(":");
        // var sg = hora[2].split(' ')
        data.push({
          rutaId: element,
          numero: this.rodamiento.num,
          orden: d + 1,
          hora: d != 0 ? 0 : hora[0],
          minutos: d != 0 ? 0 : hora[1],
          segundos: d != 0 ? 0 : hora[2],
          operador: "",
        });
      }
      getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("numero", this.info[0]["numero"]);
        fd.append("data", JSON.stringify(data));
        fd.append("eliminar", a == "SI" ? a : "");
        this.axios.post(host + "/editarRodamientoBase", fd).then((result) => {
          this.wait = false;
          let data = result.data;
          if (data.data == "OK") {
            this.$emit("toggle", "refresh");
          } else {
            this.$alert(result.data.data);
          }
        });
      });
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}

li.active {
  background: #021727 !important;
}

.active {
  color: #021727 !important;
  background: #021727 !important;
}

.contentLeft {
  text-align: left !important;
}
.vue__time-picker .dropdown {
  background-color: #55677b;
  border: 1px solid rgb(58, 58, 58) !important;
}
.vue__time-picker input.display-time {
  border-radius: 5px;
  padding: 25px;
  border: 1px solid #d2d2d2 !important ;
}
</style>