import { host } from './variables'
import getToken from './token'
import store from  '../../store'
import axios from 'axios'


const data = {}

data.getData = function (url,storeName) {
    return new Promise(resolve => {
        getToken().then(token => {
            const fd = new FormData();
            fd.append("token", token);
            axios.post(host + url, fd).then(data => {
                if (data.data.data == 'OK') {
                    store.state[storeName] = data.data.lista;
                    resolve(data.data.lista);
                }
                else {
                    resolve('Error')
                }
            }).catch(error => {
            window.alert(error);
          });
        });
    })
}



data.getDataEsp = function (url, item, dataItem) {
    return new Promise(resolve => {
        getToken().then(token => {
            const fd = new FormData();
            fd.append("token", token);
            fd.append(item, dataItem);
            axios.post(host + url, fd).then(data => {
                if (data.data.data == 'OK') {
                    resolve(data.data.lista);
                }
                else {
                    resolve('Error')
                }
            }).catch(error => {
            window.alert(error);
          });
        });
    })
}


data.getDataRoda = function (url, item, dataItem, tipo) {
    return new Promise(resolve => {
        getToken().then(token => {
            const fd = new FormData();
            fd.append("token", token);
            fd.append(item, dataItem);
            fd.append("soloPrimerOrd", tipo);
            axios.post(host + url, fd).then(data => {
                if (data.data.data == 'OK') {
                    resolve(data.data);
                }
                else {
                    resolve('Error')
                }
            }).catch(error => {
            window.alert(error);
          });
        });
    })
}

export default data