var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-spacer'),_c('v-row',{staticClass:"justify-end"},[_c('v-col',{staticClass:"12",attrs:{"md":"11"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Seleciona la fecha de consulta","rules":[_vm.rules.required],"hint":"YYYY-MM-DD","persistent-hint":""},model:{value:(_vm.fechaFiltro),callback:function ($$v) {_vm.fechaFiltro=$$v},expression:"fechaFiltro"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"es","no-title":""},on:{"change":function($event){return _vm.filtroDia()},"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fechaFiltro),callback:function ($$v) {_vm.fechaFiltro=$$v},expression:"fechaFiltro"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-icon',{staticClass:"pt-4",attrs:{"color":"secondary"},on:{"click":function($event){_vm.popupinfo = true}}},[_vm._v(" mdi-information-outline ")]),(_vm.popupinfo)?_c('v-dialog',{attrs:{"max-width":"40%"},model:{value:(_vm.popupinfo),callback:function ($$v) {_vm.popupinfo=$$v},expression:"popupinfo"}},[_c('popup-semaforos')],1):_vm._e()],1)],1),(_vm.rodamientos.length)?_c('v-data-table',{attrs:{"dense":"","sort-by":['horaSalida'],"sort-desc":[true],"multi-sort":"","headers":_vm.headersD,"items-per-page":_vm.rodamientos.length,"items":_vm.rodamientos,"footer-props":{
    'items-per-page-text': 'items por página',
    'items-per-page-all-text':'Todos'
    }},scopedSlots:_vm._u([{key:"item.horaSalida",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.horaSalida,'HH:mm'))+" ")])]}},{key:"item.horaLlegada",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.horaLlegada,'HH:mm'))+" ")])]}},{key:"item.estadoPago",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.estadoPago == false ? 'red': 'green'}},[_vm._v("mdi-square")])]}},{key:"item.estadoLinea",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.estadoLinea == 0 ? 'yellow': item.estadoLinea == 1 ? 'orange' : item.estadoLinea == '2' ? 'green': item.estadoLinea == '3' ? 'red' : 'blue'}},[_vm._v(" mdi-square")])]}},{key:"item.edit",fn:function(ref){
    var item = ref.item;
return [(_vm.permisos.escritura)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editarByday(item)}}},[_vm._v("mdi-pencil")]):_vm._e()]}},{key:"item.mapa",fn:function(ref){
    var item = ref.item;
return [(_vm.permisosSegui.lectura)?_c('v-btn',{attrs:{"small":"","plain":""},on:{"click":function($event){return _vm.verM(item)}}},[_vm._v("Ver mapa")]):_vm._e()]}},{key:"header",fn:function(ref){
    var headers = ref.headers;
return _vm._l((headers),function(item){return _c('th',{key:headers[item],staticClass:"large secondaryT fat"},[_vm._v(_vm._s(item.value))])})}}],null,false,2344099860)}):_vm._e(),(!_vm.isBuscando && _vm.rodamientos.length == 0 && _vm.fechaFiltro != null)?_c('v-container',[_c('h5',[_vm._v(" * No tienes rodamientos agendados para este día")])]):_vm._e(),(_vm.isBuscando)?_c('v-container',[_c('v-progress-circular',{attrs:{"size":25,"color":"primary","indeterminate":""}})],1):_vm._e(),(_vm.popupEdit && _vm.permisos.escritura)?_c('v-dialog',{attrs:{"max-width":"60%"},model:{value:(_vm.popupEdit),callback:function ($$v) {_vm.popupEdit=$$v},expression:"popupEdit"}},[_c('edit-linea',{attrs:{"fecha":_vm.fechaFiltro,"info":_vm.info},on:{"toggle":function($event){return _vm.eventosEditL($event)}}})],1):_vm._e(),(_vm.verMapa && _vm.permisosSegui.lectura)?_c('v-dialog',{attrs:{"max-width":"60%"},model:{value:(_vm.verMapa),callback:function ($$v) {_vm.verMapa=$$v},expression:"verMapa"}},[_c('ver-posicion',{attrs:{"busId":_vm.busId,"name":_vm.nameBus},on:{"toggle":function($event){return _vm.eventosVerMapa($event)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }