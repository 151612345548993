<template>
  <v-card class="container_created">
    <v-card-text>
      <v-container>
        <h1 class="medium secondaryT">
          Pago de la ruta {{ infoR["ruta"]["nombre"] }} del conductor
          {{ infoR["conductor"]["nombre"] }}
        </h1>
      </v-container>
      <v-container>
        <v-text-field
          class="contenedor__login-textField"
          outlined
          dense
          disabled
          v-model="saldo"
          label="Saldo inicial"
        ></v-text-field>
        <v-text-field
          class="contenedor__login-textField"
          outlined
          dense
          disabled
          v-model="tarifa"
          label="Tarifa de la ruta"
        ></v-text-field>
        <v-text-field
          class="contenedor__login-textField"
          outlined
          dense
          v-model="pago"
          label="Valor a cancelar"
        >
        </v-text-field>
        <v-container class="center">
          <p class="large errorT fat">
            Saldo total {{ (saldo - tarifa + parseInt(pago)) | currency }}
          </p>
        </v-container>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-container class="container_save">
        <v-icon @click="$emit('toggle-pagos', 'close')" x-large color="#E7BB41"
          >mdi-close-circle</v-icon
        >
        <v-icon
          :disabled="waitP"
          @click="savePago(infoR)"
          x-large
          color="#44BBA4"
          >mdi-check</v-icon
        >
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import data from "../Tools/getData";
import getToken from "../Tools/token";
import { host } from "../Tools/variables";

export default {
  name: "PagoIndividual",
  props: { infoR: Object, conductorC: null },
  data() {
    return {
      saldo: 0,
      tarifa: 0,
      saldoFinal: 0,
      waitP: false,
      pago: 0,
    };
  },
  mounted() {
    console.log(this.conductorC);
    this.openP();
  },

  methods: {
    async openP() {
      var nuevoC = false;
      if (this.conductorC != null) {
        nuevoC = true;
      }
      var info = nuevoC ? this.conductorC : this.infoR["conductor"]["id"];
      await getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("conductorId", info);
        this.axios
          .post(host + "/infoPagoConductor", fd)
          .then((result) => {
            let data = result.data;
            if (data.data == "OK") {
              this.saldo = data.saldo;
            }
          })
          .catch((error) => {
            this.$alert(error);
            return false;
          });
      });

      var lista;
      if (this.$store.state.rutas == null)
        lista = await data.getData("/listarRutas", "rutas");
      else {
        lista = this.$store.state.rutas;
      }
      for (let f = 0; f < lista.length; f++) {
        const element = lista[f];
        console.log(element);
        if (element["nombre"] == this.infoR["ruta"]["nombre"])
          this.tarifa = element["precio"];
      }
    },

    savePago(a) {
      this.waitP = true;
      getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("rodamientoId", a.id);
        fd.append("valor", this.pago);
        this.axios
          .post(host + "/pagarLinea", fd)
          .then((result) => {
            this.waitP = false;
            let data = result.data;
            if (data.data == "OK") {
              this.$alert("Pago guardado satisfactoriamente");
              this.$emit("toggle-pagos", true);
            } else {
              this.waitP = false;
              this.$alert("Ocurrió un problema con el pago ⚠️");
              this.$emit("toggle-pagos", false);
            }
          })
          .catch((error) => {
            this.waitP = false;
            this.$alert(error);
            return false;
          });
      });
    },
  },
};
</script>

<style>
</style>