<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="9" sm="9" xs="12">
        <!-- <v-autocomplete
          chips
          small-chips
          dense
          clearable
          v-model="searchR"
          :items="rutas"
          item-text="nombre"
          item-value="id"
          label="Buscar"
          @change="rutaInfo('search')"
        ></v-autocomplete> -->
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="4">
        <v-btn @click="rutaInfo(null)"  v-if="permisos.escritura" color="success">Agregar</v-btn>
      </v-col>
    </v-row>
    <v-card elevation="0" color="accent">
      <v-card-subtitle v-if="rutas.length != 0">
        <v-row>
          <v-col style="text-aling:center" v-for="i in rutas" v-bind:key="rutas[i]">
            <v-col @click="rutaInfo(i)">
              <v-img
                max-height="100"
                max-width="100"
                src="https://cdn-icons-png.flaticon.com/512/72/72376.png"
              ></v-img>
            </v-col>
            <br />
            <v-container>
              <v-row>
                <h3>{{i.nombre}}</h3>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-subtitle v-else>
        <v-progress-circular :size="40" color="primary" indeterminate></v-progress-circular>
      </v-card-subtitle>
    </v-card>
    <v-dialog v-if="openAdd" v-model="openAdd">
      <addRuta :permisos="permisos" :infoRuta="informacionRuta" v-on:toggle="eventosRuta($event)"></addRuta>
    </v-dialog>
  </v-container>
</template>
<script>
import addRuta from "./addRuta";
import data from "../Tools/getData";
import permisosMixims from "../../mixims/permisos";

export default {
  mixins: [permisosMixims],

  components: {
    addRuta
  },
  
  data() {
    return {
      openAdd: false,
      informacionRuta: [],
      rutas: [],
      searchR: null,
    };
  },
  async created() {
    var findZona = this.$store.getters.getZonas.find(data => data.nameModule === "moduloBaseDatos")
    var permisosZona = findZona['permisos']
    var findPermisos = permisosZona.find(pgna => pgna.nombre === "Rutas")
    this.permisos = findPermisos
    if (this.$store.state.rutas == null)
      this.rutas = await data.getData('/listarRutas', 'rutas')
    else {
      this.rutas = this.$store.state.rutas
    }
  },
  methods: {
    eventosRuta(a) {
      console.log(a);
      this.openAdd = false;
      if (a == "refresh") {
        this.cargarRutas();
      }
    },
    rutaInfo(a) {
      this.openAdd = true;
      if (a == null) {
        this.informacionRuta = null;
      } else if (a == "search") {
        const resultado = this.rutas.find(rut => rut.id === this.searchR);
        this.informacionRuta = resultado;
      } else {
        this.informacionRuta = a;
      }
    }
  }
};
</script>
