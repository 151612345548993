<template>
  <v-container>
    <v-container>
      <v-icon @click="$router.push('/home')" large color="accent"
        >mdi-arrow-left-bold</v-icon
      >
    </v-container>
    <v-card color="accent">
      <v-card-text>
        <despacho></despacho>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Despacho from "../ModuloDespachos/Despachos.vue";

export default {
  components: {
    Despacho,
  },
};
</script>

<style>
</style>