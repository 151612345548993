const tableMixims = {
    data() {
        return {
            myLabels: {
                footerLeft: (arriba, abajo, total) =>
                    `Del ${arriba} a ${abajo} de ${total}`,
                first: "Pimera pgna",
                previous: "Anterior pgna",
                next: "Siguiente pgna",
                last: "Última pgna",
                footerRight: {
                    selected: "Seleccionados:",
                    filtered: "Filtrados:",
                    loaded: "Cargados:"
                }
            },
        }
    },
}

export default tableMixims