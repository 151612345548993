<template>
  <div>
    <v-toolbar elevation="0" color="primary">
      <v-btn @click="openPerfil = true" icon color="white" x-large>
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <h1 class="large whiteT fat">
        Plataforma para gestión de transporte - PGT
      </h1>
      <v-spacer></v-spacer>
      <h6 class="normal errorT fat">V: 1.13 </h6>
    </v-toolbar>
    <!-- </v-container> -->
    <v-container class="container_home" v-if="modulos && dataOK">
      <v-row>
        <v-col
          cols="12"
          lg="3"
          md="3"
          sm="4"
          xs="4"
          v-for="i in modulos"
          v-bind:key="modulos[i]"
        >
          <v-card
            class="rounded-xl"
            :color="i.show == false ? '#c4c4c4' : 'white'"
            @click="go(i.to, i.show)"
          >
            <v-card-title>
              <v-container>
                <h4 class="primaryT normal fit">{{ i.name }}</h4>
              </v-container>
            </v-card-title>
            <v-card-subtitle>
              <v-img max-width="100%" :src="i.img"></v-img>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else class="loadingCenter">
      <v-container>
        <v-progress-circular
          size="60"
          indeterminate
          color="secondary"
        ></v-progress-circular>
      </v-container>
    </v-container>
    <v-dialog v-if="openPerfil" v-model="openPerfil" max-width="50%">
      <perfil-page v-on:perfil="eventosPerfil($emit)"></perfil-page>
    </v-dialog>
    <v-footer padless>
      <v-col class="text-center" cols="12">
        <strong class="primaryT">By: Capital Branch SAS</strong>
      </v-col>
    </v-footer>
  </div>
</template>
<script>
import PerfilPage from "./PerfilPage.vue";
import data from "./Tools/getData";

export default {
  name: "HomePage",
  components: {
    PerfilPage,
  },
  data() {
    return {
      modulos: [],
      openPerfil: false,
      dataOK: false,
    };
  },
  mounted() {},
  async beforeCreate() {},
  async created() {
    this.modulos = this.$store.getters.getZonas;
    this.dataOK = true;
    await data.getData("/listaConductores", "conductores");
    await data.getData("/listarBuses", "buses");
    await data.getData("/leeCiudades", "ciudades");
    await data.getData("/listarRutas", "rutas");
    await data.getData("/listarRodamientoBase", "rodamientosBase");
    await data.getData("/listarRodamientos", "rodamientos");
  },
  methods: {
    go(a, permiso) {
      console.log(`${permiso} DESCOMENTAR`);
      if (permiso == false) {
        this.$alert("No tienes permisos en este módulo");
      } else {
        this.$router.push(a);
      }
    },

    eventosPerfil() {
      this.openPerfil = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container_home {
  padding: 1% 1% 2% 1%;
  text-align: center;
}

.container_home--text {
  font-size: 16px !important;
}

.container_home--card {
  border-radius: 40px !important;
}
.container_footer {
  position: absolute;
  left: 0;
  bottom: 0;
}
</style>
