<template>
  <v-container>
    <v-container>
      <v-icon @click="$router.push('/home')" large color="accent"
        >mdi-arrow-left-bold</v-icon
      >
    </v-container>
    <v-card color="accent">
      <v-card-text>
        <h1 class="medium secondaryT fit">Módulo Configuración</h1>
      </v-card-text>
      <v-card-subtitle>
        <v-tabs v-model="tab" background-color="primary" center-active dark>
          <v-tab
            v-for="i in modulos"
            :class="i.escritura == true ? '' : 'info'"
            :key="i.nombre"
            @click="clickTab(i)"
          >
            {{ i.nombre }}</v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="tab" class="tabsContainer">
          <v-tab-item>
            <users v-if="tienePermiso"></users>
            <h5 v-else>* No tienes permiso</h5>
          </v-tab-item>

          <v-tab-item>
            <permisos v-if="tienePermiso"></permisos>
            <h5 v-else>* No tienes permiso</h5>
          </v-tab-item>
          <v-tab-item>
            <control-alertas v-if="tienePermiso"></control-alertas>
            <h5 v-else>* No tienes permiso</h5>
          </v-tab-item>
        </v-tabs-items>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
import controlAlertas from "../ModuloConfiguracion/ControlAlertas.vue";
import Permisos from "../ModuloConfiguracion/ControlPermisos.vue";
import Users from "../ModuloConfiguracion/ControlUsuarios.vue";
export default {
  components: { controlAlertas, Permisos, Users },
  data() {
    return {
      tab: 0,
      tienePermiso: true,
      modulos: [],
    };
  },
  created() {
    var findZona = this.$store.getters.getZonas.find(
      (data) => data.nameModule === "moduloConfiguracion"
    );
    this.modulos = findZona["permisos"];
  },
  mounted() {},
  methods: {
    clickTab(data) {
      console.log(data);
      this.tienePermiso = data.lectura;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabsContainer {
  background-color: transparent !important;
}
</style>